import React from 'react';
import { Button, Menu, MenuItem, Avatar, ListItemIcon, ListItemText, Box } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { ref, update, get, child } from 'firebase/database';
import { getDatabase } from 'firebase/database';

const API_URI_CNPJS = `https://core-api-hjfuceckbqgbgqhx.vinbol.ai/cnpjs?cnpj=`;

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#9c27b0', // Ensures light mode style
    color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
}));

const SquareSwitch = styled('div')(({ theme, checked }) => ({
  width: 34,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 2,
  padding: 2,
  cursor: 'pointer',
  backgroundColor: checked ? theme.palette.success.main : theme.palette.grey[400],
  '&:before': {
    content: '""',
    width: 16,
    height: 16,
    backgroundColor: theme.palette.common.white,
    transition: 'transform 0.2s',
    transform: checked ? 'translateX(14px)' : 'translateX(0)',
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    zIndex: 1600,
    marginLeft: '20px',
    minWidth: '250px',
  },
}));

const ProfileMenu = ({ 
  clientName, 
  setNomeEmpresa, 
  groupNamesList, 
  isGroup, 
  onLogout, 
  currCnpj, 
  setCurrCnpj,
  setDisplayNomeEmpresa,
  setIsGroup,
  cnpj,
  activeTutorial, 
  setActiveTutorial, 
  updateOnCalculator,
  showText,
  defaultBrand,
  setDefaultBrand
}) => {
  const classes = useStyles(); // Use styles here for Avatar
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const [subMenuAnchorElCompany, setSubMenuAnchorElCompany] = React.useState(null);
  const [managedCompanies, setManagedCompanies] = React.useState({});
  const navigate = useNavigate();
  
  // Initialize the default state for currCnpj and displayNomeEmpresa to Matriz
  React.useEffect(() => {
    if (groupNamesList.length > 0) {
      setCurrCnpj(groupNamesList[0].cnpj);
      setDisplayNomeEmpresa("Matriz");
    }
  }, [groupNamesList]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubMenuClick = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleSubMenuCompanyClick = (event) => {
    setSubMenuAnchorElCompany(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null); // Close the submenu as well
    setSubMenuAnchorElCompany(null); // Close the company submenu
  };

  const handleTutorialToggle = () => {
    setActiveTutorial(!activeTutorial);
  };

  const formatBranch = (branchName) => {
    const match = branchName.match(/\((.*?)\)/);
    return match ? `Unidade ${match[1]}` : 'Grupo';
  };

  React.useEffect(() => {
    if (Array.isArray(cnpj) && cnpj.length > 0) {
      const auth = getAuth();
      const dbRef = ref(getDatabase());
      
      // Buscar todas as marcas do usuário para ter acesso aos IDs
      get(child(dbRef, `users/${auth.currentUser.uid}/brands`)).then(async (brandsSnapshot) => {
        if (brandsSnapshot.exists()) {
          const userBrands = brandsSnapshot.val();
          
          // Primeiro, buscar todas as marcas
          const brandRefs = await Promise.all(
            Object.keys(userBrands).map(key => 
              get(child(dbRef, `brands/${key}`))
            )
          );

          // Criar um mapa de CNPJs para IDs de marca
          const cnpjToIdMap = {};
          brandRefs.forEach((brandRef, index) => {
            if (brandRef.exists()) {
              const brandData = brandRef.val();
              cnpjToIdMap[brandData.cnpj] = Object.keys(userBrands)[index];
            }
          });
          
          // Agora mapear os CNPJs para suas respectivas marcas
          const fetchPromises = cnpj.map(async (companyCnpj) => {
            const response = await axios.get(`${API_URI_CNPJS}${companyCnpj}`);
            return {
              id: cnpjToIdMap[companyCnpj], // ID real da marca
              nome: response.data[0]?.nome_pesquisa,
              cnpj: companyCnpj,
              isGroup: response.data[0]?.grupo
            };
          });

          Promise.all(fetchPromises)
            .then((companies) => {
              setManagedCompanies(companies);
            });
        }
      });
    }
  }, [cnpj]);

  // Lida com as empresas gerenciadas que são grupo - monta a string de cnpjs do grupo para fazer o fetch do ACPS corretamente.
  React.useEffect(() => {
    if (Object.keys(managedCompanies).length > 0) {
      managedCompanies?.map((item, index) => {
        if (item.isGroup) {
          axios
          .get(`https://core-api-hjfuceckbqgbgqhx.vinbol.ai/groupCnpjs?cnpj=${item.cnpj}`)
          .then(res => {
            item.cnpj = res.data.map(company => company.cnpj).join(',');
          });
        }
      });
    }
  }, [managedCompanies]);

  const handleAdminDashboard = () => {
    navigate('/admin');
  };

  // Função atualizada para definir marca padrão
  const handleSetDefaultBrand = (brandId, brandName) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user && brandId) {
      const dbRef = ref(getDatabase());
      
      // Primeiro buscar o CNPJ da marca
      get(child(dbRef, `brands/${brandId}`)).then((brandSnapshot) => {
        if (brandSnapshot.exists()) {
          const brandData = brandSnapshot.val();
          
          update(ref(getDatabase(), `users/${user.uid}`), {
            defaultBrand: brandId.toString()
          }).then(() => {
            setDefaultBrand(brandId.toString());
            setCurrCnpj(brandData.cnpj); // Usar o CNPJ da marca, não o ID
            setDisplayNomeEmpresa(brandName);
            setNomeEmpresa(brandName);
            setIsGroup(false);
            handleClose();
          }).catch(error => {
            console.error('Erro ao atualizar marca padrão:', error);
          });
        }
      });
    }
  };

  // Adicionar handler para tecla ESC
  React.useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <>
      <Box 
        onClick={handleClick} 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          width: '100%',
          cursor: 'pointer'
        }}
      >
        <ListItemIcon sx={{ 
          minWidth: 40, 
          display: 'flex', 
          justifyContent: 'center',
          marginLeft: '-7px'
        }}>
          <Avatar 
            className={classes.avatar} 
            sx={{ 
              width: 32, 
              height: 32, 
              fontSize: '1rem'
            }}
          >
            {clientName.charAt(0)}
          </Avatar>
        </ListItemIcon>
        {showText && <ListItemText primary="Minha Conta" />}
      </Box>
      
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableScrollLock
        onClick={(e) => e.stopPropagation()}
      >
        {/* Conditional rendering */}
        {isGroup && (
          <MenuItem key="toggle-units" onClick={handleSubMenuClick}>
            <ListItemText>Alternar entre Unidades</ListItemText>
            <ListItemIcon>
              <ExpandMoreIcon />
            </ListItemIcon>
          </MenuItem>
        )}

        {/* Conditional rendering for "Minhas Marcas Gerenciadas" */}
        {Array.isArray(cnpj) && cnpj.length > 1 && (
          <MenuItem key="my-managed-companies" onClick={handleSubMenuCompanyClick}>
            <ListItemText>Minhas Marcas Gerenciadas</ListItemText>
            <ListItemIcon>
              <ExpandMoreIcon />
            </ListItemIcon>
          </MenuItem>
        )}

        {/* Submenu for managing companies */}
        {Array.isArray(cnpj) && cnpj.length > 1 && (
          <Menu
            key="sub-menu-company"
            id="sub-menu-company"
            anchorEl={subMenuAnchorElCompany}
            keepMounted
            open={Boolean(subMenuAnchorElCompany)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {Object.keys(managedCompanies).length > 0 && managedCompanies?.map((item, index) => (
              <MenuItem
                key={index}
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div onClick={() => {
                  setCurrCnpj(item.cnpj);
                  setDisplayNomeEmpresa(item.nome);
                  setNomeEmpresa(item.nome);
                  setIsGroup(item.isGroup);
                  handleClose();
                }}>
                  {item.nome}
                </div>
                <Button
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSetDefaultBrand(item.id, item.nome);
                  }}
                  sx={{ 
                    ml: 2,
                    fontSize: '0.7rem',
                    color: item.id === defaultBrand ? 'primary.main' : 'text.secondary'
                  }}
                >
                  {item.id === defaultBrand ? 'Marca padrão' : 'Definir como padrão'}
                </Button>
              </MenuItem>
            ))}
          </Menu>
        )}

        {/* Submenu for "Alternar entre Unidades" */}
        {isGroup && (
          <Menu
            key="sub-menu"
            id="sub-menu"
            anchorEl={subMenuAnchorEl}
            keepMounted
            open={Boolean(subMenuAnchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              key="matriz"
              onClick={() => {
                setCurrCnpj(groupNamesList[0].cnpj);
                setDisplayNomeEmpresa("Matriz");
                handleClose();
              }}
            >
              Matriz
            </MenuItem>
            {Object.keys(groupNamesList).length > 0 && groupNamesList?.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  const cnpjValue = index === 0 ? groupNamesList?.map(item => item.cnpj).join(',') : item.cnpj;
                  setCurrCnpj(cnpjValue);
                  setDisplayNomeEmpresa(formatBranch(item.nome));
                  handleClose();
                }}
              >
                {formatBranch(item.nome)}
              </MenuItem>
            ))}
          </Menu>
        )}

        {/* New menu item for toggling tutorial */}
        <MenuItem onClick={handleTutorialToggle}>
          <ListItemText>Habilitar tutorial</ListItemText>
          <ListItemIcon>
            <SquareSwitch checked={activeTutorial} onClick={handleTutorialToggle} />
          </ListItemIcon>
        </MenuItem>

        <MenuItem onClick={handleAdminDashboard}>
          <ListItemText>Configurações</ListItemText>
        </MenuItem>

        <MenuItem onClick={onLogout}>Sair</MenuItem>
      </StyledMenu>
    </>
  );
};

export default ProfileMenu;
