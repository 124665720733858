// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCQ4oKCfQU4lP6Tju_pqUb0LEfJH8xV3rk",
    authDomain: "guaruba-ai.firebaseapp.com",
    projectId: "guaruba-ai",
    storageBucket: "guaruba-ai.appspot.com",
    messagingSenderId: "424284112097",
    appId: "1:424284112097:web:cdd85789cb07393b21e3a0"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;