import React, { useState, useEffect } from 'react';
import { Typography, Grid, Snackbar, Alert, Paper, Avatar, TextField, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, update, get, child } from "firebase/database";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import styled from 'styled-components';
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import BusinessIcon from '@mui/icons-material/Business';
import { getFunctions, httpsCallable } from 'firebase/functions';
import EditIcon from '@mui/icons-material/Edit';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import InfoIcon from '@mui/icons-material/Info';
import { useGoogleLogin } from '@react-oauth/google';
import StorefrontIcon from '@mui/icons-material/Storefront';
import FacebookLogin from '@greatsumini/react-facebook-login';
import ReclameAquiIcon from '../../assets/svg/Sources/ReclameAqui';
import needPermissionImage from '../../assets/img/google_business_profile_need_permission.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';

// Criar tema padrão
const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

// Styled Components
const Container = styled.div`
  padding: 40px;
  background-color: ${props => props.theme.palette?.mode === 'dark' ? '#1a1a1a' : '#f5f5f5'};
  min-height: 100vh;
`;

const IntegrationCard = styled.div`
  background-color: ${props => props.theme.palette?.mode === 'dark' ? '#2d2d2d' : 'white'};
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;

  svg {
    font-size: 24px;
    color: ${props => props.iconColor || '#666'};
  }
`;

const ConnectButton = styled.button`
  background-color: ${props => props.disabled ? '#9e9e9e' : props.bgColor || '#4267B2'};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  transition: all 0.2s ease;
  opacity: ${props => props.disabled ? 0.7 : 1};
  margin-top: auto;

  &:hover {
    background-color: ${props => props.disabled ? '#9e9e9e' : props.hoverColor};
    transform: ${props => props.disabled ? 'none' : 'translateY(-2px)'};
  }

  svg {
    font-size: 18px;
  }
`;

const StatusBadge = styled.div`
  background-color: ${props => props.connected ? '#4caf50' : '#ff9800'};
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 16px;
`;

const Header = styled(Paper)`
  padding: 24px;
  margin-bottom: 32px;
  background-color: ${props => props.theme.palette?.mode === 'dark' ? '#2d2d2d' : 'white'};
  display: flex;
  align-items: center;
  gap: 24px;
`;

const CompanyInfo = styled.div`
  flex: 1;
`;

const CompanyName = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${props => props.theme.palette?.mode === 'dark' ? '#fff' : '#333'};
`;

const CompanyDetails = styled(Typography)`
  color: ${props => props.theme.palette?.mode === 'dark' ? '#aaa' : '#666'};
  font-size: 0.9rem;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const AccessButton = styled.div`
  color: ${theme.palette.primary.main};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  
  &:hover {
    opacity: 0.8;
  }
`;

const WebsiteInput = styled(TextField)`
  width: 100%;
  margin-bottom: 16px;
  
  &.disabled {
    background-color: #f5f5f5;
  }
`;

const ReclameAquiInput = styled(TextField)`
  margin-bottom: 16px;
  
  &.disabled {
    background-color: #f5f5f5;
  }

  .MuiInputBase-root {
    font-size: 14px;
  }
`;

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [uid, setUid] = useState(null);
  const [loadScreen, setLoadScreen] = useState(null);
  const [userData, setUserData] = useState({
    nome: '',
    email: '',
    brands: {},
    defaultBrand: ''
  });
  const [currentBrand, setCurrentBrand] = useState({
    id: '',
    name: '',
    cnpj: '',
    integrations: {
      google: { connected: false },
      meta: { 
        connected: false,
        platforms: {
          facebook: { connected: false },
          instagram: { connected: false }
        }
      },
      reclameaqui: { connected: false }
    }
  });
  const [website, setWebsite] = useState('');
  const [isEditingWebsite, setIsEditingWebsite] = useState(false);
  const [integrations, setIntegrations] = useState({
    meta: {
      connected: false,
      facebook: false,
      instagram: false
    },
    google: false,
    reclameaqui: false
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [reclameAquiUser, setReclameAquiUser] = useState('');
  const [isEditingReclameAqui, setIsEditingReclameAqui] = useState(false);
  const [showScopeDialog, setShowScopeDialog] = useState(false);
  const [metaStatus, setMetaStatus] = useState({
    facebook: false,
    instagram: false
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [userBrands, setUserBrands] = useState({});
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  // Verificação de autenticação e carregamento de dados
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
        const dbRef = ref(getDatabase());
        
        // Buscar dados do usuário
        get(child(dbRef, `users/${user.uid}`)).then(async (userSnapshot) => {
          if (userSnapshot.exists()) {
            const userData = userSnapshot.val();
            setUserData(userData);
            
            // Verificar se é onboarding
            if (userData.isOnboarding) {
              setShowWelcomeModal(true);
            }
            
            // Buscar dados completos de todas as marcas do usuário
            const brandsData = {};
            await Promise.all(
              Object.keys(userData.brands || {}).map(async (brandId) => {
                const brandSnapshot = await get(child(dbRef, `brands/${brandId}`));
                if (brandSnapshot.exists()) {
                  brandsData[brandId] = brandSnapshot.val();
                }
              })
            );
            setUserBrands(brandsData);
            
            // Configurar marca padrão
            if (userData.defaultBrand && brandsData[userData.defaultBrand]) {
              setCurrentBrand({
                id: userData.defaultBrand,
                ...brandsData[userData.defaultBrand]
              });
              
              const defaultBrand = brandsData[userData.defaultBrand];
              setMetaStatus({
                facebook: defaultBrand.integrations?.meta?.platforms?.facebook?.connected || false,
                instagram: defaultBrand.integrations?.meta?.platforms?.instagram?.connected || false
              });
              
              setWebsite(defaultBrand.integrations?.website?.url || '');
              setReclameAquiUser(defaultBrand.integrations?.reclameaqui?.username || '');
            }
            
            setLoadScreen(true);
          } else {
            setLoadScreen(false);
          }
        }).catch(error => {
          console.error("Error loading user data:", error);
          setLoadScreen(false);
        });
      } else {
        navigate('/signin');
      }
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, [navigate]);

  // Função para abrir menu de marcas
  const handleBrandMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Função para fechar menu de marcas
  const handleBrandMenuClose = () => {
    setAnchorEl(null);
  };

  // Função para trocar de marca
  const handleBrandChange = async (brandId) => {
    const dbRef = ref(getDatabase());
    const brandSnapshot = await get(child(dbRef, `brands/${brandId}`));
    
    if (brandSnapshot.exists()) {
      const brandData = brandSnapshot.val();
      setCurrentBrand({
        id: brandId,
        ...brandData
      });
      
      // Atualizar estados das integrações
      setMetaStatus({
        facebook: brandData.integrations?.meta?.platforms?.facebook?.connected || false,
        instagram: brandData.integrations?.meta?.platforms?.instagram?.connected || false
      });
      
      setWebsite(brandData.integrations?.website?.url || '');
      setReclameAquiUser(brandData.integrations?.reclameaqui?.username || '');
    }
    handleBrandMenuClose();
  };

  // Atualizar o header com os dados do usuário
  const renderHeader = () => (
    <Header elevation={2}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Avatar 
          sx={{ 
            width: 64, 
            height: 64, 
            bgcolor: theme.palette.primary.main 
          }}
        >
          <BusinessIcon fontSize="large" />
        </Avatar>
        <CompanyInfo>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CompanyName variant="h5">
              {currentBrand.name || 'Selecione uma marca'}
            </CompanyName>
            <IconButton onClick={handleBrandMenuClick}>
              <ExpandMoreIcon />
            </IconButton>
          </Box>
          <CompanyDetails>
            CNPJ: {currentBrand.cnpj || 'N/A'}
          </CompanyDetails>
        </CompanyInfo>
      </Box>
      
      {/* Menu de seleção de marcas com nomes corretos */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleBrandMenuClose}
        PaperProps={{
          sx: {
            maxHeight: 300,
            width: '250px'
          }
        }}
      >
        {Object.entries(userBrands).map(([brandId, brandData]) => (
          <MenuItem 
            key={brandId}
            onClick={() => handleBrandChange(brandId)}
            selected={currentBrand.id === brandId}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 1
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              flex: 1,
              overflow: 'hidden'
            }}>
              {currentBrand.id === brandId && (
                <CheckIcon sx={{ color: 'primary.main', flexShrink: 0 }} />
              )}
              <Typography noWrap>
                {brandData.name}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Header>
  );

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleContinue = () => {
    navigate('/dashboard');
  };

  const responseInstagram = async (response) => {
    const auth = getAuth();
    const uid = auth.currentUser?.uid;
    const brandId = currentBrand.id;

    if (uid && brandId) {
      try {
        const db = getDatabase();
        const now = new Date();

        // Primeiro, vamos lidar com o Facebook
        const fbResponse = await fetch(
          `https://graph.facebook.com/v21.0/${response.userID}/accounts?access_token=${response.accessToken}`
        );
        const fbData = await fbResponse.json();

        if (!fbData.data || fbData.data.length === 0) {
          throw new Error('Nenhuma página do Facebook encontrada');
        }

        // Salvamos os dados do Facebook
        const pageData = fbData.data[0]; // Assumindo primeira página por enquanto
        const facebookData = {
          pageId: pageData.id,
          pageName: pageData.name,
          pageAccessToken: pageData.access_token,
          connected: true,
          connectedAt: now.toISOString()
        };

        // Agora vamos trocar o token do Instagram por um de longa duração
        const functions = getFunctions();
        const exchangeInstagramToken = httpsCallable(functions, 'exchangeInstagramToken');
        const instagramTokenResult = await exchangeInstagramToken({ 
          shortLivedToken: response.accessToken 
        });

        // Salvamos os dados do Instagram
        const instagramData = {
          userId: response.userID,
          shortLivedToken: response.accessToken,
          longLivedToken: instagramTokenResult.data.accessToken,
          expiresIn: instagramTokenResult.data.expiresIn,
          connected: true,
          connectedAt: now.toISOString()
        };

        // Atualizamos o banco com ambas as integrações
        await update(ref(db, `brands/${brandId}/integrations/meta`), {
          connected: true,
          platforms: {
            facebook: facebookData,
            instagram: instagramData
          }
        });

        setMetaStatus({
          facebook: true,
          instagram: true
        });

        setSnackbar({
          open: true,
          message: 'Contas Meta conectadas com sucesso!',
          severity: 'success'
        });

      } catch (error) {
        console.error('Error during Meta integration:', error);
        setSnackbar({
          open: true,
          message: `Erro ao conectar contas Meta: ${error.message}`,
          severity: 'error'
        });
      }
    }
  };

  // Função para formatar o tempo restante do token
  const formatExpirationTime = (connectedAt, expiresIn) => {
    if (!connectedAt || !expiresIn) return 'Não disponível';
    
    const expirationDate = new Date(new Date(connectedAt).getTime() + expiresIn * 1000);
    const now = new Date();
    
    const diffInMilliseconds = expirationDate - now;
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const diffInHours = Math.floor((diffInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    
    if (diffInDays > 0) {
      return `${diffInDays} dias e ${diffInHours} horas`;
    } else if (diffInHours > 0) {
      return `${diffInHours} horas`;
    } else {
      return 'Menos de 1 hora';
    }
  };

  // Adicione este useEffect para verificar expiração do token Meta
  useEffect(() => {
    if (userData?.integrations?.meta?.connectedAt && userData?.integrations?.meta?.expiresIn) {
      const expirationDate = new Date(new Date(userData.integrations.meta.connectedAt).getTime() + userData.integrations.meta.expiresIn * 1000);
      const now = new Date();
      
      if (expirationDate <= now) {
        const db = getDatabase();
        update(ref(db, `users/${uid}/integrations/meta`), {
          connected: false,
          platforms: {
            facebook: { 
              ...userData?.integrations?.meta?.platforms?.facebook,
              connected: false 
            },
            instagram: { 
              ...userData?.integrations?.meta?.platforms?.instagram,
              connected: false 
            }
          }
        });
        
        setMetaStatus({
          facebook: false,
          instagram: false
        });

        setSnackbar({
          open: true,
          message: 'Sua conexão com as contas Meta expirou. Por favor, reconecte suas contas.',
          severity: 'warning'
        });
      }
    }
  }, [userData?.integrations?.meta]);

  // Carregar website nas integrações
  useEffect(() => {
    if (uid) {
      const db = getDatabase();
      get(child(ref(db), `users/${uid}/integrations/website`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setWebsite(snapshot.val().url || '');
          }
        });
    }
  }, [uid]);

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleWebsiteSave = async () => {
    if (uid && website && currentBrand.id) {
      const db = getDatabase();
      await update(ref(db, `brands/${currentBrand.id}/integrations`), {
        website: {
          url: website,
          connected: true,
          updatedAt: new Date().toISOString()
        }
      });
      setIsEditingWebsite(false);
      setSnackbar({
        open: true,
        message: 'Site institucional atualizado com sucesso!',
        severity: 'success'
      });
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      if (!tokenResponse.access_token) {
        setSnackbar({
          open: true,
          message: 'Falha ao conectar com o Google Business Profile',
          severity: 'error'
        });
        return;
      }

      // Validar se o escopo necessário está presente
      const hasRequiredScope = tokenResponse.scope?.includes('https://www.googleapis.com/auth/business.manage');
      
      if (!hasRequiredScope) {
        setShowScopeDialog(true);
        return;
      }

      const auth = getAuth();
      const uid = auth.currentUser?.uid;

      if (uid) {
        try {
          const db = getDatabase();
          const now = new Date();
          
          await update(ref(db, `users/${uid}`), {
            'integrations/google': {
              accessToken: tokenResponse.access_token,
              connected: true,
              connectedAt: now.toISOString(),
              expiresIn: tokenResponse.expires_in,
              scope: tokenResponse.scope
            }
          });

          setIntegrations(prev => ({
            ...prev,
            google: true
          }));

          setSnackbar({
            open: true,
            message: 'Google Business Profile conectado com sucesso!',
            severity: 'success'
          });
        } catch (error) {
          console.error('Error during Google integration:', error);
          setSnackbar({
            open: true,
            message: 'Erro ao conectar com o Google Business Profile. Tente novamente.',
            severity: 'error'
          });
        }
      }
    },
    onError: () => {
      setSnackbar({
        open: true,
        message: 'Falha ao conectar com o Google Business Profile',
        severity: 'error'
      });
    },
    scope: 'https://www.googleapis.com/auth/business.manage'
  });

  // Carregar reclameAquiUser nas integrações
  useEffect(() => {
    if (uid) {
      const db = getDatabase();
      get(child(ref(db), `users/${uid}/integrations/reclameaqui`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setReclameAquiUser(snapshot.val().username || '');
          }
        });
    }
  }, [uid]);

  const handleReclameAquiChange = (event) => {
    setReclameAquiUser(event.target.value);
  };

  const handleReclameAquiSave = async () => {
    if (uid && reclameAquiUser && currentBrand.id) {
      const db = getDatabase();
      await update(ref(db, `brands/${currentBrand.id}/integrations`), {
        reclameaqui: {
          username: reclameAquiUser,
          connected: true,
          updatedAt: new Date().toISOString()
        }
      });
      setIsEditingReclameAqui(false);
      setSnackbar({
        open: true,
        message: 'Usuário do Reclame Aqui atualizado com sucesso!',
        severity: 'success'
      });
    }
  };

  // Adicione este useEffect para carregar o status inicial do Meta
  useEffect(() => {
    if (uid) {
      const db = getDatabase();
      get(child(ref(db), `users/${uid}/integrations/meta/platforms`)).then((snapshot) => {
        if (snapshot.exists()) {
          const platforms = snapshot.val();
          console.log('Carregando status Meta:', platforms); // Debug
          setMetaStatus({
            facebook: platforms.facebook?.connected || false,
            instagram: platforms.instagram?.connected || false
          });
        }
      }).catch(error => {
        console.error('Erro ao carregar status Meta:', error);
      });
    }
  }, [uid]);

  // Se ainda está carregando, mostrar loading
  if (loadScreen === null) {
    return (
      <Container>
        <Typography>Carregando...</Typography>
      </Container>
    );
  }

  // Se não conseguiu carregar, mostrar erro
  if (loadScreen === false) {
    return (
      <Container>
        <Typography color="error">
          Erro ao carregar dados. Por favor, recarregue a página.
        </Typography>
      </Container>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        {renderHeader()}
        
        <PageHeader>
          <Typography variant="h4">
            Integrações de fontes de dados
          </Typography>
          <AccessButton onClick={handleContinue}>
            <Typography variant="h6" component="span">
              Acessar plataforma
            </Typography>
            <ArrowForwardIcon />
          </AccessButton>
        </PageHeader>
        
        <Grid container spacing={4}>
          {/* Meta Integration (Facebook + Instagram) */}
          <Grid item xs={12} md={4}>
            <IntegrationCard>
              <CardTitle>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <FacebookIcon style={{ color: '#4267B2' }} />
                  <InstagramIcon style={{ color: '#E4405F' }} />
                </div>
                <Typography variant="h6">Meta (Facebook + Instagram)</Typography>
              </CardTitle>
              
              <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <StatusBadge connected={metaStatus.facebook}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      <FacebookIcon fontSize="small" />
                      {metaStatus.facebook ? 'Conectado' : 'Não Conectado'}
                    </div>
                  </StatusBadge>
                </Grid>
                <Grid item xs={6}>
                  <StatusBadge connected={metaStatus.instagram}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      <InstagramIcon fontSize="small" />
                      {metaStatus.instagram ? 'Conectado' : 'Não Conectado'}
                    </div>
                  </StatusBadge>
                </Grid>
              </Grid>

              {(metaStatus.facebook || metaStatus.instagram) && currentBrand?.integrations?.meta?.platforms?.instagram && (
                <Typography 
                  variant="caption" 
                  color="text.secondary" 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1, 
                    mb: 2 
                  }}
                >
                  <InfoIcon fontSize="small" />
                  Token expira em: {formatExpirationTime(
                    currentBrand.integrations.meta.platforms.instagram.connectedAt,
                    currentBrand.integrations.meta.platforms.instagram.expiresIn
                  )}
                </Typography>
              )}

              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Conecte suas contas do Facebook e Instagram para análise de comentários, avaliações e engajamento.
              </Typography>

              <FacebookLogin
                appId={process.env.REACT_APP_META_APP_ID}
                onSuccess={responseInstagram}
                onFail={(error) => {
                  console.error('Login Failed:', error);
                  setSnackbar({
                    open: true,
                    message: 'Falha ao conectar com o Facebook/Instagram',
                    severity: 'error'
                  });
                }}
                scope="read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,pages_read_engagement"
                render={({ onClick }) => (
                  <ConnectButton 
                    onClick={onClick}
                    bgColor="#4267B2"
                    hoverColor="#365899"
                    disabled={metaStatus.facebook && metaStatus.instagram}
                  >
                    <FacebookIcon />
                    {(metaStatus.facebook || metaStatus.instagram) ? 'Reconectar' : 'Conectar com Facebook'}
                  </ConnectButton>
                )}
              />
            </IntegrationCard>
          </Grid>

          {/* Google Business Profile Integration */}
          <Grid item xs={12} md={4}>
            <IntegrationCard>
              <CardTitle>
                <StorefrontIcon style={{ color: '#DB4437' }} />
                <Typography variant="h6">Google Perfil de Empresa</Typography>
              </CardTitle>
              <StatusBadge connected={integrations.google}>
                {integrations.google ? 'Conectado' : 'Não Conectado'}
              </StatusBadge>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Integre seu Perfil de Empresa do Google para análise de avaliações e interações com seus clientes.
              </Typography>
              <ConnectButton 
                onClick={() => login()}
                bgColor="#DB4437"
                hoverColor="#C53929"
                disabled={integrations.google}
              >
                <GoogleIcon />
                Conectar com Google
              </ConnectButton>
            </IntegrationCard>
          </Grid>

          {/* Portal de Notícias Integration */}
          <Grid item xs={12} md={4}>
            <IntegrationCard>
              <CardTitle>
                <NewspaperIcon style={{ color: '#2196F3' }} />
                <Typography variant="h6">Portais de Notícias</Typography>
              </CardTitle>
              <StatusBadge connected={true}>
                Integrado Nativamente
              </StatusBadge>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Nossa varredura automática busca menções da sua marca em portais de notícias. Este processo pode levar algumas horas para coletar todas as informações relevantes.
              </Typography>
              <ConnectButton 
                bgColor="#2196F3"
                disabled={true}
              >
                <NewspaperIcon />
                Conectar Portal
              </ConnectButton>
            </IntegrationCard>
          </Grid>

          {/* Site Institucional Integration */}
          <Grid item xs={12} md={4}>
            <IntegrationCard>
              <CardTitle>
                <Typography variant="h6">Site Institucional</Typography>
              </CardTitle>
              <StatusBadge connected={!!website}>
                {website ? 'Configurado' : 'Não Configurado'}
              </StatusBadge>
              <WebsiteInput
                label="URL do site"
                variant="outlined"
                value={website}
                onChange={handleWebsiteChange}
                disabled={!isEditingWebsite}
                className={!isEditingWebsite ? 'disabled' : ''}
              />
              <ConnectButton 
                onClick={isEditingWebsite ? handleWebsiteSave : () => setIsEditingWebsite(true)}
                bgColor="#4CAF50"
                hoverColor="#45a049"
              >
                {isEditingWebsite ? (
                  <>
                    <EditIcon />
                    Salvar Site
                  </>
                ) : (
                  <>
                    <EditIcon />
                    {website ? 'Configurar Site' : 'Configurar Site'}
                  </>
                )}
              </ConnectButton>
            </IntegrationCard>
          </Grid>

          {/* Reclame Aqui Integration */}
          <Grid item xs={12} md={4}>
            <IntegrationCard>
              <CardTitle>
                <ReclameAquiIcon style={{ color: '#F5840A' }} />
                <Typography variant="h6">Reclame Aqui</Typography>
              </CardTitle>
              <StatusBadge connected={!!reclameAquiUser}>
                {reclameAquiUser ? 'Configurado' : 'Não Configurado'}
              </StatusBadge>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Configure o usuário da sua empresa no Reclame Aqui para análise de reclamações e interações com clientes.
              </Typography>
              <ReclameAquiInput
                label="Usuário do Reclame Aqui"
                variant="outlined"
                value={reclameAquiUser}
                onChange={handleReclameAquiChange}
                disabled={!isEditingReclameAqui}
                className={!isEditingReclameAqui ? 'disabled' : ''}
                helperText="Digite o nome que aparece depois de /empresa/ no seu navegador"
                InputProps={{
                  startAdornment: <span style={{ marginRight: '8px' }}>https://www.reclameaqui.com.br/empresa/</span>,
                }}
              />
              <ConnectButton 
                onClick={isEditingReclameAqui ? handleReclameAquiSave : () => setIsEditingReclameAqui(true)}
                bgColor="#F5840A"
                hoverColor="#E67A09"
              >
                <EditIcon />
                {isEditingReclameAqui ? 'Salvar' : (reclameAquiUser ? 'Editar' : 'Configurar')}
              </ConnectButton>
            </IntegrationCard>
          </Grid>
        </Grid>

        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={handleSnackbarClose}
        >
          <Alert 
            onClose={handleSnackbarClose} 
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        {/* Dialog para instruções de permissão */}
        <Dialog 
          open={showScopeDialog} 
          onClose={() => setShowScopeDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            Permissão Necessária
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              Para integrar com o Google Business Profile, precisamos da sua permissão para gerenciar os dados da empresa. Por favor:
            </Typography>
            <Typography variant="body1" component="ol" sx={{ mt: 2 }}>
              <li>Clique em "Tentar Novamente" abaixo</li>
              <li>Na tela de autorização do Google, clique em "Mostrar mais" conforme indicado na imagem</li>
              <li>Marque a opção "Gerenciar seus dados do Business Profile"</li>
              <li>Clique em "Continuar"</li>
            </Typography>
            <Box 
              component="img" 
              src={needPermissionImage} 
              alt="Instruções de permissão"
              sx={{ 
                width: '100%', 
                maxWidth: 500, 
                display: 'block',
                margin: '20px auto',
                border: '1px solid #ccc',
                borderRadius: '4px'
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowScopeDialog(false)}>
              Fechar
            </Button>
            <Button 
              onClick={() => {
                setShowScopeDialog(false);
                login(); // Tenta o login novamente
              }} 
              variant="contained" 
              color="primary"
            >
              Tentar Novamente
            </Button>
          </DialogActions>
        </Dialog>

        {/* Modal de boas-vindas */}
        <Dialog
          open={showWelcomeModal}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ textAlign: 'center', color: '#375c37' }}>
            Bem-vindo(a) às Integrações da Vinbol!
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" paragraph sx={{ mt: 2 }}>
              Esta é a área onde você pode conectar diferentes fontes de dados para enriquecer sua análise de percepção pública. Quanto mais integrações você configurar, mais precisa e abrangente será a análise da sua marca.
            </Typography>
            <Typography variant="body1" paragraph>
              Recomendamos fortemente que você configure o máximo possível de integrações para obter os melhores resultados. Cada fonte de dados traz perspectivas únicas sobre como sua marca é percebida:
            </Typography>
            <Box component="ul" sx={{ pl: 3 }}>
              <Typography component="li" paragraph>
                <strong>Meta (Facebook + Instagram):</strong> Análise de comentários, avaliações e engajamento nas redes sociais
              </Typography>
              <Typography component="li" paragraph>
                <strong>Google Perfil de Empresa:</strong> Avaliações e interações com clientes no Google Perfil de Empresa, antigo Google Meu Negócio
              </Typography>
              <Typography component="li" paragraph>
                <strong>Portais de Notícias:</strong> Menções da sua marca na mídia (já integrado automaticamente)
              </Typography>
              <Typography component="li" paragraph>
                <strong>Site Institucional:</strong> Análise do seu site oficial, como você deseja se posicionar para seu público
              </Typography>
              <Typography component="li" paragraph>
                <strong>Reclame Aqui:</strong> Análise de reclamações e satisfação do cliente
              </Typography>
            </Box>
            <Typography variant="body1" paragraph>
              Você pode configurar essas integrações agora ou mais tarde, conforme sua necessidade. No entanto, quanto mais fontes de dados estiverem disponíveis, mais rica será sua análise.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
            <Button
              variant="contained"
              onClick={() => setShowWelcomeModal(false)}
              sx={{
                backgroundColor: '#f5bb09',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#d4a007',
                },
              }}
            >
              Começar Configuração
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
};

export default AdminDashboard; 