import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/Logo";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  // Function to check if the device is likely a mobile device
  const isMobile = () => window.innerWidth <= 768;

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <LogoIcon />
          {/* <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>
            fanatic
          </h1> */}
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
      {/* <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="hero"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Experimente com a sua marca
          </Link>
        </li> */}
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy={true}
            smooth={true}
            offset={-60}
          >
            A Vinbol
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="services"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Diferencial e como te ajudamos
          </Link>
        </li>
        {/* <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="projects"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Projects
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="blog"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Blog
          </Link>
        </li> */}
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="pricing"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Planos
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="contact"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Contato
          </Link>
        </li>
      </UlStyle>
      {isMobile() ? (
        <MobileMessage className="flexSpaceCenter">
          <li className="semiBold font15 whiteColor">
            Para uma experiência completa, a plataforma deve ser acessada de um computador ou tablet.
          </li>
        </MobileMessage>
      ) : (
        <UlStyle className="flexSpaceCenter">
          <li className="semiBold font15 pointer">
            <a href="/" style={{ padding: "10px 30px 10px 0" }} className="whiteColor">
              Entrar
            </a>
          </li>
          <li className="semiBold font15 pointer flexCenter">
            <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
            Acessar demonstração
            </a>
          </li>
        </UlStyle>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px; // Default width for larger screens
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-100%")}; // Use 100% to ensure it's off-screen regardless of width
  z-index: 9999;
  transition: right 0.3s ease-in-out; // Smooth transition for sidebar
  overflow-y: auto; // Scroll inside sidebar if content is too long
  @media (max-width: 768px) { // Adjust to a common smaller breakpoint
    width: 75%; // Use 75% of the screen width for the sidebar
  }
  @media (max-width: 400px) { // Adjust for very small devices
    width: 100%; // Sidebar takes full width on small devices
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
const MobileMessage = styled(UlStyle)`
  text-align: center;
  padding: 20px;
`;
