// src/components/PricingCalculator.js
import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';

const Content = styled.div`
  display: flex;
  width: 80%;
  height: 70%;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
`;

const LeftSide = styled.div`
  flex: 2;
  background-color: #ececec;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #3a5c38;
  padding: 40px;
  box-sizing: border-box;
`;

const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;

const TextContainer = styled.div`
  flex: 1;
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
  line-height: 1.2;
`;

const SubTitle = styled.h2`
  font-size: 1.2em;
  margin-bottom: 20px;
`;

const BodyText = styled.p`
  font-size: 1em;
  margin-bottom: 20px;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #3a5c38;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #f5bb09;
    color: #3a5c38;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
`;

const Footer = styled.footer`
  font-size: 0.9em;
  color: #3a5c38;
  margin-top: auto;
`;

const RightSide = styled.div`
  flex: 1;
  background-color: #f5bb09;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #3a5c38;
`;

const InputBoxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BottomBar = styled.div`
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 0 0 10px 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #3a5c38;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;

  &:hover {
    background-color: #f5bb09;
    color: #3a5c38;
  }
`;

const Result = styled.div`
  margin-top: 10px;
  font-size: 1.2em;
`;

const CalculatorImage = styled.img`
  width: 100%;
`;

const PricingCalculator = ({
  instagramUser,
  setInstagramUser,
  googleBusinessUser,
  setGoogleBusinessUser,
  reclameAquiUser,
  setReclameAquiUser,
  monthlyRevenue,
  setMonthlyRevenue,
  handleCalculate,
  result,
  formatCurrency,
  buildingImage,
}) => (
  <Content>
    <LeftSide>
      <Title>
        Bem-vindo à calculadora
        <br />
        de preços da Vinbol.AI
      </Title>
      <CenteredContent>
        <TextContainer>
          <SubTitle>Comece com sua estimativa</SubTitle>
          <BodyText>Adicione e configure planos e funcionalidades extras para obter uma estimativa de custo para compartilhar com sua equipe.</BodyText>
          <AddButton>+ Adicionar à estimativa</AddButton>
        </TextContainer>
        <ImageContainer>
          <CalculatorImage src={buildingImage} alt="Construção" />
        </ImageContainer>
      </CenteredContent>
      <div>
        <Divider />
        <Footer>
          Esta ferramenta cria um valor inicial de custos com base na análise no faturamento, engajamento e relevância da sua marca nas mídias sociais. Esse valor tenderá a mudar conforme seu posicionamento digital melhora. Saiba mais.
        </Footer>
      </div>
    </LeftSide>
    <RightSide>
      <Section>
        <SectionTitle>Mídias Sociais e Internet</SectionTitle>
        <InputBoxes>
          <TextField
            id="instagram"
            name="instagram"
            label="Instagram"
            variant="outlined"
            onChange={(e) => setInstagramUser(e.target.value)}
            helperText={'Digite o usuário da sua marca no Instagram.'}
            InputProps={{
              startAdornment: <span style={{ marginRight: '8px' }}>@</span>,
            }}
            style={{ fontSize: '14px' }}
            InputLabelProps={{
              style: { fontSize: '14px' },
            }}
            FormHelperTextProps={{
              style: { fontSize: '12px' },
            }}
          />
          <TextField
            id="gbusiness"
            name="gbusiness"
            label="Google Meu Negócio"
            variant="outlined"
            onChange={(e) => setGoogleBusinessUser(e.target.value)}
            helperText={'Acesse o Google Mapas e busque pela sua marca. Digite o nome que aparece depois de /place/ no seu navegador. Esse é o usuário da marca no Google Meu Negócio'}
            InputProps={{
              startAdornment: <span style={{ marginRight: '8px' }}>https://www.google.com/maps/place/</span>,
            }}
            style={{ fontSize: '14px' }}
            InputLabelProps={{
              style: { fontSize: '14px' },
            }}
            FormHelperTextProps={{
              style: { fontSize: '12px' },
            }}
          />
          <TextField
            id="reclameAqui"
            name="reclameAqui"
            label="Reclame Aqui"
            variant="outlined"
            onChange={(e) => setReclameAquiUser(e.target.value)}
            helperText={'Acesse o Reclame Aqui e busque pela sua marca. Digite o nome que aparece depois de /empresa/ no seu navegador. Esse é o usuário da marca no Reclame Aqui'}
            InputProps={{
              startAdornment: <span style={{ marginRight: '8px' }}>https://www.reclameaqui.com.br/empresa/</span>,
            }}
            style={{ fontSize: '14px' }}
            InputLabelProps={{
              style: { fontSize: '14px' },
            }}
            FormHelperTextProps={{
              style: { fontSize: '12px' },
            }}
          />
        </InputBoxes>
      </Section>
      <Section>
        <SectionTitle>Empresa</SectionTitle>
        <InputBoxes>
          <TextField
            id="monthlyRevenue"
            name="monthlyRevenue"
            label="Faturamento médio mensal (R$)"
            variant="outlined"
            value={monthlyRevenue}
            onChange={(e) => setMonthlyRevenue(e.target.value.replace(/\D/g, ''))}
            InputProps={{
              inputComponent: ({ inputRef, ...props }) => (
                <input {...props} ref={inputRef} style={{ textAlign: 'right' }} />
              ),
            }}
            helperText={'Digite o faturamento médio mensal da sua empresa.'}
            style={{ fontSize: '14px' }}
            InputLabelProps={{
              style: { fontSize: '14px' },
            }}
            FormHelperTextProps={{
              style: { fontSize: '12px' },
            }}
          />
        </InputBoxes>
      </Section>
      <Button onClick={handleCalculate}>Calcular</Button>
      {result && <Result>{formatCurrency(result)}</Result>}
    </RightSide>
  </Content>
);

export default PricingCalculator;
