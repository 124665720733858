import ServiceBox from "../Elements/ServiceBox";
import styled from "styled-components";

export default function StackedWordsComponent({ selectedWord  }) {
    switch (selectedWord) {
      case "empresa":
        return (
          <ServiceBoxRow className="flex">
              <ServiceBoxWrapper>
                <ServiceBox
                  icon=""
                  title="Monitoramento de Reputação"
                  subtitle="A Vinbol.AI permite que empresas monitorem sua reputação online. O sistema captura e analisa menções e interações sobre a marca em diversas plataformas, oferecendo uma visão completa de como a marca é vista pelo público. Isso permite que as empresas ajam rapidamente para abordar feedbacks negativos e aproveitem oportunidades de engajamento positivo."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon=""
                  title="Análise de Sentimento para Decisões Estratégicas"
                  subtitle="Com a análise de sentimento da Vinbol.AI, as empresas podem obter insights detalhados sobre a percepção do público em relação a seus produtos e serviços. Essa compreensão profunda permite que as empresas façam ajustes estratégicos em suas campanhas de marketing, desenvolvimento de produtos e atendimento ao cliente, garantindo uma melhor conexão com o mercado."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon=""
                  title="Relatórios de Performance e Engajamento"
                  subtitle="A Vinbol.AI gera relatórios detalhados que ajudam as empresas a entender o desempenho de suas campanhas e a eficácia de suas estratégias de conexão com seu público alvo. Esses insights valiosos permitem identificar pontos fortes e áreas de melhoria, facilitando a tomada de decisões informadas e a otimização contínua das ações de marketing e vendas."
                />
              </ServiceBoxWrapper>
          </ServiceBoxRow>
        )
        break;
      case "criador de conteúdo":
        return (
          <ServiceBoxRow className="flex">
              <ServiceBoxWrapper>
                <ServiceBox
                  icon=""
                  title="Monitoramento Inteligente de Presença Online"
                  subtitle="A Vinbol.AI oferece aos criadores de conteúdo uma ferramenta poderosa para monitorar sua presença online. Utilizando a metodologia ACPS®, o sistema captura menções e interações em redes sociais e em toda a internet, proporcionando uma visão clara e detalhada de como sua marca pessoal é percebida. Isso permite ajustar estratégias de conteúdo e engajamento para maximizar impacto e alcance."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon=""
                  title="Análise de Sentimento Avançada"
                  subtitle="Com a análise de sentimento avançada de Vinbol.AI, criadores de conteúdo podem entender melhor as emoções e reações do público em relação ao seu conteúdo. O sistema não só avalia postagens e comentários, mas também analisa vídeos e imagens, fornecendo insights profundos que ajudam a melhorar a comunicação e a conectar-se de forma mais eficaz com os seguidores."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon=""
                  title="Insights para Crescimento e Engajamento"
                  subtitle="
                  A Vinbol.AI não apenas monitora e analisa dados, mas também gera relatórios acionáveis que ajudam criadores de conteúdo a identificar oportunidades de crescimento. Ao entender quais tipos de conteúdo geram mais engajamento e quais áreas precisam de melhoria, os influenciadores podem otimizar suas estratégias e expandir sua influência de maneira sustentada e eficaz."
                />
              </ServiceBoxWrapper>
          </ServiceBoxRow>
        )
        break;
      case "agência de marketing digital":
        return (
          <ServiceBoxRow className="flex">
              <ServiceBoxWrapper>
                <ServiceBox
                  icon=""
                  title="Monitoramento e Análise de Campanhas"
                  subtitle="A Vinbol.AI fornece às agências de marketing uma plataforma robusta para monitorar e analisar campanhas de marketing. Através da captura de dados de várias fontes e da análise profunda de interações e menções, as agências podem ajustar rapidamente suas estratégias para maximizar o impacto das campanhas e alcançar melhores resultados para seus clientes."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon=""
                  title="Insights Detalhados sobre Público e Mercado"
                  subtitle="Com a análise de sentimento e a metodologia ACPS® da Vinbol.AI, as agências de marketing podem obter insights detalhados sobre as emoções e reações do público-alvo. Essas informações são cruciais para a criação de campanhas mais eficazes e para a personalização de mensagens que ressoam com o público, aumentando o engajamento e a fidelidade à marca."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon=""
                  title="Relatórios Personalizados para Clientes"
                  subtitle="A Vinbol.AI permite que as agências de marketing gerem relatórios personalizados para seus clientes, destacando o desempenho das campanhas e fornecendo recomendações acionáveis. Esses relatórios detalhados ajudam a demonstrar o valor das estratégias adotadas, facilitando a comunicação com os clientes e a justificativa dos investimentos em marketing."
                />
              </ServiceBoxWrapper>
          </ServiceBoxRow>
        )
        break;
      default:
        break;
    }
}

const ServiceBoxRow = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to next line */
  justify-content: space-between; /* Distribute items evenly */
`;

const ServiceBoxWrapper = styled.div`
  width: calc(33.33% - 5%); /* 33.33% width with 5% margin-right */
  margin-bottom: 20px; /* Add some bottom margin for spacing */
  margin-top: 0px;
  @media (max-width: 860px) {
    width: 100%; /* Full width on smaller screens */
    text-align: center;
  }
`;