import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";
import AdditionalFeaturesStrip from "../Elements/AdditionalFeaturesStrip";

export default function Pricing({ setIsPricingSectionContact, setSubject, setMessage }) {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Nossos pacotes de soluções</h1>
            <p className="font13">
              Compreendemos a diversidade dos desafios enfrentados por empresas, influenciadores e agências, por isso, nossos pacotes são desenhados para se alinhar perfeitamente ao seu estágio atual de crescimento e objetivos específicos. Desde a gestão de reputação online até assessoria de marketing e análises competitivas profundas, oferecemos o suporte necessário para que você se destaque no ambiente digital.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon=""
                price=""
                title="Plano Marca"
                text="Monitore sentimentos online e adapte estratégias; construa uma presença positiva e resiliente no ambiente digital que te permita atingir seus objetivos de negócio."
                offers={[
                  { name: "Gestão da marca", checked: true },
                  { name: "Análise detalhada do momento da marca (indicadores, menções a marca, nuvem de palavras)", checked: true },
                  { name: "Análise de Forças, oportunidades, fraquezas e ameaças, gerada por IA (SWOT Avançada)", checked: true },
                  { name: "Entenda o quanto a sua marca impacta seus objetivos de negócio com o ACPS® Compara", checked: true },
                ]}
                action={() => {
                  setIsPricingSectionContact(true)
                  setSubject('Interesse no Plano Marca')
                  setMessage('Olá, quero saber mais sobre o Plano Marca.')
                }}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon=""
                price=""
                title="Plano Campanha"
                text="Defina as publicações de uma campanha de marketing específica e dê um zoom-in no ACPS® da marca para avaliar o resultado, a eficência e o impacto de sua campanha."
                offers={[
                  { name: "Gestão da campanha", checked: true },
                  { name: "Análise detalhada da campanha (indicadores, menções a marca, nuvem de palavras)", checked: true },
                  { name: "Análise de Forças, oportunidades, fraquezas e ameaças, gerada por IA (SWOT Avançada)", checked: true },
                  { name: "Entenda o quanto a sua campanha impacta seus objetivos de negócio com o ACPS® Compara", checked: true },
                ]}
                action={() => {
                  setIsPricingSectionContact(true)
                  setSubject('Interesse no Plano Campanha')
                  setMessage('Olá, quero saber mais sobre o Plano Campanha.')
                }}
              />
            </TableBox>
            <AdditionalFeaturesStripContainer>
              <AdditionalFeaturesStrip
                additionalFeatures={[
                  {
                    name: "Modo ao vivo",
                    price: "",
                    tooltip: "Aproveite a oportunidade! Mantenha-se conectado com o sentimento do seu público em tempo real durante uma Live, uma campanha de marketing, uma pesquisa, o lançamento de um produto ou serviço. Obtenha insights valiosos sobre a receptividade da sua estratégia a cada segundo. Não deixe passar nenhum momento crucial!"
                  },
                  {
                    name: "Antecipação de crise", 
                    price: "", 
                    tooltip: "Uma boa reputação é difícil de ganhar e fácil de perder. É crucial reagir às ameaças antecipadamente. Detecte eventos críticos que podem quebrar a reputação da sua marca. Receba notificações em tempo real e aja rapidamente."
                  }
                ]}
              />
            </AdditionalFeaturesStripContainer>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const TablesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;

const AdditionalFeaturesStripContainer = styled.div`
  width: 38%;
  display: flex;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 860px) {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }
`;
