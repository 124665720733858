import * as React from "react";
import logo from "../../img/real.png"

function SvgComponent(props) {
  return (
    <img src={logo} width={64} height={64} alt="React Logo" style={{marginTop:'-25', marginLeft: '-10px'}}/>
  );
}

export default SvgComponent;
