import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import Hero from "../components/Sections/Hero"

export default function Landing() {
  const [subject, setSubject] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [isPricingSectionContact, setIsPricingSectionContact] = React.useState(false)

  return (
    <>
      <TopNavbar />
      {/* <Hero /> */}
      <Header />
      <Services />
      <Projects />
      {/* <Blog /> */}
      <Pricing setIsPricingSectionContact={setIsPricingSectionContact} setSubject={setSubject} setMessage={setMessage} />
      <Contact isPricingSectionContact={isPricingSectionContact} pricingSectionSubject={subject} pricingSectionMessage={message} />
      <Footer />
    </>
  );
}


