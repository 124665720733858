import * as React from 'react';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/system';
import GoogleIcon from '@mui/icons-material/Google';

const GoogleButton = styled(Button)({
  backgroundColor: 'white',
  color: 'black',
  textTransform: 'none',
  border: '1px solid #ccc',
  padding: '10px 20px',
  borderRadius: '4px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    boxShadow: 'none',
  },
});

const EmailButton = styled(Button)({
  backgroundColor: 'white',
  color: 'black',
  textTransform: 'none',
  padding: '10px 20px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
});

export default function AddressForm({ 
  setFirstName, 
  setLastName, 
  setEmail, 
  setPassword,
  setNomeEmpresa,
  firstNameError, setFirstNameError,
  lastNameError, setLastNameError,
  emailError, setEmailError,
  passError, setPassError,
  setLoginMethod,
  setUserUid
  }) {
  
  const [signupMethod, setSignupMethod] = useState(null);
  const [googleFirstName, setGoogleFirstName] = useState('');
  const [googleLastName, setGoogleLastName] = useState('');
  const [googleEmail, setGoogleEmail] = useState('');

  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const handleGoogleSignup = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const displayName = user.displayName.split(' ');
      const firstName = displayName[0];
      const lastName = displayName.slice(1).join(' ');

      setGoogleFirstName(firstName);
      setGoogleLastName(lastName);
      setGoogleEmail(user.email);

      setFirstName(firstName);
      setLastName(lastName);
      setEmail(user.email);

      setSignupMethod('google');
      setLoginMethod('google');
      setUserUid(user.uid);
    } catch (error) {
      console.error('Error during Google Sign-In', error);
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
        Informações do usuário
      </Typography>
      {!signupMethod ? (
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item xs={12} sm={6}>
            <GoogleButton 
              variant="contained" 
              startIcon={<GoogleIcon />} // Using colored Google icon
              fullWidth
              onClick={handleGoogleSignup}
              sx={{ mb: 2 }}
            >
              Continuar com Google
            </GoogleButton>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ width: '100%', mb: 2 }}>
            <Divider>OU</Divider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <EmailButton 
              variant="contained" 
              fullWidth
              onClick={() => {
                setSignupMethod('email');
                setLoginMethod('email');
              }}
              sx={{ mb: 2 }}
            >
              Continuar com Email
            </EmailButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="Primeiro nome"
              fullWidth
              autoComplete="given-name"
              variant="standard"
              onChange={(e) => {
                setFirstName(e.target.value);
                setFirstNameError(e.target.value.trim() === ''); // Checa se não está vazio
              }}
              error={firstNameError}
              helperText={firstNameError ? 'Campo obrigatório' : ''}
              value={signupMethod === 'google' ? googleFirstName : undefined}
              disabled={signupMethod === 'google'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Último nome"
              fullWidth
              autoComplete="family-name"
              variant="standard"
              onChange={(e) => {
                setLastName(e.target.value);
                setLastNameError(e.target.value.trim() === ''); // Checa se não está vazio
              }}
              error={lastNameError}
              helperText={lastNameError ? 'Campo obrigatório' : ''}
              value={signupMethod === 'google' ? googleLastName : undefined}
              disabled={signupMethod === 'google'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="email"
              name="email"
              label="Email profissional"
              fullWidth
              // autoComplete="shipping address-line1"
              variant="standard"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(e.target.value.trim() === ''); // Checa se não está vazio
              }}
              error={emailError}
              helperText={emailError ? 'Campo obrigatório' : ''}
              value={signupMethod === 'google' ? googleEmail : undefined}
              disabled={signupMethod === 'google'}
            />
          </Grid>
          {signupMethod === 'email' && (
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="new-password"
                variant="standard"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPassError(e.target.value.trim() === ''); // Checa se não está vazio
                }}
                error={passError}
                helperText={passError ? 'Campo obrigatório' : ''}
              />
            </Grid>
          )}
        </Grid>
      )}
    </React.Fragment>
  );
}
