import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Link, MenuItem, Select, FormControl, InputLabel, Paper, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download'; // Import the download icon
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'; // Import the locale you want

dayjs.locale('pt-br'); // Use the Portuguese locale globally

const CampaignInfo = ({ campaigns, campaignPeriod, campaignPosts, onCampaignUpdate, onCampaignChange, handleExportToPdf, onCampaignAdd }) => {
  const { startDate, endDate } = campaignPeriod;
  const [open, setOpen] = useState(false);
  const [editedCampaignName, setEditedCampaignName] = useState("");
  const [editedStartDate, setEditedStartDate] = useState(null);
  const [editedEndDate, setEditedEndDate] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postDialogOpen, setPostDialogOpen] = useState(false);
  const [currentPost, setCurrentPost] = useState({ description: '', source: '', media_type: '', url: '' });
  const [editIndex, setEditIndex] = useState(null);
  const [exporting, setExporting] = useState(false); // State to manage export popup
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [newCampaignDialogOpen, setNewCampaignDialogOpen] = useState(false);
  const [newCampaignName, setNewCampaignName] = useState("");
  const [newCampaignStartDate, setNewCampaignStartDate] = useState(null);
  const [newCampaignEndDate, setNewCampaignEndDate] = useState(null);
  const [swotFrequency, setSwotFrequency] = useState('weekly');

  useEffect(() => {
    if (campaigns.length > 0) {
      const lastCampaign = campaigns[campaigns.length - 1];
      setSelectedCampaign(lastCampaign.name);
    }
  }, [campaigns]);

  useEffect(() => {
    const selectedCampaignData = campaigns.find(campaign => campaign.name === selectedCampaign);
    if (selectedCampaignData) {
      setEditedStartDate(dayjs(selectedCampaignData.period.startDate));
      setEditedEndDate(dayjs(selectedCampaignData.period.endDate));
      setEditedCampaignName(selectedCampaignData.name);
      setPosts(selectedCampaignData.posts || []);
    }
  }, [selectedCampaign, campaigns]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewCampaignDialogOpen(false);
  };

  const handleSave = () => {
    onCampaignUpdate(
      editedCampaignName,
      {
        startDate: editedStartDate.format('YYYY-MM-DD'),
        endDate: editedEndDate.format('YYYY-MM-DD')
      },
      posts
    );
    handleClose();
  };

  const handlePostDialogOpen = () => {
    setCurrentPost({ description: '', source: '', media_type: '', url: '' });
    setEditIndex(null);
    setPostDialogOpen(true);
  };

  const handlePostDialogClose = () => {
    setPostDialogOpen(false);
  };

  const handlePostSave = () => {
    if (editIndex !== null) {
      const updatedPosts = [...posts];
      updatedPosts[editIndex] = currentPost;
      setPosts(updatedPosts);
    } else {
      setPosts([...posts, currentPost]);
    }
    setPostDialogOpen(false);
    // Save the post to Firebase (replace with your Firebase code)
  };

  const handlePostEdit = (index) => {
    setCurrentPost(posts[index]);
    setEditIndex(index);
    setPostDialogOpen(true);
  };

  const handlePostDelete = (index) => {
    if (window.confirm('Tem certeza de que deseja deletar esta mídia?')) {
      const updatedPosts = posts.filter((_, i) => i !== index);
      setPosts(updatedPosts);
      // Delete the post from Firebase (replace with your Firebase code)
    }
  };

  const formatDate = (dateStr) => {
    if (dateStr) {
      const [year, month, day] = dateStr.split('-');
      return `${day}/${month}/${year}`;
    }
  };

  const handleExportClick = () => {
    setExporting(true);
    handleExportToPdf().finally(() => setExporting(false));
  };

  const handleCampaignChange = (event) => {
    const newCampaignName = event.target.value;
    setSelectedCampaign(newCampaignName);
    onCampaignChange(newCampaignName); // Communicate the selected campaign to the parent component
  };

  const handleAddCampaignClick = () => {
    setNewCampaignDialogOpen(true);
  };

  const handleAddCampaignSave = () => {
    if (newCampaignName && newCampaignStartDate && newCampaignEndDate) {
      onCampaignAdd({
        name: newCampaignName,
        period: {
          startDate: newCampaignStartDate.format('YYYY-MM-DD'),
          endDate: newCampaignEndDate.format('YYYY-MM-DD')
        },
        posts: [],
        swotFrequency: swotFrequency // Add SWOT frequency to the new campaign data
      });
      setNewCampaignName("");
      setNewCampaignStartDate(null);
      setNewCampaignEndDate(null);
      setSwotFrequency('weekly'); // Reset to default
      setNewCampaignDialogOpen(false);
    }
  };

  const isCampaignFinished = (endDate) => {
    const end = new Date(endDate);
    const today = new Date();
    today.setHours(today.getHours() - 3); // Adjust to UTC-3
  
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    return end < yesterday;
  };

  const campaignFinished = isCampaignFinished(endDate);

  return (
    <div id="campaignInfo">
      <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="-5px" marginBottom="10px">
        <Box display="flex" alignItems="center">
          <FormControl variant="outlined" size="small" sx={{ minWidth: 200, height: '40px' }}>
            <InputLabel>Campanha</InputLabel>
            <Select
              value={selectedCampaign}
              onChange={handleCampaignChange}
              label="Campanha"
              sx={{ fontSize: '0.875rem', height: '40px', alignItems: 'center' }}
            >
              {campaigns.map((campaign, index) => (
                <MenuItem key={index} value={campaign.name}>
                  {campaign.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="body2" sx={{ marginLeft: 2 }}>
            Período: {formatDate(startDate)} a {formatDate(endDate)}
          </Typography>
          {campaignFinished && (
            <Box display="flex" alignItems="center" sx={{ marginLeft: 2, bgcolor: 'lightgrey', borderRadius: '12px', padding: '0 8px' }}>
              <InfoIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontFamily: 'Roboto Mono, monospace', fontSize: '0.75rem', marginLeft: 1 }}>
                Finalizada
              </Typography>
            </Box>
          )}
        </Box>

        <Box>
          <IconButton aria-label="Configurações de campanha" onClick={handleClickOpen}>
            <SettingsIcon />
            <Typography variant="caption">Configurações de campanha</Typography>
          </IconButton>
          <IconButton aria-label="Exportar relatório" onClick={handleExportClick}>
            <DownloadIcon />
            <Typography variant="caption">Exportar relatório</Typography>
          </IconButton>
        </Box>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Editando detalhes da campanha</DialogTitle>
          <DialogContent>
            <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
              <TextField
                autoFocus
                margin="dense"
                label="Nome da campanha"
                type="text"
                fullWidth
                variant="standard"
                value={editedCampaignName}
                onChange={(e) => setEditedCampaignName(e.target.value)}
                sx={{ marginRight: 2, flex: 1 }}
              />
              <Button 
                startIcon={<AddIcon />} 
                onClick={handleAddCampaignClick} 
                size="small" 
                variant="outlined"
              >
                Adicionar Campanha
              </Button>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Data de início"
                value={editedStartDate}
                onChange={setEditedStartDate}
                renderInput={(params) => <TextField {...params} />}
                format="DD/MM/YYYY"
                sx={{ marginBottom: 2 }}
              />
              <DatePicker
                label="Data de término"
                value={editedEndDate}
                onChange={setEditedEndDate}
                renderInput={(params) => <TextField {...params} />}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
            <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
              Mídias da campanha ({posts.length}):
              <Button 
                startIcon={<AddIcon />} 
                onClick={handlePostDialogOpen} 
                size="small" 
                variant="outlined" 
                sx={{ ml: 1 }}
              >
                Adicionar mídia
              </Button>
            </Typography>
            {posts?.map((post, index) => (
              <Paper key={index} sx={{ p: 2, mb: 2 }}>
                <Typography variant="body1">
                  <Link href={post?.url || '#'} target="_blank" rel="noopener noreferrer">
                    {post?.description?.length > 50 ? `${post?.description.slice(0, 50)}...` : post?.description}
                  </Link>
                </Typography>
                <Typography variant="caption" sx={{ display: 'block', color: 'gray', mt: 1 }}>
                  {post?.source?.charAt(0).toUpperCase() + post?.source?.slice(1)} - {post?.media_type?.charAt(0).toUpperCase() + post?.media_type?.slice(1)}
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                  <IconButton size="small" onClick={() => handlePostEdit(index)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small" onClick={() => handlePostDelete(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Paper>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleSave}>Salvar</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={newCampaignDialogOpen} onClose={() => setNewCampaignDialogOpen(false)}>
          <DialogTitle>Adicionar nova campanha</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Nome da nova campanha"
              type="text"
              fullWidth
              variant="standard"
              value={newCampaignName}
              onChange={(e) => setNewCampaignName(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Data de início"
                value={newCampaignStartDate}
                onChange={setNewCampaignStartDate}
                renderInput={(params) => <TextField {...params} />}
                format="DD/MM/YYYY"
                sx={{ marginBottom: 2 }}
              />
              <DatePicker
                label="Data de término"
                value={newCampaignEndDate}
                onChange={setNewCampaignEndDate}
                renderInput={(params) => <TextField {...params} />}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
            <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
              Com qual frequência a Análise SWOT da Campanha deve ser atualizada?
            </Typography>
            <RadioGroup
              row
              value={swotFrequency}
              onChange={(e) => setSwotFrequency(e.target.value)}
            >
              <FormControlLabel value="weekly" control={<Radio />} label="Semanal" />
              <FormControlLabel value="daily" control={<Radio />} label="Diário" />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNewCampaignDialogOpen(false)}>Cancelar</Button>
            <Button onClick={handleAddCampaignSave}>Salvar</Button>
          </DialogActions>
        </Dialog>
        
        <Dialog open={postDialogOpen} onClose={handlePostDialogClose}>
          <DialogTitle>{editIndex !== null ? 'Editar Mídia' : 'Adicionar Mídia'}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Descrição"
              type="text"
              fullWidth
              variant="standard"
              value={currentPost.description}
              onChange={(e) => setCurrentPost({ ...currentPost, description: e.target.value })}
              sx={{ marginBottom: 2 }}
            />
            <FormControl fullWidth variant="standard" sx={{ marginBottom: 2 }}>
              <InputLabel id="source-label">Fonte</InputLabel>
              <Select
                labelId="source-label"
                id="source-select"
                value={currentPost.source}
                onChange={(e) => setCurrentPost({ ...currentPost, source: e.target.value })}
              >
                <MenuItem value="Instagram">Instagram</MenuItem>
              </Select>
            </FormControl>
            {currentPost.source === 'Instagram' && (
              <FormControl fullWidth variant="standard" sx={{ marginBottom: 2 }}>
                <InputLabel id="media-type-label">Tipo de mídia</InputLabel>
                <Select
                  labelId="media-type-label"
                  id="media-type-select"
                  value={currentPost.media_type}
                  onChange={(e) => setCurrentPost({ ...currentPost, media_type: e.target.value })}
                >
                  <MenuItem value="Publicação">Publicação</MenuItem>
                </Select>
              </FormControl>
            )}
            <TextField
              margin="dense"
              label="URL"
              type="text"
              fullWidth
              variant="standard"
              value={currentPost.url}
              onChange={(e) => setCurrentPost({ ...currentPost, url: e.target.value })}
              sx={{ marginBottom: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePostDialogClose}>Cancelar</Button>
            <Button onClick={handlePostSave}>Salvar</Button>
          </DialogActions>
        </Dialog>
      </Box>

      {/* Exporting popup */}
      <Dialog open={exporting}>
        <DialogContent>
          <Typography variant="body2">Exportando relatório, por favor aguarde...</Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CampaignInfo;
