import * as React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceDot } from 'recharts';
import { Typography, Paper } from '@mui/material';

// Custom Reference Dot component
const CustomReferenceDot = ({ cx, cy, onClick }) => (
  <svg x={cx - 10} y={cy - 10} width={20} height={20} onClick={onClick} style={{ cursor: 'pointer' }}>
    <circle cx={10} cy={10} r={10} fill="none" stroke="#3a5c38" strokeWidth={2} />
    <text x={10} y={15} textAnchor="middle" fill="#3a5c38" fontSize={12}>C</text>
  </svg>
);

// Format numbers to use commas as decimal separators
const formatNumber = (value) => {
  return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

// Custom Tooltip component
const CustomTooltip = ({ payload, label, active, darkMode }) => {
  if (active && payload && payload.length) {
    const acpsValue = payload.find(p => p.dataKey === 'ACPS_CLIENT')?.value ?? 'N/A';
    const irdValue = payload.find(p => p.dataKey === 'dataRobustnessIndex')?.value ?? 'N/A';
    return (
      <Paper sx={{ padding: 1, backgroundColor: darkMode ? '#1e1e1e' : '#ffffff' }}>
        <Typography variant="subtitle2" style={{ color: darkMode ? '#cccccc' : '#000000' }}>{label}</Typography>
        <Typography variant="body2" style={{ color: darkMode ? '#cccccc' : '#000000' }}>{`ACPS: ${formatNumber(acpsValue)}`}</Typography>
        {/* <Typography variant="body2" style={{ color: darkMode ? '#cccccc' : '#000000' }}>{`Índice de Robustez de Dados: ${formatNumber(irdValue)}`}</Typography> */}
      </Paper>
    );
  }
  return null;
};

export default function Orders({ 
  chartData, 
  campaignPeriod,
  updateOnFilterCampanha,
  darkMode,
  dataRobustnessIndex
 }) {

  // console.log(chartData)
  // console.log(dataRobustnessIndex)
  
  const [combinedChartData, setCombinedChartData] = React.useState([]);
  // Parse campaignPeriod dates with UTC-3
  const parseDateUTC3 = (dateStr) => {
    const date = new Date(dateStr);
    return new Date(date.getTime() + (3 * 60 * 60 * 1000)); // Convert to UTC-3
  };

  const startDate = campaignPeriod?.startDate ? parseDateUTC3(campaignPeriod.startDate) : null;
  const endDate = campaignPeriod?.endDate ? parseDateUTC3(campaignPeriod.endDate) : null;

  // Convert date to 'mon-yyyy' format in Portuguese
  const options = { year: 'numeric', month: 'short', timeZone: 'America/Sao_Paulo' };
  const formatDateToAxis = (date) => {
    const formattedDate = date.toLocaleDateString('pt-BR', options);
    return formattedDate.split('. de ').join('-');
  };
  const formattedStartDate = startDate ? formatDateToAxis(startDate) : null;
  const formattedEndDate = endDate ? formatDateToAxis(endDate) : null;

  // Find the appropriate x position in the chartData
  const xAxisData = chartData.map(data => data.day);
  const xAxisStartPosition = formattedStartDate ? xAxisData.indexOf(formattedStartDate) : -1;
  const xAxisEndPosition = formattedEndDate ? xAxisData.indexOf(formattedEndDate) : -1;

  // Calculate the number of months based on the chartData
  const numberOfMonths = Math.ceil(xAxisData.length / 30); // Estimate months by dividing the number of data points by 30

  // Combine chartData with dataRobustnessIndex
  React.useEffect(() => {
    if (chartData && dataRobustnessIndex.length) {
      // Combine chartData with dataRobustnessIndex after both are available
      const combinedData = chartData.map((data, index) => ({
        ...data,
        dataRobustnessIndex: dataRobustnessIndex[index]?.dataRobustnessIndex || 0,
      }));

      setCombinedChartData(combinedData);
    }
  }, [chartData, dataRobustnessIndex]);

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        ACPS <Typography component="h2" sx={{ textDecoration: 'underline' }} display="inline" variant="h6" color="primary" gutterBottom>
          {/* acumulado nos últimos {numberOfMonths} meses */}
          acumulado nos últimos meses
        </Typography>
      </Typography>
      <ResponsiveContainer>
        <AreaChart
          data={chartData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="0" stroke={darkMode ? "#ffffff" : "#e0e0e0"} horizontal={true} vertical={false} />
          <XAxis dataKey="day" stroke={darkMode ? "#cccccc" : "#616161"} />
          
          {/* Primary Y-Axis for ACPS */}
          <YAxis yAxisId="left" stroke={darkMode ? "#cccccc" : "#616161"} axisLine={false} tickLine={false} domain={[-100, 100]} />

          {/* Secondary Y-Axis for Data Robustness Index */}
          <YAxis yAxisId="right" orientation="right" stroke={darkMode ? "#cccccc" : "#616161"} axisLine={false} tickLine={false} domain={[0, 1]} />

          <Tooltip content={<CustomTooltip darkMode={darkMode} />} />

          {/* Area for Data Robustness Index */}
          <Area 
            yAxisId="right"
            type="monotone" 
            dataKey="dataRobustnessIndex" 
            stroke="#A0A4A8"
            fillOpacity={0.3} 
            fill="#A0A4A8" 
            strokeWidth={1.5} 
            dot={false}
          />

          {/* Area for ACPS */}
          <Area 
            yAxisId="left"
            connectNulls 
            activeDot={{
              r: 6, 
              stroke: '#616161', 
              onClick: updateOnFilterCampanha, // Ensure dot click updates filter
            }}
            type="monotone" 
            dataKey="ACPS_CLIENT" 
            stroke={darkMode ? "#00a651" : "#8884d8"} 
            fill={darkMode ? "#00a651" : "#8884d8"} 
            strokeWidth={5} 
          />

          {/* Reference Dots for Campaign Period */}
          {/* {xAxisStartPosition !== -1 && (
            <ReferenceDot 
              x={xAxisData[xAxisStartPosition]}
              y={Math.max(...chartData.map(data => data.ACPS_CLIENT))} 
              ifOverflow="extendDomain"
              shape={<CustomReferenceDot onClick={updateOnFilterCampanha} />}
            />
          )}
          {xAxisEndPosition !== -1 && (
            <ReferenceDot 
              x={xAxisData[xAxisEndPosition]}
              y={Math.max(...chartData.map(data => data.ACPS_CLIENT))} 
              ifOverflow="extendDomain"
              shape={<CustomReferenceDot onClick={updateOnFilterCampanha} />}
            />
          )} */}
        </AreaChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
