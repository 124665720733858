import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import FullButton from "../Buttons/FullButton";
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// Assets
import AddImage1 from "../../assets/img/termometro.jpg";
// Modal
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import StackedWordsComponent from "../Elements/StackedWordsComponent";

const modalStyle = {
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // width: 400,
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  // p: 4,
  // display: 'flex', justifyContent: 'center', alignItems: 'center',
  zIndex: "1000",
  overflowY: "auto"
};

export default function Services() {
  const [selectedWord, setSelectedWord] = React.useState("empresa");

  const handleClick = (word) => {
    setSelectedWord(word);
    // Perform actions based on the selected word, e.g., render different components
    switch (word) {
      case "empresa":
        // Render component for "empresa" word
        break;
      case "criador de conteúdo":
        // Render component for "criador de conteúdo" word
        break;
      case "agência de marketing digital":
        // Render component for "agência de marketing digital" word
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "0px 0", marginBottom: "200px" }}>
        <div className="container">
        <HeaderInfo>
        <h1 className="font40 extraBold">
              Como a Vinbol ajuda{" "}
              <StackedWords>
                <Word onClick={() => handleClick("empresa")}>
                  {selectedWord === "empresa" ? (
                    <HighlightedWord>empresas</HighlightedWord>
                  ) : (
                    "empresas"
                  )}
                </Word>
                <Word onClick={() => handleClick("criador de conteúdo")}>
                  {selectedWord === "criador de conteúdo" ? (
                    <HighlightedWord>criadores de conteúdo</HighlightedWord>
                  ) : (
                    "criadores de conteúdo"
                  )}
                </Word>
                <Word onClick={() => handleClick("agência de marketing digital")}>
                  {selectedWord === "agência de marketing digital" ? (
                    <HighlightedWord>agências de marketing digital</HighlightedWord>
                  ) : (
                    "agências de marketing digital"
                  )}
                </Word>
              </StackedWords>
            </h1>
          </HeaderInfo>
          <StackedWordsComponent selectedWord={selectedWord}/>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const StackedWords = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-left: 50px;
  top: -1.55em;
  
  @media (max-width: 768px) {
    top: 0;
    position: static;
  }
`;

const Word = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const HighlightedWord = styled(Word)`
  color: #ffcc00;
`;