import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CustomizedTreeView from '../../Elements/CustomizedTreeView';
import InputMask from "react-input-mask";

export default function PaymentForm({ 
  setCnpj,
  setNomeEmpresa,
  setSite,
  cnpjError, setCnpjError,
  nomeEmpresaError, setNomeEmpresaError,
  siteError, setSiteError
 }) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Informações da marca
      </Typography>
      <Grid container spacing={3}>
      <Grid item xs={12}>
      {/* <CustomizedTreeView /> */}
        <Grid item xs={12}>
            <InputMask
              mask="99.999.999/0009-99"
              // value={null}
              disabled={false}
              maskChar=" "
              onChange={(e) => {
                setCnpj(e.target.value);
                setCnpjError(e.target.value.trim() === ''); // Checa se não está vazio
              }}
            >
              {() => 
              <TextField
                id="cnpj"
                name="cnpj"
                label="CNPJ da marca"
                fullWidth
                // autoComplete="shipping address-line2"
                variant="standard"
              />}
            </InputMask>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="nomeEmpresa"
            name="nomeEmpresa"
            label="Nome fantasia da marca"
            fullWidth
            // autoComplete="shipping address-line1"
            variant="standard"
            onChange={(e) => {
              setNomeEmpresa(e.target.value);
              setNomeEmpresaError(e.target.value.trim() === ''); // Checa se não está vazio
            }}
            error={nomeEmpresaError}
            helperText={nomeEmpresaError ? 'Campo obrigatório' : ''}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
