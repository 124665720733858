import React from "react";
import styled from "styled-components";
import Link from '@mui/material/Link';
import axios from 'axios';
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";
import { Alarm } from "@mui/icons-material";

const API_URI_SEND_MESSAGE = "https://us-central1-guaruba-ai.cloudfunctions.net/sendEmail"

export default function Contact({ isPricingSectionContact, pricingSectionSubject, pricingSectionMessage }) {
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [tel, setTel] = React.useState('')
  const [subject, setSubject] = React.useState('Novo contato pelo site')
  const [message, setMessage] = React.useState('');
  const [isWhatsApp, setIsWhatsApp] = React.useState(false);

  const sendMessage = () => {
    axios
      .get(API_URI_SEND_MESSAGE, {
        params: {
          name,
          email,
          tel,
          // Condiciona o envio do Assunto e Mensagem se vem da tela de Consulta de preços ou do formulário de Contato.
          ...( isPricingSectionContact ) && { subject: pricingSectionSubject },
          ...( isPricingSectionContact ) && { message: pricingSectionMessage },
          ...( !isPricingSectionContact ) && { subject },
          ...( !isPricingSectionContact ) && { message },
        },
      })
  };

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Estamos aqui para conversar</h1>
            <p className="font13">
              Queremos ouvir suas necessidades e esclarecer dúvidas. Descubra como a Vinbol.AI pode ser a chave para desbloquear o próximo nível de sucesso em suas iniciativas digitais.
            </p>
            <p className="font13">
            Agende uma conversa com nossa equipe e comece a transformação hoje mesmo!
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
    <Form>
      <label className="font13">Nome:</label>
      <input type="text" id="name" name="name" className="font20 extraBold" onChange={(e) => setName(e.target.value)} />
      <label className="font13">Email:</label>
      <input type="text" id="email" name="email" className="font20 extraBold" onChange={(e) => setEmail(e.target.value)} />
      <label className="font13">Telefone (Com DDD):</label>
      <input type="text" id="telephone" name="telephone" className="font20 extraBold" onChange={(e) => setTel(e.target.value)} />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', whiteSpace: 'nowrap' }}>
        <label htmlFor="whatsapp" className="font13">Este número é WhatsApp</label>
        <input type="checkbox" id="whatsapp" name="whatsapp" onChange={(e) => setIsWhatsApp(e.target.checked)} />
      </div>
      {
        !isPricingSectionContact ?
        <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" onChange={(e) => setMessage(e.target.value)} /> :
        <>
          <label className="font13">Assunto:</label>
          <input type="text" id="subject" name="subject" className="font20 extraBold" value={pricingSectionSubject} readOnly />
          <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" value={pricingSectionMessage} readOnly />
        </>
      }
    </Form>
    <SumbitWrapper className="flex">
      <Link color="inherit" href="#" onClick={sendMessage}>
        <ButtonInput type="submit" value="Enviar mensagem" className="pointer animate radius8" style={{ maxWidth: "220px", backgroundColor: "#f2a301" }} />
      </Link>
    </SumbitWrapper>
  </div>
            {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









