import React from "react";
import styled from "styled-components";
import FullButton from "../Buttons/FullButton";
import HeaderImage from "../../assets/img/anh-duc-le-Ihmk9AOd4xg-unsplash.jpg";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { useNavigate } from "react-router-dom";
import Typography from '@mui/joy/Typography';
import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';
import ModalClose from '@mui/joy/ModalClose';
import VideoFrame from '../Elements/VideoFrame';
import AddImage2 from "../../assets/img/dashboard.png";
import AddImage3 from "../../assets/img/dashboard_2.png";
import AddImage4 from "../../assets/img/dashboard_1.png";
import pythia from "../../assets/img/pythia.png"


const loomBaseLink = 'https://www.loom.com/embed/'
const videoUri = '0cd7e5f71cc340d9941bbdb58e8873f9?sid=d5c5e4fa-7fd8-45c1-806f-c041d04ca9d6';

const LoomVideoLink = `${loomBaseLink}${videoUri}`

export default function Header() {
  const [highlighted, setHighlighted] = React.useState(false);
  // "Saiba mais sobre a Vinbo.AI" modal
  const [openModalOne, setOpenModalOne] = React.useState(false);
  const handleOpenModalOne = () => setOpenModalOne(true);
  const handleCloseModalOne = () => setOpenModalOne(false);
  // "escuta social" modal
  const [openModalTwo, setOpenModalTwo] = React.useState(false);
  const handleOpenModalTwo = () => setOpenModalTwo(true);
  const handleCloseModalTwo = () => setOpenModalTwo(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setHighlighted(true);
        } else {
          setHighlighted(false);
        }
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    const headerElement = document.getElementById("home");
    if (headerElement) {
      observer.observe(headerElement);
    }

    return () => {
      if (headerElement) {
        observer.unobserve(headerElement);
      }
    };
  }, []);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    document.getElementById('hero').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">
            Nós Somos a{" "}
            <HighlightedText highlighted={highlighted}>Percepção</HighlightedText>{" "}
            na Era da Inteligência Artificial!
          </h1>
          <HeaderP className="font13 semiBold">
            A Vinbol é uma revolucionária ferramenta de{" "}
            <span className="term" onClick={handleOpenModalTwo}>
              escuta social
            </span>
            , alimentada por inteligência artificial (IA) e sustentada pela
            inovadora metodologia ACPS®. Nossa missão é decifrar como diferentes
            públicos - desde o mercado consumidor até seguidores e stakeholders -
            percebem a sua marca. Auxiliamos empresas, influenciadores e agências
            de marketing a analisarem sentimentos, descobrirem insights profundos e
            aprimorarem a gestão da reputação, além de elevar o engajamento e
            resultados de suas ações. Está pronto para elevar sua estratégia
            digital a um novo patamar?
          </HeaderP>
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={openModalTwo}
            onClose={handleCloseModalTwo}
            // sx = {modalStyle}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Sheet
              variant="outlined"
              sx={{
                maxWidth: 700,
                borderRadius: 'md',
                p: 3,
                boxShadow: 'lg',
                maxHeight: 500,
                zIndex: "1000",
                overflowY: "auto"
              }}
              >
              <ModalClose variant="plain" sx={{ m: 1 }} />
              <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
              >
                O que é escuta social?
              </Typography>
              <Typography id="modal-desc" textColor="text.tertiary" mb={1}>
              A escuta social, também conhecida como social listening, refere-se ao processo de monitoramento das conversas e interações que ocorrem nas redes sociais e em outras plataformas online. O objetivo da escuta social é compreender o que está sendo dito sobre uma marca, produto, serviço ou tópico específico, bem como identificar tendências, padrões de comportamento e insights relevantes para aprimorar estratégias de marketing, gerenciamento de reputação e tomada de decisões empresariais. Em resumo, a escuta social permite às empresas entenderem melhor o seu público-alvo, adaptarem suas estratégias de comunicação e melhorarem a experiência do cliente.
              </Typography>
              </Sheet>
          </Modal>
          <BtnWrapper>
            <FullButton title="Experimente agora!" action={handleButtonClick} />
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" style={{ zIndex: 9, width: 426, height: 607 }} />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>Uma identidade e presença fortes digitalmente podem ajudar a colocar qualquer marca no topo da mente dos consumidores. Segundo <u><a className="font15 whiteColor" target="_blank" href="https://www.forbes.com/sites/forbesagencycouncil/2019/10/22/to-convert-more-customers-focus-on-brand-awareness/?sh=67c546092075">pesquisas</a></u>, essa interação influencia 70% das decisões online.</em>
              </p>
              <p className="font13 orangeColor textRight" style={{ marginTop: '10px' }}>Revecka Jallad</p>
              <p className="font13 orangeColor textRight" style={{ marginTop: '0px' }}>(Membro do Conselho da Forbes)</p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        {/* <GreyDiv className="lightBg"></GreyDiv> */}
      </RightSide>
    </Wrapper>
          {/* <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div> */}
      <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">Por que escolher a Vinbol?</h4>
                <h2 className="font40 extraBold">Inúmeras fontes de dados na internet, um único indicador</h2>
                <p className="font13 semiBold">
                  Nossa plataforma analisa inúmeras fontes de dados online, oferecendo uma visão unificada através do ACPS®. Esse indicador, processado pelo Ian - nosso sistema de IA avançado - analisa interações nas mídias sociais, proporcionando uma perspectiva clara de como sua marca, influência ou campanhas são percebidas online.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  <div style={{ width: "300px" }}>
                    <FullButton title="Saiba mais como funciona a Vinbol" action={handleOpenModalOne} />
                    <Modal
                      aria-labelledby="modal-title"
                      aria-describedby="modal-desc"
                      open={openModalOne}
                      onClose={handleCloseModalOne}
                      // sx = {modalStyle}
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Sheet
                        variant="outlined"
                        sx={{
                          maxWidth: 700,
                          borderRadius: 'md',
                          p: 3,
                          boxShadow: 'lg',
                          maxHeight: 500,
                          zIndex: "1000",
                          overflowY: "auto"
                        }}
                      >
                        <ModalClose variant="plain" sx={{ m: 1 }} />
                        <Typography
                          component="h2"
                          id="modal-title"
                          level="h4"
                          textColor="inherit"
                          fontWeight="lg"
                          mb={1}
                        >
                          ACPS®: O Coração Analítico da Vinbol
                        </Typography>
                        <Typography id="modal-desc" textColor="text.tertiary" mb={1}>
                          O ACPS® é a síntese da análise de sentimentos, traduzindo complexas interações online em uma escala compreensível de -100 a 100. Este indicador é dividido em zonas claramente definidas que refletem o estágio de maturidade da marca, tornando a percepção da marca uma discussão democrática e acessível.
                        </Typography>
                        <Typography
                          component="h3"
                          id="modal-title"
                          level="h4"
                          textColor="inherit"
                          fontWeight="lg"
                          mb={1}
                        >
                          O que é ACPS®?
                        </Typography>
                        <Typography id="modal-desc" textColor="text.tertiary" mb={1}>
                          O ACPS® é mais do que um número; é um indicador dinâmico que captura o sentimento do público em relação à sua marca, influência ou campanha. Através de uma análise minuciosa das interações nas mídias sociais e outras fontes de dados online, o Ian avalia a popularidade, engajamento e percepção geral do seu nome no mercado digital.
                        </Typography>
                        <Typography
                          component="h3"
                          id="modal-title"
                          level="h4"
                          textColor="inherit"
                          fontWeight="lg"
                          mb={1}
                        >
                          Benefícios do ACPS®
                        </Typography>
                        <Typography id="modal-desc" textColor="text.tertiary" mb={1}>
                        <ul>
                          <li><strong>Tomada de Decisão Aprimorada:</strong> Fornece aos decisores uma métrica clara e objetiva, auxiliando na interpretação de dados complexos e na formulação de estratégias eficazes.</li>
                          <li><strong>Democratização da Percepção de Marca:</strong> Facilita o entendimento coletivo sobre a posição da marca, eliminando barreiras técnicas e promovendo um ambiente de trabalho colaborativo e inclusivo.</li>
                          <li><strong>Análise Competitiva:</strong> Permite uma comparação direta com concorrentes, oferecendo uma visão estratégica sobre sua posição no mercado.</li>
                          <li><strong>Adaptação e Otimização:</strong> Com insights precisos sobre a recepção da sua marca, você pode ajustar rapidamente as estratégias de marketing, conteúdo e comunicação para melhor alinhar-se às expectativas do seu público.</li>
                        </ul>
                        </Typography>
                        <Typography
                          component="h2"
                          id="modal-title"
                          level="h4"
                          textColor="inherit"
                          fontWeight="lg"
                          mb={1}
                        >
                          Ian: A Inteligência Artificial especializada em elevar o reconhecimento da sua presença online
                        </Typography>
                        <Typography id="modal-desc" textColor="text.tertiary" mb={1}>
                        A Vinbol apresenta o Ian, um sistema de IA inovador e dedicado a uma missão crítica: mapear e compreender as conversas sobre sua marca, influência ou as campanhas gerenciadas por sua agência no vasto universo online. Com a capacidade de captar menções e interações em toda a internet e mídias sociais de maneira orgânica, Ian está no coração da nossa promessa de transformar dados em insights poderosos, ele é responsável por gerar o ACPS®.
                        </Typography>
                        <Typography
                          component="h2"
                          id="modal-title"
                          level="h4"
                          textColor="inherit"
                          fontWeight="lg"
                          mb={1}
                        >
                          Arquitetura avançada para uma análise completa:
                        </Typography>
                        <Typography id="modal-desc" textColor="text.tertiary" mb={1}>
                            Ian é construído sobre uma estrutura de quatro camadas independentes, projetadas para processar uma ampla gama de conteúdos digitais - desde vídeos e imagens até postagens, comentários e curtidas, sem esquecer do conteúdo institucional da sua marca. Esta abordagem multicamada assegura uma análise de sentimentos abrangente e precisa, permitindo entender não apenas o que é falado, mas como é percebido o seu impacto no mundo digital.
                            <ul>
                                <li><strong>Vídeos e Imagens:</strong> Análise visual avançada para captar tendências, reações e o alcance da sua marca ou campanhas.</li>
                                <li><strong>Postagens e Comentários:</strong> Escuta ativa de conversas online para entender o sentimento e a recepção do público.</li>
                                <li><strong>Curtidas e Interações:</strong> Medição do engajamento para avaliar a popularidade e a aceitação de sua presença online.</li>
                                <li><strong>Conteúdo Institucional:</strong> Avaliação do impacto do seu conteúdo oficial na construção da percepção do público.</li>
                            </ul>
                            A síntese dessas camadas ocorre em uma quinta camada de condensação, onde todos esses dados são integrados para gerar o ACPS® (Auto Classified Propellant Score). Este indicador exclusivo da Vinbol simplifica a análise, facilita a comparação com concorrentes e promove uma comunicação eficaz entre todos os setores envolvidos - sejam eles equipes internas de empresas, influenciadores buscando ampliar seu engajamento, ou agências de marketing em busca de dados concretos para estratégias assertivas.

                            Com o Ian e o ACPS®, você tem em mãos uma ferramenta poderosa para entender, adaptar e otimizar sua presença online, garantindo que sua marca, influência ou as estratégias de marketing implementadas estejam sempre alinhadas às percepções e expectativas do seu público.
                        </Typography>
                        <img src={pythia} width={640} />
                      </Sheet>
                    </Modal>
                  </div>
                  {/* <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton title="Contact Us" action={() => alert("clicked")} border />
                  </div> */}
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <VideoFrame imageUrl={AddImage2} videoUrl={"https://fast.wistia.net/embed/iframe/wivxc5exp3?seo=false&videoFoam=true"} />
                    </AddImgWrapp1>
                    {/* <AddImgWrapp2>
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp2> */}
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
        </>
  );
}

const HighlightedText = styled.span`
  position: relative;
  display: inline-block;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffcc00;
    z-index: -1;
    transform-origin: top left;
    transform: skewX(-10deg);
    animation: ${({ highlighted }) => (highlighted ? "highlight 2s forwards" : "none")};
  }

  @keyframes highlight {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`;

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  overflow-x: hidden;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }

  .term {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .term::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0.3em;
    border-bottom: 2px dotted;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  width: 100%;

  img {
    filter: brightness(120%);
    width: 100%;
    height: auto;
  }

  @media (max-width: 960px) {
    justify-content: center;
  }
`;

const Img = styled.img`
  @media (max-width: 560px) {
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 100%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 80%;
  margin: 10% 5% 20px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 10px 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;