import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const ColorInterpolationBox = () => {
  const svgRef = useRef(null);
  const width = '100%';
  const height = 40;
  const startColor = '#0cc90c';
  const endColor = '#ed0506';

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    // Create a linear gradient
    const gradient = svg.append('defs')
      .append('linearGradient')
      .attr('id', 'colorGradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '0%');

    // Add gradient stops
    gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', startColor);
    gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', endColor);

    // Draw the box with the gradient
    svg.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      .style('fill', 'url(#colorGradient)'); // Apply the gradient

    // Add text "Positivo" at the beginning of the box
    svg.append('text')
      .attr('x', '2%') // Adjust position as needed
      .attr('y', height / 2)
      .attr('fill', 'white')
      .attr('font-size', '14px')
      .attr('text-anchor', 'start')
      .text('Frequência Positiva');

    // Add text "Negativo" at the end of the box
    svg.append('text')
      .attr('x', '98%') // Adjust position as needed
      .attr('y', height / 2)
      .attr('fill', 'white')
      .attr('font-size', '14px')
      .attr('text-anchor', 'end')
      .text('Frequência Negativa');

  }, []);

  return (
    <svg ref={svgRef} width={width} height={height}>
    </svg>
  );
};

export default ColorInterpolationBox;
