import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, child, get } from "firebase/database";
import { Box, Typography, Divider, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const CampaignSwot = ({ cnpjClient, isGroup, campaignIndex }) => {
  const [swotData, setSwotData] = useState({});
  const [strengths, setStrengths] = useState([]);
  const [weaknesses, setWeaknesses] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [threats, setThreats] = useState([]);
  const [timestamps, setTimestamps] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const theme = useTheme();
  const [frequency, setFrequency] = useState('weekly');

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const dbRef = ref(getDatabase());
        let cleanCnpj = isGroup ? cnpjClient.split(',')[0].replace(/[./-]/g, '') : cnpjClient.replace(/[./-]/g, '');
        get(child(dbRef, `campaign-analysis/${cleanCnpj}/${campaignIndex}/swot-analysis`)).then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setFrequency(data.frequency || 'weekly');
            const keys = Object.keys(data.data || {});
            keys.sort((a, b) => b - a); // Sort timestamps in descending order
            setSwotData(data.data || {});
            setTimestamps(keys);
            setCurrentIndex(0);
            if (keys.length > 0) {
              updateSwotData(data.data || {}, keys[0]);
            } else {
              setStrengths([]);
              setWeaknesses([]);
              setOpportunities([]);
              setThreats([]);
            }
            setDataLoaded(true); // Set data loaded flag to true
          } else {
            console.log("No data available");
            setFrequency('weekly');
            setSwotData({});
            setStrengths([]);
            setWeaknesses([]);
            setOpportunities([]);
            setThreats([]);
            setTimestamps([]);
            setCurrentIndex(0);
            setDataLoaded(true); // Set data loaded flag to true
          }
        }).catch((error) => {
          console.error(error);
        });
      }
    });
  }, [cnpjClient, isGroup, campaignIndex]);

  const updateSwotData = (data, key) => {
    setOpportunities(data[key]?.Opportunities || []);
    setStrengths(data[key]?.Strengths || []);
    setThreats(data[key]?.Threats || []);
    setWeaknesses(data[key]?.Weaknesses || []);
  };

  const handlePrevious = () => {
    if (currentIndex < timestamps.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      updateSwotData(swotData, timestamps[newIndex]);
    }
  };

  const handleNext = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      updateSwotData(swotData, timestamps[newIndex]);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h6" gutterBottom>
            Análise SWOT Avançada da Campanha
          </Typography>
        </Grid>
        <Grid item xs={2} container direction="column" alignItems="center">
          <div>
            <IconButton onClick={handlePrevious} disabled={currentIndex >= timestamps.length - 1 || !dataLoaded} style={{ marginTop: '-8px' }}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton onClick={handleNext} disabled={currentIndex <= 0 || !dataLoaded} style={{ marginTop: '-8px' }}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </div>
          <Typography variant="body2" sx={{ marginTop: '4px'}}>
            {timestamps.length > 0 ? `${frequency === 'weekly' ? 'Semana de' : 'Dia'} ${formatDate(timestamps[currentIndex])}` : `${frequency === 'weekly' ? 'Semana de' : 'Dia'}`}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <SwotColumn title="Forças" items={strengths} />
        <SwotColumn title="Fraquezas" items={weaknesses} />
        <SwotColumn title="Oportunidades" items={opportunities} />
        <SwotColumn title="Ameaças" items={threats} />
      </Grid>
    </Box>
  );
};

const SwotColumn = ({ title, items }) => (
  <Grid item xs={12} sm={6} md={3} style={{ display: 'flex', flexDirection: 'column' }}>
    <Typography variant="subtitle1" sx={{ textDecoration: 'underline', mb: 1, textAlign: 'center' }}>
      {title}
    </Typography>
    {items?.map((item, index) => {
      const [analysis, comment] = item.split('<br/>');
      return (
        <Box key={index} sx={{ p: 1, mb: 1, border: '1px solid', borderColor: 'grey.300', borderRadius: 1, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body2">
            {analysis}
          </Typography>
          {comment && (
            <Typography variant="body2" sx={{ color: 'grey.600', fontSize: '0.8rem', mt: 1}}>
              {comment}
            </Typography>
          )}
        </Box>
      );
    })}
  </Grid>
);

export default CampaignSwot;
