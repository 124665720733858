import * as React from 'react';
import '../../style/Tags.css';

export default function TagsList({ currentTags, setCurrentTags, setFilterWords }) {
  const [inputValue, setInputValue] = React.useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setFilterWords(event.target.value.split(' ')); // Split input into words for filtering
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const newTags = inputValue.trim().split(' ');
      setCurrentTags([...currentTags, ...newTags]);
      setInputValue('');
      setFilterWords([]);
    }
  };

  const deleteTag = React.useCallback(
    (tagId) => () => {
      const tagsFiltered = currentTags.filter((tag) => tag !== tagId);
      setCurrentTags(tagsFiltered);
    },
    [currentTags]
  );

  return (
    <span className="tagInputWrapper">
      <div>
        {currentTags.map((tag) => (
          <button
            key={`close-button-${tag}`}
            className='close'
            onClick={deleteTag(tag)}
          >
            {tag} &nbsp; x
          </button>
        ))}
      </div>
      <input 
        name="tagInput" 
        className="tagInput" 
        placeholder="Filtre as menções digitando aqui ou clicando nas tags..." 
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
      />
    </span>
  );
}
