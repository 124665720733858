import * as React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Dot } from 'recharts';
import Title from './Title';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Components from './AcpsComponents';
import { Modal, Box, Typography, ListItem, ListItemText, List, ListItemIcon, Divider, Paper } from '@mui/material';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';

const modalStyle = {
  position: 'absolute',
  top: '42%',
  left: '65%',
  transform: 'translate(-50%, -50%)',
  width: 720,
  maxHeight: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  zIndex: 1000,
  overflowY: 'auto',
};

// Helper function to format numbers with leading zeros
function formatNumberWithLeadingZero(number) {
  return number < 10 ? `0${number}` : number.toString();
}

// Helper function to get the name of the month
function getMonthName(activePeriodOfMonthChart) {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + activePeriodOfMonthChart);
  return currentDate.toLocaleString('default', { month: 'long' });
}

export default function Chart({
  userTier,
  chartData,
  clientCnpj,
  competitor1cnpj,
  competitor2cnpj,
  competitor3cnpj,
  clientGoodCommentsData,
  clientBadCommentsData,
  competitorOneGoodCommentsData,
  competitorOneBadCommentsData,
  competitorTwoGoodCommentsData,
  competitorTwoBadCommentsData,
  competitorThreeGoodCommentsData,
  competitorThreeBadCommentsData,
  FilterConcorrente1,
  FilterConcorrente2,
  FilterConcorrente3,
  FilterSegmento,
  activePeriodOfMonthChart,
  onPrevPeriodOfMonthChart,
  onNextPeriodOfMonthChart,
  nomeEmpresa,
  nomeEmpresaCompetidor1,
  nomeEmpresaCompetidor2,
  nomeEmpresaCompetidor3,
  postsLikes,
  filterPostUrl,
  cnpjClient,
  trialExpired,
  darkMode,
  isTourActive,
  joyrideRef,
  dataRobustnessIndex
}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [dataPoint, setDataPoint] = React.useState({});
  const [dataPointDayBefore, setDatapointDayBefore] = React.useState({});
  const [target, setTarget] = React.useState(0);
  const [combinedChartData, setCombinedChartData] = React.useState([]);

  const [openHelp, setOpenHelp] = React.useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  const handleDotClick = (dot, index) => {
    if (!trialExpired) {
      let currentdate = new Date();
      currentdate.setMonth(currentdate.getMonth() + activePeriodOfMonthChart);
      function getStringDate(ix) {
        return currentdate
          .getFullYear()
          .toString() + "-" + formatNumberWithLeadingZero(currentdate.getMonth() + 1)
          .toString() + "-" + formatNumberWithLeadingZero(
            ix < 0 ? index['index'] : index['index'] + 1)
          .toString();
      }
      setDataPoint({ date: getStringDate(0), acpsVal: chartData[index['index']][index['dataKey']] });
      setDatapointDayBefore({ date: getStringDate(-1), acpsVal: chartData[index['index']][index['dataKey']] });

      if (index['dataKey'] === 'ACPS_CLIENT') {
        setTarget(0);
      } else if (index['dataKey'] === 'ACPS_COMP1') {
        setTarget(1);
      } else if (index['dataKey'] === 'ACPS_COMP2') {
        setTarget(2);
      } else if (index['dataKey'] === 'ACPS_COMP3') {
        setTarget(3);
      }

      setOpen(true);

      // Trigger next Joyride step if applicable
      if (isTourActive && joyrideRef.current && typeof joyrideRef.current.next === 'function') {
        setTimeout(() => {
          joyrideRef.current.next(); // Move to the next step in the tour after opening the modal
        }, 500); // Small delay to ensure modal is open before moving to the next step
      }
    }
  };

  // Combine o chartData existente com o dataRobustnessIndex
  React.useEffect(() => {
    if (chartData && dataRobustnessIndex.length) {
      // Mapear os dados do ACPS e dataRobustnessIndex por dia, garantindo alinhamento
      const combinedData = chartData.map((data) => {
        const robustnessData = dataRobustnessIndex.find(
          (robustness) => robustness.day === data.day
        );
        return {
          ...data,
          dataRobustnessIndex: robustnessData ? robustnessData.dataRobustnessIndex : 0, // Garantir 0 se o dado não estiver presente
        };
      });

      setCombinedChartData(combinedData);
    }
  }, [chartData, dataRobustnessIndex]);

  // Função para formatar valores numéricos com vírgula como separador decimal
  const formatNumber = (value) => {
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  // Define o Custom Tooltip com formatação especificada
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload.length) {
      const acpsValue = payload.find(p => p.dataKey === 'ACPS_CLIENT')?.value ?? 'N/A';
      const irdValue = payload.find(p => p.dataKey === 'dataRobustnessIndex')?.value ?? 'N/A';
      const day = `Dia ${label}`; // Formatação do dia

      return (
        <Paper sx={{ padding: 1, backgroundColor: darkMode ? '#1e1e1e' : '#ffffff' }}>
          <Typography variant="subtitle2" style={{ color: darkMode ? '#cccccc' : '#000000' }}>{day}</Typography>
          <Typography variant="body2" style={{ color: darkMode ? '#cccccc' : '#000000' }}>{`ACPS: ${formatNumber(acpsValue)}`}</Typography>
          <Typography variant="body2" style={{ color: darkMode ? '#cccccc' : '#000000' }}>{`Índice de Robustez de Dados: ${formatNumber(irdValue)}`}</Typography>
        </Paper>
      );
    }
    return null;
  };

  const renderCustomDot = (props) => {
    const { cx, cy, dataKey, index } = props;

    return (
      <Dot
        cx={cx}
        cy={cy}
        r={isTourActive ? 8 : 4} // Smaller dot size outside of Joyride tour
        fill={dataKey === 'ACPS_CLIENT' ? (darkMode ? "#00a651" : "#8884d8") : (darkMode ? "#ff00ff" : "#82ca9d")}
        stroke={darkMode ? "#ffffff" : "#000000"}
        strokeWidth={2}
        className="chart-point"
        onClick={(e) => handleDotClick(props, { index, dataKey })}
        style={{
          cursor: 'pointer',
          visibility: isTourActive ? 'visible' : 'visible', // Keep visible during tour and regular usage
        }}
      />
    );
  };

  const triggerDotClick = () => {
    const lastPoint = document.querySelector('.chart-point');
    if (lastPoint) {
      lastPoint.dispatchEvent(new MouseEvent('click', { bubbles: true }));
    }
  };

  React.useEffect(() => {
    if (isTourActive) {
      triggerDotClick();
    }
  }, [isTourActive]);

  return (
    <React.Fragment>
      <Grid container alignItems="center">
        <Grid item xs={8.5}>
          <Title>ACPS distribuido diariamente em <Typography component="h2" sx={{ textDecoration: 'underline' }} display="inline" variant="h6" color="primary" gutterBottom>{getMonthName(activePeriodOfMonthChart)}</Typography></Title>
        </Grid>
        <Grid item xs={1.5}>
          <IconButton onClick={handleOpenHelp} style={{ marginRight: '1px', marginTop: '-8px' }}>
            <HelpCenterOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={onPrevPeriodOfMonthChart} style={{ marginTop: '-8px' }}>
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={onNextPeriodOfMonthChart} style={{ marginTop: '-8px' }}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Modal
        open={openHelp}
        onClose={handleCloseHelp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h5" id="modal-modal-title" align="center" style={{ margin: 5 }}>
            ACPS Mensal
          </Typography>
          <Typography variant="body1" id="modal-modal-description">
            Esse gráfico mostra o valor do ACPS da marca ao longo do mês. Escolha um ponto no gráfico, clique sobre ele e veja detalhes do ACPS naquele dia.
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <LabelOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Visão Geral: Você tem uma visão geral da situação através de quatro indicadores: Total de menções, Total de autores, ACPS e Engajamento bruto." />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemIcon>
                <LabelOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Menções: Analise detalhadamente o que falam da sua marca, quem fala e onde fala, através de filtros para uma análise mais eficaz." />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemIcon>
                <LabelOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Nuvem de palavras: Não sabe por onde começar sua análise? Comece pela nuvem de palavras, entenda se as palavas que mais aparecem nas menções são positivas ou negativas." />
            </ListItem>
            <Divider variant="inset" component="li" />
          </List>
        </Box>
      </Modal>
      <ResponsiveContainer>
      <AreaChart
        data={combinedChartData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="0" stroke={darkMode ? "#ffffff" : "#e0e0e0"} horizontal={true} vertical={false} />
        
        <XAxis dataKey="day" stroke={darkMode ? "#ffffff" : "#616161"} />

        {/* Primary Y-Axis for ACPS, on the left side */}
        <YAxis 
          yAxisId="left"
          stroke={darkMode ? "#ffffff" : "#616161"} 
          domain={[-100, 100]} 
          axisLine={false} 
          tickLine={false} 
        />

        {/* Secondary Y-Axis for Data Robustness Index, on the right side */}
        <YAxis 
          yAxisId="right"
          orientation="right"
          stroke={darkMode ? "#ffffff" : "#616161"} 
          domain={[0, 1]} 
          axisLine={false} 
          tickLine={false}
        />

        {/* Use the custom tooltip */}
        <Tooltip content={<CustomTooltip />} />

        {/* Area for Data Robustness Index on the secondary axis */}
        <Area 
          yAxisId="right"
          type="monotone" 
          dataKey="dataRobustnessIndex" 
          stroke="#A0A4A8"
          fillOpacity={0.3} 
          fill="#A0A4A8" 
          strokeWidth={1.5} 
          dot={false}
        />

        {/* Primary Area for ACPS on the primary axis */}
        <Area
          yAxisId="left"
          connectNulls
          activeDot={{
            r: isTourActive ? 8 : 4, // Ensure proper handling of dot clicks
            stroke: '#616161', 
            onClick: (dot, index, e) => handleDotClick(dot, index, e),
          }}
          dot={isTourActive ? renderCustomDot : null} 
          type="monotone"
          dataKey="ACPS_CLIENT"
          stroke={darkMode ? "#00a651" : "#8884d8"}
          fill={darkMode ? "#00a651" : "#8884d8"}
          strokeWidth={3}
        />
      </AreaChart>
      <Components
          userTier={userTier}
          openModal={open}
          handleCloseModal={handleClose}
          target={target}
          targetName={
            target === 0 ? nomeEmpresa :
              target === 1 ? nomeEmpresaCompetidor1 :
                target === 2 ? nomeEmpresaCompetidor2 :
                  target === 3 ? nomeEmpresaCompetidor3 :
                    null
          }
          targetCnpj={
            target === 0 ? clientCnpj :
              target === 1 ? competitor1cnpj :
                target === 2 ? competitor2cnpj :
                  target === 3 ? competitor3cnpj :
                    null
          }
          dataPoint={dataPoint}
          dataPointDayBefore={dataPointDayBefore}
          clientGoodCommentsData={clientGoodCommentsData}
          clientBadCommentsData={clientBadCommentsData}
          competitorOneGoodCommentsData={competitorOneGoodCommentsData}
          competitorOneBadCommentsData={competitorOneBadCommentsData}
          competitorTwoGoodCommentsData={competitorTwoGoodCommentsData}
          competitorTwoBadCommentsData={competitorTwoBadCommentsData}
          competitorThreeGoodCommentsData={competitorThreeGoodCommentsData}
          competitorThreeBadCommentsData={competitorThreeBadCommentsData}
          postsLikes={postsLikes}
          filterPostUrl={filterPostUrl}
          cnpjClient={cnpjClient}
          darkMode={darkMode}
        />
    </ResponsiveContainer>
    </React.Fragment>
  );
}
