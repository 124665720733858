import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="hero" smooth={true} offset={-80}>
              <LogoImg />
            </Link>
            <TextContainer>
              <StyleP className="whiteColor font13">
                ® {getCurrentYear()} - <span className="purpleColor font13">Vinbol</span> Todos os direitos reservados.
              </StyleP>
              <FederalInscription className="whiteColor font13">
                Ararajuba Tecnologia Autonoma Ltda. - CNPJ n° 55.013.515/0001-18
              </FederalInscription>
            </TextContainer>
            <Link className="whiteColor animate pointer font13" to="hero" smooth={true} offset={-80}>
              Voltar ao topo
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const TextContainer = styled.div`
  text-align: center;
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const StyleP = styled.p`
  margin: 0;
`;

const FederalInscription = styled.p`
  margin: 0;
`;

