import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import logo from '../../../assets/img/logo_only_circle.png';
import ProfileMenu from '../../Nav/ProfileMenu';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      zIndex: 1500,
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& textarea': {
      scrollbarWidth: 'none', // Hide scrollbar for Firefox
      '&::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar for WebKit browsers
      },
    },
  },
}));

const TypingEffect = ({ text, speed, onTypingComplete, chatBoxRef, resetTrigger }) => {
  const [displayedText, setDisplayedText] = useState('');
  const typingStartedRef = useRef(false);

  useEffect(() => {
    typingStartedRef.current = false; // Reset typing state when resetTrigger changes
  }, [resetTrigger]);

  useEffect(() => {
    if (typingStartedRef.current) return;

    typingStartedRef.current = true;
    setDisplayedText(''); // Reset displayed text
    let index = 0;
    const interval = setInterval(() => {
      setDisplayedText((prev) => prev + text[index]);
      index++;
      if (chatBoxRef.current) {
        chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
      }
      if (index === text.length) {
        clearInterval(interval);
        typingStartedRef.current = false;
        if (onTypingComplete) {
          onTypingComplete();
        }
      }
    }, speed);
    return () => {
      clearInterval(interval);
      typingStartedRef.current = false;
    };
  }, [text, speed, onTypingComplete, chatBoxRef, resetTrigger]);

  return <span>{displayedText}</span>;
};

export default function RightSection({ 
  open, 
  toggleDrawer, 
  cnpj,
  nomeEmpresa,
  setNomeEmpresa,
  groupCompanies,
  isGroup,
  signOutUser,
  currCnpj,
  setCurrCnpj,
  displayNomeEmpresa,
  setDisplayNomeEmpresa,
  setIsGroup,
  activeTutorial,
  setActiveTutorial,
  updateOnCalculator,
  toggleDarkMode,
  darkMode,
  defaultBrand,
  setDefaultBrand
}) {
  const theme = useTheme();
  const [chatOpen, setChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const chatBoxRef = useRef(null); 
  const [loading, setLoading] = useState(false);
  const [resetTrigger, setResetTrigger] = useState(0);

  useEffect(() => {
    if (chatOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [chatOpen]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, loading]);

  const handleChatOpen = () => {
    setChatOpen(true);
  };

  const handleChatClose = () => {
    setChatOpen(false);
    setMessages([]); // Clear chat messages when closing
  };

  // Função para buscar resposta da API do Azure OpenAI com filtro de CNPJ
  const fetchOpenAIResponse = async (userMessage) => {
    const apiBase = "https://vb-action-assistant-1.openai.azure.com/";
    const apiKey = "757c8724ef324fb88b28671a5aab7aab"; // Chave API do Azure OpenAI
    const deploymentId = "gpt-4o";
    const searchEndpoint = "https://search-action-assistant.search.windows.net";
    const searchKey = "KWh2DfdchhF43NWoXLEiT9i6mX4BXk4trwVAQQvTLuAzSeCouXiP"; // chave do Azure Search
    const searchIndex = "vw-fact-comments-final-cnpj-idx";

    try {
      const response = await fetch(
        `${apiBase}openai/deployments/${deploymentId}/chat/completions?api-version=2024-02-15-preview`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey, // Chave da API do Azure OpenAI
          },
          body: JSON.stringify({
            messages: [
              {
                role: 'system',
                content:
                  'Seu nome é Ian e você é o assistente de IA da Vinbol especializado em analisar sentimentos e fornecer insights sobre comentários. Use o índice de comentários para responder com base nas informações disponíveis. Se uma consulta perguntar sobre contagem de comentários, sempre consulte o valor total no índice e informe o número exato encontrado.',
              },
              { role: 'user', content: userMessage },
            ],
            past_messages: 10,
            temperature: 0.7,
            top_p: 0.9,
            frequency_penalty: 0.2,
            presence_penalty: 0.3,
            max_tokens: 800,
            stop: null,
            azureSearchEndpoint: searchEndpoint,
            azureSearchKey: searchKey,
            azureSearchIndexName: searchIndex,
            data_sources: [
              {
                type: 'azure_search',
                parameters: {
                  endpoint: searchEndpoint,
                  index_name: searchIndex,
                  semantic_configuration: 'default',
                  query_type: 'semantic',
                  fields_mapping: {},
                  in_scope: false,
                  role_information:
                    'Você é um assistente de IA especializado em analisar sentimentos e fornecer insights sobre comentários. Use o índice de comentários para responder com base nas informações disponíveis. Se uma consulta perguntar sobre contagem de comentários, sempre consulte o valor total no índice e informe o número exato encontrado.',
                  filter: `cnpj eq '${cnpj}'`, // Filtro por CNPJ
                  strictness: 1,
                  top_n_documents: 200,
                  authentication: {
                    type: 'api_key',
                    key: searchKey, // Chave do Azure Search
                  },
                },
              },
            ],
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        return data.choices[0].message.content.trim(); // Ajuste conforme a estrutura da resposta da API
      } else {
        console.error('Erro ao buscar resposta da OpenAI:', response.statusText);
        return 'Erro ao buscar resposta da IA.';
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
      return 'Erro ao se comunicar com a IA.';
    }
  };

  const handleSendMessage = async () => {
    if (inputValue.trim()) {
      const newMessages = [...messages, { text: inputValue, sender: 'user' }];
      setMessages(newMessages);
      setInputValue('');
      setLoading(true);
  
      // Adicionar mensagem de loading
      const loadingMessageId = new Date().getTime(); // Unique ID for the loading message
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: loadingMessageId, text: '', sender: 'system', loading: true },
      ]);
  
      // Fazer a chamada à OpenAI API para obter a resposta
      const systemResponse = await fetchOpenAIResponse(inputValue);
  
      // Remover mensagem de loading e adicionar a resposta da IA
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const loadingMessageIndex = updatedMessages.findIndex(msg => msg.id === loadingMessageId);
        if (loadingMessageIndex !== -1) {
          updatedMessages[loadingMessageIndex] = { id: loadingMessageId, text: systemResponse, sender: 'system', loading: false };
        }
        return updatedMessages;
      });
  
      // Update resetTrigger to reset TypingEffect only for new system messages
      setResetTrigger(loadingMessageId);
  
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const LoadingDots = () => {
    const [dots, setDots] = useState('');
  
    useEffect(() => {
      const interval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
      }, 500);
      return () => clearInterval(interval);
    }, []);
  
    return <span>{dots}</span>;
  };

  const handleFacebookCallback = (response) => {
    if (response?.status === "unknown") {
        console.error('Sorry!', 'Something went wrong with facebook Login.');
     return;
    }
    console.log(response);
  }

  return (
    <Drawer variant="permanent" anchor="right" open={open}>
      <div>
        <IconButton onClick={toggleDrawer}>
          <ChevronRightIcon />
        </IconButton>
      </div>
      <Divider />
      
      {/* Área do Chat */}
      {chatOpen ? (
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          overflow: 'hidden'
        }}>
          <Button onClick={handleChatClose} sx={{ margin: '8px' }}>
            Voltar aos meus chats
          </Button>
          <Divider sx={{ marginY: '8px' }} />
          <Box
            ref={chatBoxRef}
            sx={{
              flex: 1,
              overflowY: 'auto',
              border: '1px solid #444',
              padding: '8px',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme.palette.mode === 'dark' ? '#222' : '#f9f9f9',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: theme.palette.mode === 'dark' ? '#222' : '#fff',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.mode === 'dark' ? '#555' : '#ccc',
                borderRadius: '4px',
              },
            }}
          >
            {messages.length === 0 && !loading && ( // Add this block
            <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'rgba(152, 156, 153, 0.8)',
                textAlign: 'center',
                fontSize: '0.9rem',
                width: '80%', // Ensure it respects the dimensions of the interaction box
                whiteSpace: 'pre-wrap', // Ensure text wraps correctly
            }}
            >
            Utilize o Assistente de Ação para obter insights sobre seus dados. <br />
            Faça perguntas sobre conteúdo de comentários, sentimentos, usuários, redes sociais, etc. <br /><br /> Seja criativo!😉
            </Box>
            )}
            {messages.map((msg, index) => (
            <Box
                key={index}
                sx={{
                alignSelf: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                display: 'flex',
                flexDirection: msg.sender === 'user' ? 'row-reverse' : 'row', // Adjust direction for user/system
                alignItems: 'flex-start', // Align items at the top
                margin: '4px 0',
                maxWidth: '95%', // Increase maxWidth
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                position: 'relative',
                scrollbarWidth: 'thin', // For Firefox
                '&::-webkit-scrollbar': {
                width: '8px', // For WebKit browsers
                },
                '&::-webkit-scrollbar-track': {
                background: theme.palette.mode === 'dark' ? '#222' : '#fff',
                },
                '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.mode === 'dark' ? '#555' : '#ccc',
                borderRadius: '4px',
                },
                }}
            >
                <Box
                  sx={{
                    backgroundColor: msg.sender === 'user' ? '#1e88e5' : '#424242',
                    color: '#fff',
                    borderRadius: '8px',
                    padding: '8px',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    flex: 1,
                  }}
                >
                  {msg.loading ? (
                    <LoadingDots />
                  ) : msg.sender === 'system' ? (
                    <TypingEffect
                    text={msg.text}
                    speed={50}
                    chatBoxRef={chatBoxRef}
                    onTypingComplete={() => {
                        if (chatBoxRef.current) {
                        chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
                        }
                    }}
                    resetTrigger={resetTrigger} // Pass resetTrigger prop
                    />
                  ) : (
                    msg.text
                  )}
                </Box>
                {msg.sender === 'system' && !msg.loading && (
                  <img
                    src={logo}
                    alt="System Logo"
                    style={{
                      width: '28px',
                      height: '28px',
                      borderRadius: '50%',
                      marginLeft: '12px',
                      alignSelf: 'flex-start',
                    }}
                  />
                )}
              </Box>
            ))}
          </Box>
          
          {/* Input do chat */}
          <Box sx={{ 
            padding: '16px',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}>
            <TextFieldStyled
              fullWidth
              variant="outlined"
              size="medium"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Faça sua pergunta aqui"
              multiline
              maxRows={2}
              inputRef={inputRef}
              autoFocus
              sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff',
                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              }}
              InputProps={{
                style: { color: theme.palette.mode === 'dark' ? '#fff' : '#000' },
              }}
            />
            <Button
              onClick={handleSendMessage}
              sx={{ marginLeft: '8px', backgroundColor: '#1e88e5', color: '#fff' }}
            >
              Enviar
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          {/* Lista de opções quando não está no chat */}
          <List sx={{ 
            padding: !open ? '6px' : '16px', 
            marginTop: '30px',
            flex: 1,
            overflowY: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}>
            {open && (
              <>
                <Button onClick={toggleDrawer} sx={{ margin: '8px' }}>
                  Voltar ao menu
                </Button>
                <Divider sx={{ marginY: '8px' }} />
              </>
            )}
            <ListItemButton
              sx={{ marginTop: '8px', marginBottom: '8px' }}
              onClick={open ? handleChatOpen : null}
              disabled={!open}
            >
              <ListItemIcon>
                <QuestionAnswerIcon />
              </ListItemIcon>
              <ListItemIcon />
              <ListItemText
                primary="Chat"
                primaryTypographyProps={{ variant: 'subtitle1' }}
                secondaryTypographyProps={{
                  variant: 'body2',
                  sx: { whiteSpace: 'pre-line', fontSize: '0.8rem' },
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  '& > *:not(:first-child):not(:last-child)': {
                    marginTop: '4px',
                  },
                }}
              />
            </ListItemButton>
          </List>

          {/* Footer com ícones (visível apenas quando não está no chat) */}
          <Box sx={{ 
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff',
          }}>

            {/* Profile Menu */}
            <ListItemButton sx={{ padding: '8px' }}>
              <ProfileMenu
                clientName={nomeEmpresa || ''}
                setNomeEmpresa={setNomeEmpresa}
                groupNamesList={groupCompanies || []}
                isGroup={isGroup || false}
                onLogout={signOutUser}
                currCnpj={currCnpj}
                setCurrCnpj={setCurrCnpj}
                cnpj={cnpj}
                displayNomeEmpresa={displayNomeEmpresa || ''}
                setDisplayNomeEmpresa={setDisplayNomeEmpresa}
                setIsGroup={setIsGroup}
                activeTutorial={activeTutorial || false}
                setActiveTutorial={setActiveTutorial}
                updateOnCalculator={updateOnCalculator}
                showText={open}
                defaultBrand={defaultBrand}
                setDefaultBrand={setDefaultBrand}
              />
            </ListItemButton>

            {/* Dark Mode Toggle */}
            <ListItemButton
              onClick={toggleDarkMode}
              sx={{ padding: '8px' }}
            >
              <ListItemIcon>
                {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
              </ListItemIcon>
              {open && <ListItemText primary={darkMode ? "Modo Claro" : "Modo Escuro"} />}
            </ListItemButton>

            {/* Help Button */}
            <ListItemButton
              onClick={() => {/* TODO: Implementar modal de FAQ */}}
              sx={{ padding: '8px' }}
            >
              <ListItemIcon>
                <HelpIcon sx={{ fontSize: 24 }} />
              </ListItemIcon>
              {open && <ListItemText primary="Ajuda e Suporte" />}
            </ListItemButton>
          </Box>
        </>
      )}
    </Drawer>
  );
}
