import React from "react";
import styled from "styled-components";

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <Container>
        <Title>Política de Privacidade da Vinbol.AI</Title>
        <Section>
          <Subtitle>Introdução</Subtitle>
          <Text>
            A Vinbol.AI está comprometida com a proteção da privacidade e dos dados pessoais dos nossos usuários. Esta política de privacidade descreve como coletamos, usamos, armazenamos e protegemos suas informações pessoais quando você utiliza nossos serviços. Ao acessar ou usar os serviços da Vinbol.AI, você concorda com os termos desta política de privacidade.
          </Text>
        </Section>
        <Section>
          <Subtitle>Coleta de Informações</Subtitle>
          <Text>
            Coletamos diversos tipos de informações para fornecer e melhorar nossos serviços, incluindo:
          </Text>
          <List>
            <ListItem>
              <b>Informações Pessoais:</b> Nome, endereço de e-mail, número de telefone e outras informações fornecidas voluntariamente por você ao criar uma conta ou entrar em contato conosco.
            </ListItem>
            <ListItem>
              <b>Informações de Uso:</b> Dados sobre como você interage com nossos serviços, incluindo endereço IP, tipo de navegador, páginas acessadas, tempo gasto em cada página e outras informações de navegação.
            </ListItem>
            <ListItem>
              <b>Informações de Dispositivo:</b> Dados sobre o dispositivo utilizado para acessar nossos serviços, incluindo tipo de dispositivo, sistema operacional e identificadores de dispositivo.
            </ListItem>
          </List>
        </Section>
        <Section>
          <Subtitle>Uso das Informações</Subtitle>
          <Text>Utilizamos as informações coletadas para:</Text>
          <List>
            <ListItem><b>Fornecimento de Serviços:</b> Garantir a funcionalidade adequada dos nossos serviços e fornecer suporte ao cliente.</ListItem>
            <ListItem><b>Melhoria de Serviços:</b> Analisar o uso dos nossos serviços para melhorar a experiência do usuário e desenvolver novos recursos.</ListItem>
            <ListItem><b>Comunicação:</b> Enviar atualizações, notificações, e-mails promocionais e outras informações relacionadas aos nossos serviços.</ListItem>
            <ListItem><b>Segurança:</b> Proteger nossos serviços e usuários contra fraudes, abusos e outros atos maliciosos.</ListItem>
          </List>
        </Section>
        <Section>
          <Subtitle>Compartilhamento de Informações</Subtitle>
          <Text>
            Não vendemos, alugamos ou compartilhamos suas informações pessoais com terceiros, exceto:
          </Text>
          <List>
            <ListItem>
              <b>Com Provedores de Serviços:</b> Compartilhamos informações com fornecedores terceirizados que nos ajudam a operar nossos serviços, desde que esses provedores concordem em proteger a privacidade e a segurança das informações.
            </ListItem>
            <ListItem>
              <b>Conforme Exigido por Lei:</b> Divulgamos informações pessoais quando exigido por lei ou em resposta a processos legais.
            </ListItem>
            <ListItem>
              <b>Para Proteger Direitos e Segurança:</b> Compartilhamos informações para proteger os direitos, propriedade ou segurança da Vinbol.AI, nossos usuários ou terceiros.
            </ListItem>
          </List>
        </Section>
        <Section>
          <Subtitle>Armazenamento e Segurança das Informações</Subtitle>
          <Text>
            Adotamos medidas de segurança técnicas e organizacionais para proteger suas informações pessoais contra acesso não autorizado, perda, destruição ou alteração. No entanto, nenhum método de transmissão pela Internet ou armazenamento eletrônico é 100% seguro. Portanto, não podemos garantir segurança absoluta.
          </Text>
        </Section>
        <Section>
          <Subtitle>Seus Direitos</Subtitle>
          <Text>Você tem o direito de:</Text>
          <List>
            <ListItem><b>Acessar e Atualizar:</b> Acessar e atualizar suas informações pessoais a qualquer momento, por meio das configurações da sua conta ou entrando em contato conosco.</ListItem>
            <ListItem><b>Excluir:</b> Solicitar a exclusão de suas informações pessoais, salvo quando precisarmos manter esses dados para cumprir obrigações legais ou contratuais.</ListItem>
            <ListItem><b>Restrição de Processamento:</b> Solicitar a restrição do processamento de suas informações pessoais em certas circunstâncias.</ListItem>
          </List>
        </Section>
        <Section>
          <Subtitle>Alterações nesta Política de Privacidade</Subtitle>
          <Text>
            Podemos atualizar esta política de privacidade periodicamente para refletir mudanças em nossas práticas ou em leis aplicáveis. Notificaremos sobre quaisquer alterações substanciais através do nosso site ou outros meios apropriados.
          </Text>
        </Section>
        <Section>
          <Subtitle>Contato</Subtitle>
          <Text>
            Se você tiver dúvidas ou preocupações sobre esta política de privacidade ou sobre como lidamos com suas informações pessoais, entre em contato conosco através do e-mail: suporte@vinbol.ai.
          </Text>
        </Section>
        <Footer>
          Esta política de privacidade foi atualizada pela última vez em 1 de julho de 2024.
        </Footer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  background: #f5f5f5;
  color: #333;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 14px;
  color: #666;
  line-height: 1.6;
`;

const List = styled.ul`
  list-style: disc;
  padding-left: 20px;
`;

const ListItem = styled.li`
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
`;

const Footer = styled.p`
  font-size: 12px;
  color: #999;
  text-align: center;
  margin-top: 20px;
`;

export default PrivacyPolicy;
