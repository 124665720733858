import styled from 'styled-components';
import GoogleBusiness from "../../assets/svg/Sources/GoogleBusiness";
import Instagram from "../../assets/svg/Sources/Instagram";
import InstagramPng from '../../assets/img/instagram-png-logo.png';
import DefaultAvatar from '../../assets/img/default_avatar.jpg';
import ReclameAqui from "../../assets/svg/Sources/ReclameAqui";
import NewMentionTag from '../Elements/NewMentionTag';
import Tags from './AcpsTags';

const Container = styled.div`
  width: 100%;
  border: 2px solid ${props => getBorderColor(props.classification)};
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.strong`
  font-size: 16px;
`;

const Date = styled.span`
  font-size: 14px;
  color: #666;
`;

const Message = styled.p`
  margin: 10px 0;
`;

const Source = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666;
`;

const SourceLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const NewMentionWrapper = styled.div`
  margin-left: auto;
`;

const getBorderColor = (classification) => {
  if (classification === 'pos') return 'green';
  if (classification === 'neg') return 'red';
  return 'darkgrey';
};

const TweetBox = ({ title, picture, date, message, origin, newMention, tags, currentTags, setCurrentTags, classification }) => {
  // Treat the binary array, when necessary, to render in the <img> tag.
  if (picture && picture.startsWith('b\'')) {
    picture = picture.split('b\'')[1];
    picture = picture.slice(0, -1);
  }

  return (
    <Container classification={classification}>
      <Header>
        <HeaderLeft>
          <Avatar src={origin === 2 ? `data:image/png;base64,${picture}` : DefaultAvatar} alt="User Avatar" />
          <HeaderText>
            <Title>{title}</Title>
            <Date>{date}</Date>
          </HeaderText>
        </HeaderLeft>
        {newMention && <NewMentionWrapper><NewMentionTag /></NewMentionWrapper>}
      </Header>
      <Message>{message}</Message>
      <Source>
        {origin === 1 ? <GoogleBusiness style={{ marginRight: '5px' }} width={20} height={20} /> : origin === 3 ? <ReclameAqui style={{ marginRight: '5px' }} width={20} height={20} /> : origin === 2 ? <SourceLogo src={InstagramPng} /> : null}
        <span>{
          origin === 1 ? "Google Meu Negócio" : origin === 3 ? "Reclame Aqui" : origin === 2 ? "Instagram" : null
        }</span>
      </Source>
      <TagsWrapper>
        <Tags tags={tags} currentTags={currentTags} setCurrentTags={setCurrentTags} />
      </TagsWrapper>
    </Container>
  );
};

export default TweetBox;
