import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import PricingCalculator from '../components/Elements/PricingCalculator';
import buildingImage from '../assets/img/calculator/building.png'; // Adjust the import path accordingly

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
`;

const Calculator = () => {
  const [instagramUser, setInstagramUser] = useState('');
  const [googleBusinessUser, setGoogleBusinessUser] = useState('');
  const [reclameAquiUser, setReclameAquiUser] = useState('');
  const [monthlyRevenue, setMonthlyRevenue] = useState('');
  const [result, setResult] = useState(null);

  const handleCalculate = async () => {
    try {
      const response = await axios.post('https://api.example.com/calculate-price', {
        instagramUser,
        googleBusinessUser,
        reclameAquiUser,
        monthlyRevenue,
      });
      setResult(response.data.price);
    } catch (error) {
      console.error('Error calculating price', error);
    }
  };

  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  return (
    <Container>
      <PricingCalculator
        instagramUser={instagramUser}
        setInstagramUser={setInstagramUser}
        googleBusinessUser={googleBusinessUser}
        setGoogleBusinessUser={setGoogleBusinessUser}
        reclameAquiUser={reclameAquiUser}
        setReclameAquiUser={setReclameAquiUser}
        monthlyRevenue={monthlyRevenue}
        setMonthlyRevenue={setMonthlyRevenue}
        handleCalculate={handleCalculate}
        result={result}
        formatCurrency={formatCurrency}
        buildingImage={buildingImage}
      />
    </Container>
  );
};

export default Calculator;