import * as React from "react";

function SwotTemplate(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 750 500"
      {...props}
    >
      <rect fill="#68d1bf" x="0" y="0" width="375" height="250" />
      <text x="20" y="30" fill="#000" fontSize="24" fontWeight="bold">Pontos fortes</text>
      
      <rect fill="#f2c14b" x="375" y="0" width="375" height="250" />
      <text x="395" y="30" fill="#000" fontSize="24" fontWeight="bold">Pontos fracos</text>

      <rect fill="#ffeb3b" x="0" y="250" width="375" height="250" />
      <text x="20" y="280" fill="#000" fontSize="24" fontWeight="bold">Oportunidades</text>
      
      <rect fill="#b686f5" x="375" y="250" width="375" height="250" />
      <text x="395" y="280" fill="#000" fontSize="24" fontWeight="bold">Ameaças</text>
    </svg>
  );
}

export default SwotTemplate;
