import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 48 48" {...props}>
      <g data-name="Group 77" fill="#F44336">
        <path data-name="Rectangle 40" d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)" />
        <path data-name="Rectangle 41" d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)" />
      </g>
    </svg>
  );
}

export default SvgComponent;
