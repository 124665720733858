import React from "react";
import { useLocation } from 'react-router-dom';
import Checkout from "../components/Sections/checkout/Checkout";

export default function Setup() {
  const location = useLocation();
  const { instagramUser, googleBusinessUser, reclameAquiUser } = location.state || {};

    return (
      <>
        <Checkout 
        _instagramUser={instagramUser} 
        _googleBusinessUser={googleBusinessUser} 
        _reclameAquiUser={reclameAquiUser} 
      />
      </>
    );
  }