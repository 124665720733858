import React from 'react';
import { Typography, Box, Tooltip, IconButton, CircularProgress, useTheme } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const MetricCard = ({ title, value, referenceValue, tooltipMessage, loading }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // Format the value with dots to separate thousands and handle decimal places
  const formattedValue = value ? 
    (title === "Taxa de engajamento" || title === "ACPS") 
      ? value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : value.toLocaleString() 
    : '0';

  // Calculate the KPI and determine the color
  const kpi = referenceValue ? ((value - referenceValue) / Math.abs(referenceValue)) * 100 : 0;
  const isPositive = kpi > 0;
  const isNegative = kpi < 0;

  // Define styles based on KPI direction
  const kpiStyle = {
    color: isPositive ? 'green' : isNegative ? 'red' : 'grey'
  };

  return (
    <Box
      sx={{
        p: 2,
        border: 1,
        borderRadius: 4,
        borderColor: isDarkMode ? 'grey.700' : 'grey.300',
        textAlign: 'center',
        backgroundColor: isDarkMode ? 'grey.900' : 'white', // Adjust background color for dark mode
      }}
    >
      <Typography variant="h6" gutterBottom>
        {title}
        <Tooltip title={tooltipMessage} placement="top">
          <IconButton size="small" sx={{ marginBottom: 0.4 }}>
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '4rem' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="h4">
            {title === "Taxa de engajamento" ? `${formattedValue}%` : formattedValue}
          </Typography>
          <Typography variant="body1" style={kpiStyle}>
            ({kpi.toLocaleString()}%)
          </Typography>
        </>
      )}
    </Box>
  );
};

export default MetricCard;