import React from "react";
import styled from "styled-components";
// Assets
import BrandPng from "../../assets/svg/Services/BrandPng";
import CrisisPng from "../../assets/svg/Services/CrisisPng";
import CompetitorPng from "../../assets/svg/Services/CompetitorPng";
import MarketRePng from "../../assets/svg/Services/MarketRePng";
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import LivePng from "../../assets/svg/Services/LivePng";
import SwotPng from "../../assets/svg/Services/SwotPng";

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "brand":
      getIcon = <BrandPng />;
      break;
    case "crisis":
      getIcon = <CrisisPng />;
      break;
    case "competitor":
      getIcon = <CompetitorPng />;
      break;
    case "marketre":
      getIcon = <MarketRePng />;
      break;
    case "live":
      getIcon = <LivePng />;
      break;
    case "swot":
      getIcon = <SwotPng />;
      break;
    case "roller":
      getIcon = <RollerIcon />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    default:
      getIcon = null;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  margin-left: 15px;
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;