import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AcpsTabs from './AcpsTabs';
import Pricing from '../Pricing';
import Contact from '../Contact';

const devModalStyle = (darkMode) => ({
  position: 'absolute',
  top: '53%',
  left: '59.3%',
  transform: 'translate(-50%, -50%)',
  width: '65%',
  maxHeight: '85%',
  backgroundColor: darkMode ? '#333333' : '#f5f5f5',
  boxShadow: 24,
  p: 4,
  zIndex: 1200,
  overflowY: "auto",
  color: darkMode ? '#ffffff' : '#000000'
});

export default function Components({ 
  userTier,
  openModal, 
  handleCloseModal, 
  target, 
  targetName,
  targetCnpj,
  dataPoint, 
  dataPointDayBefore,
  clientGoodCommentsData, 
  clientBadCommentsData,
  competitorOneGoodCommentsData,
  competitorOneBadCommentsData,
  competitorTwoGoodCommentsData,
  competitorTwoBadCommentsData,
  competitorThreeGoodCommentsData,
  competitorThreeBadCommentsData,
  postsLikes,
  filterDateRange,
  filterPostUrl,
  cnpjClient,
  onCampaign,
  darkMode // Add darkMode prop
}) {
  const [isPricingSectionContact, setIsPricingSectionContact] = React.useState(false)
  const [subject, setSubject] = React.useState('')
  const [message, setMessage] = React.useState('')

  function transformDate(dateString) {
    const options = { timeZone: 'UTC', month: 'short', day: '2-digit', year: 'numeric' };
    const transformedDate = new Date(dateString).toLocaleDateString('pt-BR', options);
    return transformedDate;
  }

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus // Disable Autofocus to be possible interact with Action Assistant [1]
      disableAutoFocus // Disable Autofocus to be possible interact with Action Assistant [2]
      disableEscapeKeyDown // Disable closing on ESC key
      hideBackdrop // Disable closing on clicking outside
    >
      <Box id="acps-details-modal" sx={devModalStyle(darkMode)}>
      <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {userTier < 2 && target !== 0 ? (
          <>
            <Typography variant="h6" style={{ color: darkMode ? '#ffffff' : '#000000', marginBottom: '-20px' }}>
              Faça o upgrade para um plano acima do seu para ter acesso aos Detalhes do ACPS® dos seus Concorrentes.
            </Typography>
            <Box sx={{ padding: '0px', width: '100%' }}>
              <Pricing 
                setIsPricingSectionContact={setIsPricingSectionContact}
                setSubject={setSubject}
                setMessage={setMessage} 
              />
              <Contact 
                isPricingSectionContact={isPricingSectionContact}
                pricingSectionSubject={subject}
                pricingSectionMessage={message}
              /> 
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h6" id="modal-modal-title" align="center" style={{ margin: 5, color: darkMode ? '#ffffff' : '#000000' }}>
              Detalhes do ACPS de {targetName} em {transformDate(dataPoint['date'])}
            </Typography>
            <AcpsTabs
              target={target}
              targetCnpj={targetCnpj}
              dataPoint={dataPoint}
              dataPointDayBefore={dataPointDayBefore} 
              clientGoodCommentsData={clientGoodCommentsData} 
              clientBadCommentsData={clientBadCommentsData}
              competitorOneGoodCommentsData={competitorOneGoodCommentsData}
              competitorOneBadCommentsData={competitorOneBadCommentsData}
              competitorTwoGoodCommentsData={competitorTwoGoodCommentsData}
              competitorTwoBadCommentsData={competitorTwoBadCommentsData}
              competitorThreeGoodCommentsData={competitorThreeGoodCommentsData}
              competitorThreeBadCommentsData={competitorThreeBadCommentsData}
              postsLikes={postsLikes}
              filterDateRange={filterDateRange}
              filterPostUrl={filterPostUrl}
              cnpjClient={cnpjClient}
              onCampaign={onCampaign}
              darkMode={darkMode} // Pass darkMode prop to AcpsTabs
            />
          </>
        )}
      </Box>
    </Modal>  
  );
}
