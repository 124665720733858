import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Modal from '@mui/material/Modal';
import zonas_acps from "../../../../assets/img/zonas_acps.png"

// Helper function to determine background color based on ACPS value
const getBackgroundColor = (acpsValue) => {
  if (acpsValue <= 0) return '#455577'; // Zona Crítica
  if (acpsValue <= 50) return '#6880A2'; // Zona de Aperfeiçoamento
  if (acpsValue <= 75) return '#99B8D5'; // Zona de Qualidade
  return '#87c228'; // Zona de Excelência
};

// Helper function to get emoji based on ACPS value
const getEmoji = (acpsValue) => {
  if (acpsValue <= 0) return '😢'; // Zona Crítica
  if (acpsValue <= 50) return '😐'; // Zona de Aperfeiçoamento
  if (acpsValue <= 75) return '😊'; // Zona de Qualidade
  return '😄'; // Zona de Excelência
};

// Helper function to determine text color based on background color
const getTextColor = (acpsValue) => {
  return acpsValue <= 87 ? 'common.white' : 'common.black'; // Dark backgrounds get white text, light backgrounds get black text
};

function findACPSValueByDate(date, data) {
  for (let item of data) {
      if (item.date === date) {
          return item.acps;
      }
  }
  return null;
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 720,
  maxHeight: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  zIndex: "1000",
  overflowY: "auto"
};

const CampaignDailyAcps = ({ date, acpsClientData }) => {
  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const acps = findACPSValueByDate(date, acpsClientData);
  const theme = useTheme();
  const bgColor = getBackgroundColor(acps);
  const textColor = getTextColor(acps);

  function formatDateToPortuguese(dateString) {
    // Parse the date from a yyyy-mm-dd format
    const dateParts = dateString.split('-');
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Month index is 0-based in JavaScript
    const day = parseInt(dateParts[2], 10);

    // Create a date object
    const date = new Date(year, month, day);

    // Use Intl.DateTimeFormat to format the date in a verbose Portuguese format
    const formatter = new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    return formatter.format(date);
  }


  return (
    <Paper
      sx={{
        p: theme.spacing(2), // Increased padding
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 45, // Adjusted height
        bgcolor: bgColor,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        color: textColor, // Dynamic text color for better visibility
        marginBottom: '10px',
        id: 'acps-daily-campaign'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Typography variant="body1" component="div" sx={{ marginRight: '8px' }}>
          ACPS em {formatDateToPortuguese(date)}
        </Typography>
        <IconButton onClick={handleOpen} style={{ marginLeft: '1px', marginTop: '0px' }}>
          <HelpCenterOutlinedIcon />
        </IconButton>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
            <Typography variant="h5" id="modal-modal-title" align="center" style={{margin: 5 }}>
              ACPS Diário
            </Typography>
            <Grid container>
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
              >
               Esse gráfico mostra o valor do ACPS da marca no dia, o classificando em quatro zonas conforme a imagem abaixo.
              </Typography>
              {/* <Typography id="modal-desc" textColor="text.tertiary" mb={1}>
                O IAN calcula a reputação da marca captando as menções e interações em toda a internet e mídias sociais de forma orgânica.
              </Typography> */}
              <img src={zonas_acps} width={640} alt="" />
            </Grid>
            </Box>
          </Modal>
      </div>
      <Typography variant="h5" sx={{ mx: 2, fontSize: '1.5rem' }}>
        {getEmoji(acps)}
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        {acps?.toLocaleString('pt-BR', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3
      })}
      </Typography>
    </Paper>
  );
};

export default CampaignDailyAcps;
