import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import RealPng from "../../assets/svg/Pricing/RealPng"; 
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import CheckMark from "../../assets/svg/Checkmark";
import Xmark from "../../assets/svg/Xmark";
import { Link, animateScroll as scroll } from "react-scroll";
import RocketIcon from '@mui/icons-material/Rocket';

export default function PricingTable({ icon, price, title, text,  offers, action }) {

  const getLastButtonTitle = () => {
    if (!offers || offers.length === 0) {
      return "Assinar"; // Default title if there are no offers
  }

  const lastOffer = offers[offers.length - 1];
  const penultimateOffer = offers.length > 1 ? offers[offers.length - 2] : null;

  if (lastOffer.checked) {
      return "Quero saber mais"; // Title for the last offer
  // } else if (penultimateOffer && penultimateOffer.checked) {
  //     return "Lista de espera"; // Title for the penultimate offer
  } else {
      return "Assinar"; // Default title if neither last nor penultimate offer is checked
  }
  };

  const getLastButtonAction = () => {
    // if (!offers || offers.length === 0) {
    //   return "Assinar"; // Default title if there are no offers
    // }

    // const lastOffer = offers[offers.length - 1];
    // const penultimateOffer = offers.length > 1 ? offers[offers.length - 2] : null;

    // if (lastOffer.checked) {
    //   action(); // Title for the last offer
    // } else if (penultimateOffer && penultimateOffer.checked) {
    //     return; // Title for the penultimate offer
    // } else {
    //   action(); // Default title if neither last nor penultimate offer is checked
    // }

    action();
    scroll.scrollMore(600, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    })
  };

  let getIcon;

  switch (icon) {
    case "real":
      getIcon = <RealPng />;
      break;
    default:
      
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      { getIcon && price  &&
        <div className="flexSpaceCenter">
          {getIcon && getIcon}
        <p className="font30 extraBold">{price}</p>
        </div>
      }
      <div style={{ margin: "0 0" }}>
        <h4 className="font30 extraBold">{title}</h4>
        <p className="font13">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                  {item.checked ? (
                    <div style={{ minWidth: "20px" }}>
                      {/* <CheckMark /> */}
                      <RocketIcon sx={{ color: '#5cb85c' }}/>
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}>
                      <Xmark />
                    </div>
                  )}
                </div>
                <p className="font20">{item.name}</p>
              </div>
            ))
          : null}
      </div>
      <div style={{ maxWidth: "170px", margin: "30px auto 0 auto" }}>
        <FullButton title={getLastButtonTitle()} action={getLastButtonAction} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
