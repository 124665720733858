import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

export default function Review({ 
  setSite,
  googleBusinessUser,
  setGoogleBusinessUser,
  instagramUser,
  setInstagramUser,
  reclameAquiUser,
  setReclameAquiUser,
  siteError, setSiteError,
  // googleBusinessUserError, setGoogleBusinessUserError,
  // instagramUserError, setInstagramUserError,
  // reclameAquiUserError, setReclameAquiUserError
 }) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Mídias sociais e internet
      </Typography>
      <Grid container spacing={3}>
      <Grid item xs={12}>
          <TextField
            id="site"
            name="site"
            label="Endereço Web (Site)"
            fullWidth
            // autoComplete="shipping address-line2"
            variant="standard"
            onChange={(e) => {
              setSite(e.target.value);
            }}
            InputProps={{
              startAdornment: <span style={{ marginRight: '8px' }}>https://</span>,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="gbusiness"
            name="gbusiness"
            label="Google Meu Negócio"
            fullWidth
            value={googleBusinessUser}
            // autoComplete="shipping address-line2"
            variant="standard"
            onChange={(e) => {
              setGoogleBusinessUser(e.target.value);
            }}
            helperText={'Acesse o Google Mapas e busque pela sua marca. Digite o nome que aparece depois de /place/ no seu navegador. Esse é o usuário da marca no Google Meu Negócio'}
            InputProps={{
              startAdornment: <span style={{ marginRight: '8px' }}>https://www.google.com/maps/place/</span>,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="reclameaqui"
            name="reclameaqui"
            label="Reclame Aqui"
            fullWidth
            value={reclameAquiUser}
            // autoComplete="shipping address-line2"
            variant="standard"
            onChange={(e) => {
              setReclameAquiUser(e.target.value);
            }}
            helperText={'Digite o nome que aparece depois de /empresa/ no seu navegador. Esse é o usuário da marca no Reclame Aqui'}
            InputProps={{
              startAdornment: <span style={{ marginRight: '8px' }}>https://www.reclameaqui.com.br/empresa/</span>,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="instagram"
            name="instagram"
            label="Instagram"
            fullWidth
            value={instagramUser}
            // autoComplete="shipping address-line2"
            variant="standard"
            onChange={(e) => {
              setInstagramUser(e.target.value);
            }}
            helperText={'Digite o usuário do Instagram.'}
            InputProps={{
              startAdornment: <span style={{ marginRight: '8px' }}>@</span>,
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
