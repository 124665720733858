import React from 'react';
import styled from 'styled-components';
import { Tooltip, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function AdditionalFeaturesStrip({ additionalFeatures }) {
  return (
    <StripContainer>
      <StripTitle>Adicione Funcionalidades Extras para Potencializar sua Estratégia:</StripTitle>
      <StripSubtitle>
      Incorpore outras funcionalidades para atender demandas específicas, assegurando que sua marca, influência ou as estratégias de seus clientes estejam sempre um passo à frente.
      </StripSubtitle>
      <FeatureList>
        {additionalFeatures.map((feature, index) => (
          <FeatureWrapper key={index}>
            <FeatureItem>
              <FeatureName>{feature.name}</FeatureName>
              {feature.price && <FeaturePrice>{`+ ${feature.price}`}</FeaturePrice>}
              <Tooltip title={feature.tooltip} placement="top">
                <IconButton size="small">
                  <HelpOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </FeatureItem>
          </FeatureWrapper>
        ))}
      </FeatureList>
    </StripContainer>
  );
}

const StripContainer = styled.div`
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const StripTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const StripSubtitle = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: row; /* Display features in a row */
  flex-wrap: wrap; /* Allow features to wrap to the next row if needed */
  justify-content: space-between; /* Spread features evenly across the row */
`;

const FeatureWrapper = styled.div`
  flex-basis: calc(50% - 10px); /* Set width for each feature block */
  margin-bottom: 10px; /* Adjust spacing between feature blocks */
`;

const FeatureItem = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const FeatureName = styled.p`
  font-size: 16px;
  color: #333;
`;

const FeaturePrice = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #009688;
`;
