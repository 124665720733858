import React from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const CheckIcon = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-color: green;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
`;

const Description = styled.p`
  margin-top: 20px;
  text-align: center;
  font-size: 0.9em;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const TrialModal = ({ trialExpired }) => {
  return (
    <ModalWrapper>
      <Title>All advanced features available during the 15-day free trial</Title>
      <List>
        <ListItem>
          <CheckIcon>✔️</CheckIcon>
          <span>Discover at what point users abandon with Funnels</span>
        </ListItem>
        <ListItem>
          <CheckIcon>✔️</CheckIcon>
          <span>Visualize metrics with Trends</span>
        </ListItem>
        <ListItem>
          <CheckIcon>✔️</CheckIcon>
          <span>Customize surveys with your company's brand</span>
        </ListItem>
        <ListItem>
          <CheckIcon>✔️</CheckIcon>
          <span>Focus on what you need with advanced filters</span>
        </ListItem>
        <ListItem>
          <CheckIcon>✔️</CheckIcon>
          <span>And much more...</span>
        </ListItem>
      </List>
      <Description>
        After July 5, you will revert to your previous plan. No need to cancel or worry about extra charges.
      </Description>
      <Button>Understood</Button>
      <ImageWrapper>
        <Image src="your-image-source.png" alt="Illustration" />
      </ImageWrapper>
    </ModalWrapper>
  );
};

export default TrialModal;
