import React from 'react';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import TrialModal from '../Elements/TrialModal'; // Make sure this path points to where you saved the TrialModal component

const TrialModalWrapper = styled.div`
  position: absolute;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 16px 32px 24px;
  width: 600px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  height: 80%; // Adjust based on your needs
  max-height: 80%;
  box-sizing: border-box;
`;

const TrialModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
`;

const TrialModalTitle = styled.h2`
  margin: 0;
`;

const TrialModalContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px 0;
`;

const TrialModalFooter = styled.div`
  padding-top: 10px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
`;

const TrialModalButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const TrialModalContainer = ({ 
    open, 
    onClose,
    trialExpired,
    userTier
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <TrialModalWrapper>
        <TrialModalHeader>
          <TrialModalTitle>
            {
                userTier !== 0 ?
                "CALCULADORA" :
                !trialExpired && userTier === 0 ?
                "Todas as funcionalidades avançadas disponíveis por 15 dias" :
                "Atenção! Você está sem acesso às funcionalidades avançadas"
            }
            </TrialModalTitle>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TrialModalHeader>
        <TrialModalContent>
          <TrialModal />
        </TrialModalContent>
        <TrialModalFooter>
          <TrialModalButton onClick={onClose}>Entendi</TrialModalButton>
        </TrialModalFooter>
      </TrialModalWrapper>
    </Modal>
  );
};

export default TrialModalContainer;