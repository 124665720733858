import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Adicionado useParams para capturar o parâmetro da empresa
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import styled from 'styled-components';
import { ref, get, child } from 'firebase/database';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { getDatabase } from 'firebase/database'; // Importar para obter o banco de dados Firebase

const defaultTheme = createTheme();

const GoogleButton = styled(Button)({
  backgroundColor: 'white',
  color: '#444',
  textTransform: 'none',
  border: '1px solid #dadce0',
  padding: '10px 20px',
  borderRadius: '4px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#f8f9fa',
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    '& svg': {
      color: 'transparent',
    }
  }
});

// Definir o componente Copyright
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Todos os direitos reservados ® '}
      <Link color="inherit" href="/">
        Vinbol
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// Substituir o ícone do Google por um com as cores corretas
const GoogleColoredIcon = () => (
  <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
  </svg>
);

// Atualizar os estilos do TextField para melhor visibilidade do autofill
const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: '#000', // Texto preto para melhor visibilidade
  },
  '& .MuiInputBase-input:-webkit-autofill': {
    '-webkit-text-fill-color': '#000',
    '-webkit-box-shadow': '0 0 0px 1000px #f5f5f5 inset', // Fundo mais escuro para autofill
    'transition': 'background-color 5000s ease-in-out 0s', // Evita flash branco
  },
});

export default function SignIn() {
  const [showDialog, setShowDialog] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [resetEmail, setResetEmail] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [passError, setPassError] = React.useState(false);
  const [showEmailForm, setShowEmailForm] = React.useState(false);
  const [logoUrl, setLogoUrl] = React.useState(null); // Novo estado para o logo
  const navigate = useNavigate();
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const { company } = useParams(); // Obter o nome da empresa da URL

  // Busca o logo do Firebase quando é white-label ou default
  React.useEffect(() => {
    const dbRef = ref(getDatabase());
    const companyPath = company || 'default';
    
    // Buscar a configuração no Realtime Database
    get(child(dbRef, `white-label/companyConfigs/${companyPath}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const config = snapshot.val();
          
          // Extrair o caminho do logo do formato gs://
          const bucketName = config.logo.onLightTheme.split('/')[2];
          const filePath = config.logo.onLightTheme.split('/').slice(3).join('/');
          
          // Carregar o logo correto
          const storage = getStorage();
          const logoStorageRef = storageRef(storage, filePath);

          getDownloadURL(logoStorageRef)
            .then((url) => {
              setLogoUrl(url);
            })
            .catch((error) => {
              console.error('Error fetching logo URL:', error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching config:", error);
      });
  }, [company]); // Mantém a dependência para re-executar quando mudar a empresa

  const handleGoogleSignup = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Redirecionar para o dashboard correto com base na empresa
      if (company) {
        navigate(`/${company}/dashboard`);
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error('Error during Google Sign-In', error);
    }
  };

  const onLogin = (e) => {
    e.preventDefault();
    if (email.trim() === '') {
      setEmailError(true);
      return;
    } else if (password.trim() === '') {
      setPassError(true);
      return;
    } else if (resetEmail) {
      triggerResetEmail();
      return;
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Verificar se é onboarding
          const dbRef = ref(getDatabase());
          const userSnapshot = await get(child(dbRef, `users/${userCredential.user.uid}`));
          
          if (userSnapshot.exists()) {
            const userData = userSnapshot.val();
            if (userData.isOnboarding) {
              // Se for onboarding, redirecionar para admin dashboard
              if (company) {
                navigate(`/${company}/admin`);
              } else {
                navigate("/admin");
              }
              return;
            }
          }
          
          // Se não for onboarding, redirecionar normalmente
          if (company) {
            navigate(`/${company}/dashboard`);
          } else {
            navigate("/dashboard");
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          if (errorCode === 'auth/invalid-login-credentials') {
            alert("Credenciais inválidas, por favor, revise o endereço de e-mail e senha digitados.");
          }
        });
    }
  };

  const triggerResetEmail = async () => {
    await sendPasswordResetEmail(auth, email);
    alert("E-mail de redefinição de senha enviado");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar sx={{ justifyContent: 'flex-end' }}>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* Avatar agora usa o logo quando disponível */}
          {logoUrl ? (
            <Avatar
              src={logoUrl}
              sx={{ 
                m: 1,
                width: 'auto',
                height: 'auto',
                maxWidth: '200px',  // Limite máximo de largura
                maxHeight: '80px',  // Limite máximo de altura
                minWidth: '56px',   // Tamanho mínimo para logos pequenos
                minHeight: '56px',  // Tamanho mínimo para logos pequenos
                bgcolor: 'white',
                borderRadius: '4px', // Removendo formato circular do Avatar
                '& img': {
                  objectFit: 'contain', // Mantém proporção original
                  width: '100%',
                  height: '100%'
                }
              }}
              variant="square" // Remove o formato circular padrão do Avatar
            />
          ) : (
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
          )}
          <Typography component="h1" variant="h5">
            Entrar na plataforma
          </Typography>
          {!showEmailForm ? (
            <React.Fragment>
              <GoogleButton
                variant="contained"
                startIcon={<GoogleColoredIcon />}
                fullWidth
                onClick={handleGoogleSignup}
                sx={{ mt: 3, mb: 2 }}
              >
                Continuar com Google
              </GoogleButton>
              <Divider sx={{ width: '100%', mb: 2 }}>OU</Divider>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setShowEmailForm(true)}
                sx={{ mb: 2 }}
              >
                Continuar com Email
              </Button>
            </React.Fragment>
          ) : (
            <Box component="form" onSubmit={onLogin} noValidate sx={{ mt: 1 }}>
              <StyledTextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Endereço de email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(e.target.value.trim() === '');
                }}
                error={emailError}
                helperText={emailError ? 'Campo obrigatório' : ''}
              />
              {!resetEmail ? (
                <StyledTextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPassError(e.target.value.trim() === '');
                  }}
                  error={passError}
                  helperText={passError ? 'Campo obrigatório' : ''}
                />
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!resetEmail ? "Entrar" : "Enviar instruções para o e-mail"}
              </Button>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Link 
                    href="#" 
                    onClick={() => setShowEmailForm(false)} 
                    variant="body2"
                  >
                    Voltar
                  </Link>
                </Grid>
                <Grid item>
                  {!resetEmail ? (
                    <Link 
                      href="#" 
                      onClick={() => setResetEmail(true)} 
                      variant="body2"
                    >
                      Esqueceu a senha?
                    </Link>
                  ) : (
                    <Link 
                      href="#" 
                      onClick={() => setResetEmail(false)} 
                      variant="body2" 
                      component="button"
                    >
                      Voltar para o login
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
