import * as React from "react";
import logo from "../../img/alert.png"

function SvgComponent(props) {
  return (
    <img src={logo} width={64} height={64} alt="React Logo" style={{marginTop:'-20px'}}/>
  );
}

export default SvgComponent;
