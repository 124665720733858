import React from 'react';
import '../../style/Tags.css';

const NewMentionTag = () => {
  return (
    <div className="new-mention-tag">
      <span>Novo</span>
    </div>
  );
};

export default NewMentionTag;
