import React, { useState } from 'react';
import styled from 'styled-components';

const VideoFrameWrapper = styled.div`
  width: 100%;
  margin: 0 6% 10px 6%;
  position: relative;
  cursor: pointer;
`;

const PlayIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  opacity: 0.8;
  background: none;

  &::before {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    background: url('data:image/svg+xml;utf8,<svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><circle cx="32" cy="32" r="32" fill="rgba(0, 0, 0, 0.5)" /><polygon points="26,20 50,32 26,44" fill="white" /></svg>') no-repeat center center;
    background-size: contain;
  }
`;

const AspectRatioBox = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);

  img, iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
  }
`;

const VideoFrame = ({ imageUrl, videoUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <VideoFrameWrapper onClick={handlePlay} className="flexCenter">
      <AspectRatioBox>
        {!isPlaying ? (
          <>
            <img src={imageUrl} alt="video cover" />
            <PlayIcon />
          </>
        ) : (
          <iframe
            src={`${videoUrl}?autoplay=1`}
            webkitallowfullscreen 
            mozallowfullscreen
            allowFullScreen
          ></iframe>
        )}
      </AspectRatioBox>
    </VideoFrameWrapper>
  );
};

export default VideoFrame;
