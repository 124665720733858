import * as React from 'react';

export default function Tags({ tags, currentTags, setCurrentTags }) {
  const addTag = React.useCallback(
    (tag) => () => {
      if (!currentTags.includes(tag)) {
        return setCurrentTags((prevTags) => [...prevTags, tag]);
      }
    },
    [currentTags, setCurrentTags]
  );

  return (
    <>
      {tags && tags.map((tag, index) => (
        <ul key={index} className='tagsList' style={{ padding: 0 }}>
          <li onClick={addTag(tag)} className="tag clickable">
            {tag}
          </li>
        </ul>
      ))}
    </>
  );
}
