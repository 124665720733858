import * as React from 'react';
import { Box, Typography, Grid, Paper, Button, TextField } from '@mui/material';
import {
  DataSheetGrid,
  textColumn,
  keyColumn,
} from 'react-datasheet-grid';
import {
  ComposedChart,
  Area,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, child, get, set } from "firebase/database";
import 'react-datasheet-grid/dist/style.css';
import styled from 'styled-components';

// Define the common background color for dark mode
const darkModePaperColor = '#3a3d4d';

// Styled-components for dark mode

const StyledDataSheetGrid = styled(DataSheetGrid)`
  background-color: ${({ darkMode }) => darkMode ? darkModePaperColor : '#ffffff'};
  color: ${({ darkMode }) => darkMode ? '#f5f5f5' : '#000000'};

  .dsg-cell {
    background-color: ${({ darkMode }) => darkMode ? darkModePaperColor : '#ffffff'};
    color: ${({ darkMode }) => darkMode ? '#f5f5f5' : '#000000'};
  }

  .dsg-header {
    background-color: ${({ darkMode }) => darkMode ? '#444444' : '#f5f5f5'};
    color: ${({ darkMode }) => darkMode ? '#f5f5f5' : '#000000'};
    height: auto; /* Adjust header height dynamically */
    white-space: normal; /* Allow header text to wrap */
  }

  .dsg-row.is-new .dsg-cell {
    background-color: ${({ darkMode }) => darkMode ? darkModePaperColor : '#ffffff'};
    color: ${({ darkMode }) => darkMode ? '#f5f5f5' : '#000000'};
  }

  .dsg-cell.is-selected,
  .dsg-cell.is-focused {
    background-color: ${({ darkMode }) => darkMode ? '#555555' : '#e0e0e0'};
    color: ${({ darkMode }) => darkMode ? '#ffffff' : '#000000'};
  }

  .dsg-add-row {
    background-color: ${({ darkMode }) => darkMode ? darkModePaperColor : '#ffffff'};
    color: ${({ darkMode }) => darkMode ? '#f5f5f5' : '#000000'};
    &:hover {
      background-color: ${({ darkMode }) => darkMode ? '#555555' : '#f0f0f0'};
    }
  }
`;

const StyledTextField = styled(TextField)`
  && {
    .MuiInputBase-root {
      background-color: ${({ darkMode }) => darkMode ? darkModePaperColor : '#ffffff'};
      color: ${({ darkMode }) => darkMode ? '#ffffff' : '#000000'};
    }

    .MuiInputLabel-root {
      color: ${({ darkMode }) => darkMode ? '#cccccc' : '#000000'};
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ darkMode }) => darkMode ? '#555555' : '#000000'};
    }
  }
`;

const StyledPaper = styled(Paper)`
  background-color: ${({ darkMode }) => darkMode ? darkModePaperColor : '#ffffff'};
  color: ${({ darkMode }) => darkMode ? '#f5f5f5' : '#000000'};
`;

const StyledButton = styled(Button)`
  background-color: ${({ darkMode }) => darkMode ? '#424242' : 'inherit'};
  color: ${({ darkMode }) => darkMode ? '#ffffff' : 'inherit'};
  &:hover {
    background-color: ${({ darkMode }) => darkMode ? '#555555' : 'inherit'};
  }
`;

// Function definition for formatYearWeekToWeekOfMonth
function formatYearWeekToWeekOfMonth(yearWeek) {
  const [year, week] = yearWeek.split('-W');
  const firstDayOfYear = new Date(year, 0, 1);
  const firstDayOfWeek = new Date(firstDayOfYear);
  const daysToAdd = (parseInt(week, 10) - 1) * 7;
  const dayOffset = firstDayOfYear.getDay() === 0 ? 6 : firstDayOfYear.getDay() - 1;
  firstDayOfWeek.setDate(firstDayOfYear.getDate() + daysToAdd - dayOffset);
  const day = String(firstDayOfWeek.getDate()).padStart(2, '0');
  const month = String(firstDayOfWeek.getMonth() + 1).padStart(2, '0');
  const yearShort = firstDayOfWeek.getFullYear().toString().substr(2);
  return `${day}/${month}/${yearShort}`;
}

export default function AcpsCompara({ data, cnpjClient, isGroup, activeTutorial, darkMode }) {
  const [editData, setEditData] = React.useState([]);
  const [displayData, setDisplayData] = React.useState([]);
  const [newIndicatorName, setNewIndicatorName] = React.useState('Indicador 1');
  const [initialIndicatorName, setInitialIndicatorName] = React.useState('');

  React.useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, user => {
      if (user) {
        const dbRef = ref(getDatabase());
        let cleanCnpj = isGroup ? cnpjClient.split(',')[0].replace(/[./-]/g, '') : cnpjClient.replace(/[./-]/g, '');
        get(child(dbRef, `acps-compara/${cleanCnpj}/0/`)).then(snapshot => {
          if (snapshot.exists()) {
            const firebaseData = snapshot.val();
            setInitialIndicatorName(firebaseData.name);
            setNewIndicatorName(firebaseData.name);
            const convertDateKeys = data => Object.keys(data).reduce((acc, key) => {
              const newKey = key.replace(/-/g, '/');
              acc[newKey] = data[key];
              return acc;
            }, {});
            const formattedKpis = convertDateKeys(firebaseData.kpi || {});
            const formattedTargets = convertDateKeys(firebaseData.target || {});
            let mergedData = data.map(week => {
              const formattedWeek = formatYearWeekToWeekOfMonth(week.week).replace(/-/g, '/');
              return {
                day: formattedWeek,
                acps: week ? week.acps : '',
                indicador: formattedKpis[formattedWeek] || '',
                meta: formattedTargets[formattedWeek] || ''
              };
            });

            // Add any missing future dates from Firebase data
            Object.keys(formattedKpis).forEach(date => {
              if (!mergedData.some(item => item.day === date)) {
                mergedData.push({
                  day: date,
                  acps: '',
                  indicador: formattedKpis[date] || '',
                  meta: formattedTargets[date] || ''
                });
              }
            });

            // Sort the merged data by date
            mergedData.sort((a, b) => {
              const [dayA, monthA, yearA] = a.day.split('/').map(Number);
              const [dayB, monthB, yearB] = b.day.split('/').map(Number);
              return new Date(yearA + 2000, monthA - 1, dayA) - new Date(yearB + 2000, monthB - 1, dayB);
            });

            setEditData(mergedData);
            setDisplayData(mergedData);
          } else {
            console.log("No data available");
            const fallbackData = data.map(week => ({
              day: formatYearWeekToWeekOfMonth(week.week).replace(/-/g, '/'),
              acps: week ? week.acps : '',
              indicador: '',
              meta: ''
            }));
            setEditData(fallbackData);
            setDisplayData(fallbackData);
          }
        }).catch(error => {
          console.error(error);
        });
      }
    });
  }, [data]);

  const columns = [
    {
      ...keyColumn('day', textColumn),
      title: 'Data',
      cellStyle: ({ value }) => {
        const currentDate = new Date();
        const cellDateParts = value.split('/');
        const cellDate = new Date(`20${cellDateParts[2]}`, cellDateParts[1] - 1, cellDateParts[0]);
        return {
          backgroundColor: darkMode
            ? cellDate > currentDate ? '#333333' : '#121212'
            : cellDate > currentDate ? '#FFEB3B' : undefined,
          color: darkMode ? '#f5f5f5' : undefined,
        };
      },
    },
    { ...keyColumn('acps', textColumn), title: 'ACPS' },
    { ...keyColumn('indicador', textColumn), title: newIndicatorName },
    { ...keyColumn('meta', textColumn), title: `Meta para ${newIndicatorName}` },
  ];

  const handleSaveChanges = () => {
    const isConfirmed = window.confirm('Você deseja salvar as alterações na base de dados? Essa ação não poderá ser desfeita.');
    if (isConfirmed) {
      const auth = getAuth();
      let cleanCnpj = '';
      onAuthStateChanged(auth, user => {
        if (user) {
          const db = getDatabase();
          cleanCnpj = isGroup ? cnpjClient.split(',')[0].replace(/[./-]/g, '') : cnpjClient.replace(/[./-]/g, '');
          const updates = {};
          updates['frequency'] = "weekly";
          updates['name'] = newIndicatorName;
          updates['kpi'] = {};
          updates['target'] = {};
          editData.forEach(item => {
            if (item.day) {
              const formattedDay = item.day.replace(/\//g, '-');
              updates['kpi'][formattedDay] = item.indicador !== undefined ? item.indicador : null;
              updates['target'][formattedDay] = item.meta !== undefined ? item.meta : null;
            }
          });
          set(ref(db, `acps-compara/${cleanCnpj}/0/`), updates)
            .then(() => {
              console.log('Data saved successfully!');
              setDisplayData(editData);
            })
            .catch(error => {
              console.error('Error saving data: ', error);
            });
        } else {
          console.log("User is not signed in.");
        }
      });
    } else {
      console.log('As modificações não foram salvas na base de dados.');
    }
  };

  const handleClearChanges = () => {
    setEditData([...displayData]);
    setNewIndicatorName(initialIndicatorName);
  };

  const handleChangeIndicatorName = (event) => {
    setNewIndicatorName(event.target.value);
  };

  function isValidNumber(value) {
    return value !== '' && !isNaN(Number(value));
  }

  const validDataForChart = editData.filter(item =>
    isValidNumber(item.acps) &&
    isValidNumber(item.indicador) &&
    isValidNumber(item.meta)
  );

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2}>
        {/* {activeTutorial && (
          <Grid item xs={12}>
            <StyledPaper darkMode={darkMode}
              sx={{
                p: 1,
                mb: 2,
                borderRadius: '10px',
                fontSize: '0.8rem',
              }}
            >
              <Typography variant="body2" component="p">
                O <strong>ACPS® Compara</strong> é uma ferramenta avançada oferecida pela Vinbol.AI, projetada para permitir a comparação do ACPS® com um indicador fim de objetivo específico escolhido pela marca. Isso possibilita uma análise mais aprofundada e direcionada sobre o impacto das estratégias digitais adotadas.
                <br /><br />
                Através da Análise SWOT Avançada para essa Demonstração, identificamos que a ação mais prioritária é a melhoria do atendimento ao cliente e da comunicação. Essa prioridade foi estabelecida face aos desafios evidenciados por alguns clientes em obter respostas rápidas e eficientes, especialmente em canais digitais. Aperfeiçoar esses aspectos é essencial para elevar a satisfação dos clientes, melhorar a imagem da marca e fomentar a fidelização.
              </Typography>
            </StyledPaper>
          </Grid>
        )} */}
        <Grid item xs={12}>
          <StyledTextField
            darkMode={darkMode}
            label="Nome do Indicador"
            variant="outlined"
            value={newIndicatorName}
            onChange={handleChangeIndicatorName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} container spacing={2} sx={{ display: 'flex', height: '500px' }}>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} id="chart-compara">
            <StyledPaper darkMode={darkMode} sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  data={validDataForChart}
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke={darkMode ? '#333333' : '#e0e0e0'} />
                  <XAxis dataKey="day" stroke={darkMode ? '#cccccc' : '#000000'} />
                  <YAxis yAxisId="left" domain={[0, 'auto']} stroke={darkMode ? '#cccccc' : '#000000'} />
                  <YAxis yAxisId="right" orientation="right" domain={[-100, 100]} stroke={darkMode ? '#cccccc' : '#000000'} />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: darkMode ? '#1e1e1e' : '#ffffff',
                      borderColor: darkMode ? '#8884d8' : '#000000',
                      color: darkMode ? '#cccccc' : '#000000',
                    }}
                  />
                  <Legend />
                  <Bar yAxisId="left" dataKey="indicador" fill={darkMode ? '#8884d8' : '#8884d8'} />
                  <Line yAxisId="left" type="monotone" dataKey="meta" stroke={darkMode ? '#ff7300' : '#ff7300'} />
                  <Area yAxisId="right" type="monotone" dataKey="acps" stroke={darkMode ? '#82ca9d' : '#82ca9d'} fill={darkMode ? '#82ca9d' : '#82ca9d'} />
                </ComposedChart>
              </ResponsiveContainer>
            </StyledPaper>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} id="data-table">
            <StyledPaper darkMode={darkMode} sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
              <StyledDataSheetGrid
                darkMode={darkMode}
                value={editData}
                onChange={setEditData}
                columns={columns}
              />
            </StyledPaper>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <StyledButton darkMode={darkMode} variant="contained" onClick={handleSaveChanges}>
            Salvar Alterações
          </StyledButton>
          <StyledButton darkMode={darkMode} variant="contained" onClick={handleClearChanges} sx={{ ml: 2 }}>
            Descartar Alterações
          </StyledButton>
        </Grid>
      </Grid>
    </Box>
  );
}
