import * as React from "react";

function SvgComponent({ style, ...props }) {
  return (
    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0.43 1064 928.69" style={style}>
        <linearGradient id="a" x1="0%" x2="99.999%" y1="49.999%" y2="49.999%">
            <stop offset=".03" stop-color="#4079d8"/>
            <stop offset="1" stop-color="#4989f5"/>
        </linearGradient>
        <g fill="none" fill-rule="evenodd">
            <g fill-rule="nonzero">
                <rect fill="#4989f5" height="696.14" rx="36.88" width="931" x="53.45" y="232.98"/>
                <path d="M936.81 227.75H100.06c-25.92 0-46.09 200.6-46.09 226.52L512.2 929.12h424.61c26-.071 47.059-21.13 47.13-47.13V274.87c-.077-25.996-21.134-47.049-47.13-47.12z" fill="url(#a)"/>
                <path d="M266.03 349.56h266V.44H305.86z" fill="#3c4ba6"/>
                <path d="M798.03 349.56h-266V.44H758.2zM984.45 66.62l.33 1.19c-.08-.42-.24-.81-.33-1.19z" fill="#7babf7"/>
                <path d="M984.78 67.8l-.33-1.19C976.017 27.993 941.837.455 902.31.43H758.2L798 349.56h266z" fill="#3f51b5"/>
                <path d="M79.61 66.62l-.33 1.19c.08-.42.24-.81.33-1.19z" fill="#7babf7"/>
                <path d="M79.27 67.8l.33-1.19C88.033 27.993 122.213.455 161.74.43h144.12L266 349.56H0z" fill="#7babf7"/>
            </g>
                <path d="M266.48 349.47c0 73.412-59.513 132.925-132.925 132.925S.63 422.882.63 349.47z" fill="#709be0"/>
                <path d="M532.33 349.47c0 73.412-59.513 132.925-132.925 132.925S266.48 422.882 266.48 349.47z" fill="#3c4ba6"/>
                <path d="M798.18 349.47c0 73.412-59.513 132.925-132.925 132.925S532.33 422.882 532.33 349.47z" fill="#709be0"/>
                <path d="M1064 349.47c0 73.412-59.513 132.925-132.925 132.925S798.15 422.882 798.15 349.47z" fill="#3c4ba6"/>
                
        </g>
    </svg>
  );
}

export default SvgComponent;
