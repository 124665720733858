import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Mentions from './AcpsMentions';
import AcpsWordCloud from './AcpsWordCloud';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MetricCard from './Metric';
import TagsList from '../../Elements/AcpsTagsList';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import ColorInterpolationBox from '../../Elements/ColorInterpolationBox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

function CustomTabPanel(props) {
  const { children, value, index, darkMode, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ color: darkMode ? '#FFFFFF' : '#000000' }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const API_URI_TOTAL_NOMES = 'https://core-api-hjfuceckbqgbgqhx.vinbol.ai/acps_details';

export default function AcpsTabs({ 
  target, 
  targetCnpj,
  dataPoint,
  dataPointDayBefore, 
  clientGoodCommentsData, 
  clientBadCommentsData,
  competitorOneGoodCommentsData,
  competitorOneBadCommentsData,
  competitorTwoGoodCommentsData,
  competitorTwoBadCommentsData,
  competitorThreeGoodCommentsData,
  competitorThreeBadCommentsData,
  postsLikes,
  filterDateRange,
  filterPostUrl,
  onCampaign,
  darkMode // Add darkMode prop
}) {
  const [value, setValue] = useState(0);
  const [currentTags, setCurrentTags] = useState([]);
  const [filterWords, setFilterWords] = useState([]);
  const [totalComments, setTotalComments] = useState(0);
  const [totalCommentsDayBefore, setTotalCommentsDayBefore] = useState(0);
  const [totalAuthors, setTotalAuthors] = useState(0);
  const [totalAuthorsDayBefore, setTotalAuthorsDayBefore] = useState(0);
  const [engagementRate, setEngagementRate] = useState(0.0);
  const [engagementRateDayBefore, setEngagementRateDayBefore] = useState(0.0);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);

  const sourceOptions = [
    { id: 1, label: 'Google Perfil de Negócios' },
    { id: 2, label: 'Instagram' },
    { id: 3, label: 'Reclame Aqui' },
    { id: 4, label: 'Facebook' }
  ];

  const [selectedSources, setSelectedSources] = useState(sourceOptions.map(source => source.id));
  const [tempSelectedSources, setTempSelectedSources] = useState(sourceOptions.map(source => source.id));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleWordClick = (word) => {
    setValue(1); // Switch to 'Menções' tab
    setFilterWords([word]);
  };

  const fetchData = (date) => {
    const startDateParam = filterDateRange ? `&startDate=${filterDateRange.startDate}` : "";
    const sourcesParam = selectedSources.length > 0 ? `&idSource=${selectedSources.join(',')}` : "";
    
    axios.get(`${API_URI_TOTAL_NOMES}?cnpj=${targetCnpj}&postUrl${onCampaign ? `=${filterPostUrl}` : ""}${startDateParam}&endDate=${date < 0 ? dataPointDayBefore['date'] : dataPoint['date']}${sourcesParam}`)
      .then((response) => {
        if (date < 0) {
          setTotalCommentsDayBefore(response.data[0].total_comments);
          setTotalAuthorsDayBefore(response.data[0].total_authors);
          setEngagementRateDayBefore(response.data[0].engagement_rate);
        } else {
          setTotalComments(response.data[0].total_comments);
          setTotalAuthors(response.data[0].total_authors);
          setEngagementRate(response.data[0].engagement_rate);
          setLoading(false);
          setNoDataFound(false);
        }
      })
      .catch((error) => {
        if (date === 0) { // Apenas para a chamada do dia atual
          setTotalComments(null);
          setTotalAuthors(null);
          setEngagementRate(null);
          setNoDataFound(true);
          setLoading(false);
        }
      });
  };

  const handleSourceChange = (event) => {
    setTempSelectedSources(event.target.value);
  };

  const handleApplyFilter = () => {
    setSelectOpen(false); // Fecha o dropdown
    setLoading(true);
    setSelectedSources(tempSelectedSources);
  };

  const handleClearFilter = () => {
    setTempSelectedSources([]);
    setSelectedSources([]);
    setNoDataFound(false);
  };

  useEffect(() => {
    setTempSelectedSources(selectedSources);
  }, [selectedSources]);

  useEffect(() => {
    fetchData(0); // Today
    fetchData(-1); // Yesterday
  }, [filterDateRange, filterPostUrl, targetCnpj, dataPoint, dataPointDayBefore, onCampaign, selectedSources]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ 
        borderBottom: 1, 
        borderColor: darkMode ? '#424242' : 'divider',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Tabs 
          value={value} 
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          indicatorColor="secondary"
          sx={{
            '& .MuiTab-root': {
              color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
              '&.Mui-selected': {
                color: darkMode ? '#fff' : '#1976d2',
              },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: darkMode ? '#fff' : '#1976d2',
            },
          }}
        >
          <Tab label={`${onCampaign ? 'Visão geral*' : 'Visão geral'}`} {...a11yProps(0)} />
          <Tab label={`${onCampaign ? 'Menções*' : 'Menções'}`} {...a11yProps(1)} />
          <Tab label={`${onCampaign ? 'Nuvem de palavras*' : 'Nuvem de palavras'}`} {...a11yProps(2)} />
        </Tabs>
        
        <FormControl 
          size="small"
          sx={{ 
            width: '180px',
            marginRight: 2,
            '& .MuiInputLabel-root': {
              fontSize: '0.875rem',
              color: darkMode ? 'rgba(255, 255, 255, 0.7)' : undefined,
              '&.Mui-focused': {
                color: darkMode ? '#fff' : '#1976d2',
              },
            },
            '& .MuiOutlinedInput-root': {
              height: '32px',
              color: darkMode ? '#fff' : undefined,
              '& fieldset': {
                borderColor: darkMode ? 'rgba(255, 255, 255, 0.23)' : undefined,
                borderRadius: '6px',
              },
            },
          }}
        >
          <InputLabel id="source-select-label">Fontes de dados</InputLabel>
          <Select
            labelId="source-select-label"
            id="source-select"
            multiple
            value={tempSelectedSources}
            onChange={handleSourceChange}
            label="Fontes de dados"
            renderValue={(selected) => 
              selected.length === 0 
                ? 'Selecione' 
                : `${selected.length} fonte${selected.length === 1 ? '' : 's'}`
            }
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: darkMode ? '#3a3d4d' : '#fff',
                  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                  maxHeight: '300px',
                  width: '220px',
                },
              },
            }}
            open={selectOpen}
            onOpen={() => setSelectOpen(true)}
            onClose={() => setSelectOpen(false)}
          >
            {sourceOptions.map((option) => (
              <MenuItem 
                key={option.id} 
                value={option.id}
                sx={{
                  padding: '4px 8px',
                  height: '32px',
                }}
              >
                <Checkbox 
                  checked={tempSelectedSources.indexOf(option.id) > -1}
                  size="small"
                  sx={{
                    color: darkMode ? 'rgba(255, 255, 255, 0.7)' : undefined,
                    '&.Mui-checked': {
                      color: darkMode ? '#fff' : '#1976d2',
                    },
                  }}
                />
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: darkMode ? '#fff' : undefined,
                    fontSize: '0.813rem',
                  }}
                >
                  {option.label}
                </Typography>
              </MenuItem>
            ))}
            <Divider sx={{ my: 1 }} />
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              px: 1, 
              py: 0.5 
            }}>
              <Button 
                size="small" 
                onClick={handleClearFilter}
                sx={{ 
                  fontSize: '0.75rem',
                  color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                  },
                }}
              >
                Limpar
              </Button>
              <Button 
                size="small" 
                onClick={handleApplyFilter}
                variant="text"
                sx={{ 
                  fontSize: '0.75rem',
                  color: darkMode ? '#fff' : '#1976d2',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                  },
                }}
              >
                Aplicar filtro
              </Button>
            </Box>
          </Select>
        </FormControl>
      </Box>
      {noDataFound && (
        <Alert 
          severity="warning" 
          sx={{ 
            mt: 2, 
            mb: 2,
            backgroundColor: darkMode ? 'rgba(237, 108, 2, 0.1)' : undefined,
            color: darkMode ? '#fff' : undefined,
            '& .MuiAlert-icon': {
              color: darkMode ? '#ed6c02' : undefined
            }
          }}
        >
          Não foram encontrados dados para a combinação de filtros selecionada.
        </Alert>
      )}
      <CustomTabPanel value={value} index={0} darkMode={darkMode}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <MetricCard 
              title="Total de menções" 
              value={totalComments} 
              referenceValue={totalCommentsDayBefore} 
              tooltipMessage={"Total de menções feitas à marca"} 
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MetricCard 
              title="Total de autores" 
              value={totalAuthors} 
              referenceValue={totalAuthorsDayBefore} 
              tooltipMessage={"Representa o número de indivíduos distintos que mencionaram a marca. Cada autor é contado apenas uma vez, independentemente de quantas vezes tenha feito menções."} 
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MetricCard 
              title="Taxa de engajamento" 
              value={engagementRate * 100} 
              referenceValue={engagementRateDayBefore * 100} 
              tooltipMessage={filterPostUrl ? "É a média de curtidas e comentários das publicações que compõem a campanha dividido pelo número de seguidores. Esse indicador compara o nível de interação com os posts da campanha em relação ao número de seguidores. Algumas redes sociais como o Instagram não entregam o seu conteúdo para todos os seus seguidores de forma orgânica, por isso, veja a tabela \"Faixas de Taxa de Engajamento máximo por quantidade de seguidores\" para avaliar o seu real cenário." : "É a média de curtidas e comentários das últimas 12 publicações dividido pelo número de seguidores. Esse indicador compara o nível de interação com o post em relação aos seguidores."} 
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MetricCard 
              title="ACPS" 
              value={dataPoint['acpsVal']} 
              referenceValue={dataPointDayBefore['acpsVal']} 
              tooltipMessage={"O ACPS do dia avalia o sentimento do seu público em relação a campanha após classificar os sentimentos expressos nos comentários e menções."} 
              loading={loading}
            />
          </Grid>
        </Grid>
        <span style={{ fontSize: '11px' }}>¹Evolução em relação ao dia anterior.</span>
        <br />
        <span style={{ fontSize: '11px' }}>*Filtrado pela campanha.</span>
        {onCampaign && (
          <>
            <br />
            <span style={{ fontSize: '11px' }}>Faixas de Taxa de Engajamento máximo por quantidade de seguidores:</span>
            <br />
            <StyledTable darkMode={darkMode}>
              <thead>
                <tr>
                  <StyledTh darkMode={darkMode}>Seguidores</StyledTh>
                  <StyledTh darkMode={darkMode}>Taxa de engajamento</StyledTh>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <StyledTd darkMode={darkMode}>Até 1.000</StyledTd>
                  <StyledTd darkMode={darkMode}>Máximo de 8%</StyledTd>
                </tr>
                <tr>
                  <StyledTd darkMode={darkMode}>De 1.000 a 5.000</StyledTd>
                  <StyledTd darkMode={darkMode}>Máximo de 5,7%</StyledTd>
                </tr>
                <tr>
                  <StyledTd darkMode={darkMode}>De 5.000 a 10.000</StyledTd>
                  <StyledTd darkMode={darkMode}>Máximo de 4%</StyledTd>
                </tr>
                <tr>
                  <StyledTd darkMode={darkMode}>De 10.000 a 100.000</StyledTd>
                  <StyledTd darkMode={darkMode}>Máximo de 2,4%</StyledTd>
                </tr>
                <tr>
                  <StyledTd darkMode={darkMode}>Acima de 100.000</StyledTd>
                  <StyledTd darkMode={darkMode}>Máximo de 1,7%</StyledTd>
                </tr>
              </tbody>
            </StyledTable>
          </>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} darkMode={darkMode}>
        <TagsList 
          currentTags={currentTags} 
          setCurrentTags={setCurrentTags} 
          filterWords={filterWords} 
          setFilterWords={setFilterWords} 
        />
        <Mentions 
          dataPoint={dataPoint} 
          targetCnpj={targetCnpj}
          currentTags={currentTags}
          setCurrentTags={setCurrentTags}
          filterWords={filterWords}
          filterDateRange={filterDateRange}
          filterPostUrl={filterPostUrl}
          darkMode={darkMode}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} darkMode={darkMode}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7.5} lg={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 350, backgroundColor: darkMode ? '#3a3d4d' : '#FFFFFF' }}>
              <AcpsWordCloud 
                dataPoint={dataPoint} 
                targetCnpj={targetCnpj}
                filterDateRange={filterDateRange}
                filterPostUrl={filterPostUrl}
                onWordClick={handleWordClick} 
                darkMode={darkMode}
              />
            </Paper>
            <ColorInterpolationBox darkMode={darkMode} />
          </Grid>
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 11px;
  color: ${(props) => (props.darkMode ? '#FFFFFF' : '#000000')};
`;

const StyledTh = styled.th`
  border: 1px solid ${(props) => (props.darkMode ? '#424242' : '#dddddd')};
  text-align: left;
  padding: 6px;
  background-color: ${(props) => (props.darkMode ? '#3a3d4d' : '#f5f5f5')};
`;

const StyledTd = styled.td`
  border: 1px solid ${(props) => (props.darkMode ? '#424242' : '#dddddd')};
  text-align: left;
  padding: 6px;
  background-color: ${(props) => (props.darkMode ? '#3a3d4d' : '#ffffff')};
`;
