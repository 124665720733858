import React, { useState } from 'react';
import { Box, Typography, Divider, Paper } from '@mui/material';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Dot } from 'recharts';
import Components from '../AcpsComponents';

function formatYearWeekToWeekOfMonth(yearWeek) {
  const [year, week] = yearWeek.split('-W');
  const firstDayOfYear = new Date(Date.UTC(year, 0, 1));
  const firstDayOfWeek = new Date(firstDayOfYear);

  const daysToAdd = (parseInt(week, 10) - 1) * 7;
  const dayOffset = firstDayOfYear.getUTCDay() === 0 ? 6 : firstDayOfYear.getUTCDay() - 1;
  firstDayOfWeek.setUTCDate(firstDayOfYear.getUTCDate() + daysToAdd - dayOffset);

  const day = String(firstDayOfWeek.getUTCDate()).padStart(2, '0');
  const month = String(firstDayOfWeek.getUTCMonth() + 1).padStart(2, '0');
  const yearShort = firstDayOfWeek.getUTCFullYear().toString().substr(2);

  return `${day}/${month}/${yearShort}`;
}

const CampaignChart = ({ 
  chartOpt, 
  currCnpj,
  userTier,
  clientGoodCommentsData,
  clientBadCommentsData,
  chartData, 
  dateRange,
  campaignPosts,
  onCampaign,
  darkMode,
  isTourActive,
  joyrideRef
}) => {
  const [dataPoint, setDataPoint] = useState({});
  const [dataPointDayBefore, setDatapointDayBefore] = useState({});
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const { startDate, endDate } = dateRange;

  const dateToWeekOfYear = (date) => {
    const d = new Date(date);
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNum = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return `${d.getUTCFullYear()}-W${weekNum.toString().padStart(2, '0')}`;
  };

  const filteredChartData = chartData.filter((data) => {
    if (chartOpt === "weekly") {
      const weekStart = dateToWeekOfYear(startDate);
      const weekEnd = dateToWeekOfYear(endDate);
      return data.week >= weekStart && data.week <= weekEnd;
    } else {
      return data.date >= startDate && data.date <= endDate;
    }
  });

  const formattedXAxisTick = (tickItem) => {
    return chartOpt === "weekly" ? formatYearWeekToWeekOfMonth(tickItem) : tickItem.split('-')[2];
  };

  function formatNumberWithLeadingZero(number) {
    return number < 10 ? `0${number}` : number.toString();
  }

  const handleDotClick = (data, index) => {
    const pointData = filteredChartData[index['index']];
    setDataPoint({
      date: pointData.date,
      acpsVal: pointData.acps
    });

    if (index['index'] > 0) {
      const previousDayData = filteredChartData[index['index'] - 1];
      setDatapointDayBefore({
        date: previousDayData.date,
        acpsVal: previousDayData.acps
      });
    } else {
      setDatapointDayBefore({});
    }

    setOpen(true);
  };

  const formatTooltipDate = (value) => {
    const date = new Date(value);
    date.setHours(date.getHours() + 3); // Adjust for UTC-03
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTooltipACPS = (value) => {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3
    });
  };

  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload.length) {
      const formattedLabel = chartOpt === "weekly" ? formatYearWeekToWeekOfMonth(label) : formatTooltipDate(label);
      return (
        <Paper sx={{ padding: 1, backgroundColor: darkMode ? '#1e1e1e' : '#ffffff' }}>
          <Typography variant="subtitle2" style={{ color: darkMode ? '#cccccc' : '#000000' }}>{formattedLabel}</Typography>
          <Typography variant="body2" style={{ color: darkMode ? '#cccccc' : '#000000' }}>{`ACPS: ${formatTooltipACPS(payload[0].value)}`}</Typography>
        </Paper>
      );
    }
    return null;
  };

  const renderCustomDot = (props) => {
    const { cx, cy, dataKey, index } = props;

    return (
      <Dot
        cx={cx}
        cy={cy}
        r={isTourActive ? 8 : 4}
        fill={darkMode ? "#00a651" : "#8884d8"}
        stroke={darkMode ? "#ffffff" : "#000000"}
        strokeWidth={2}
        className="chart-point"
        onClick={(e) => handleDotClick(props, { index, dataKey })}
        style={{
          cursor: 'pointer',
          visibility: 'visible',
        }}
      />
    );
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {chartOpt === "weekly" ? "ACPS Semanal da Campanha" : "ACPS Diário da Campanha"}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <ResponsiveContainer>
        <AreaChart
          width={500}
          height={200}
          data={filteredChartData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="0" stroke={darkMode ? "#ffffff" : "#e0e0e0"} horizontal={true} vertical={false} />
          <XAxis dataKey={chartOpt === "weekly" ? "week" : "date"} tickFormatter={formattedXAxisTick} stroke={darkMode ? "#cccccc" : "#616161"} />
          <YAxis stroke={darkMode ? "#cccccc" : "#616161"} axisLine={false} tickLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <Area
            connectNulls
            activeDot={{ r: isTourActive ? 8 : 4, stroke: '#616161', onClick: chartOpt === "daily" ? handleDotClick : null }}
            dot={isTourActive ? renderCustomDot : null}
            type="monotone"
            dataKey="acps"
            stroke={darkMode ? "#00a651" : "#8884d8"}
            fill={darkMode ? "#00a651" : "#8884d8"}
            strokeWidth={3}
          />
        </AreaChart>
        <Components
          userTier={userTier}
          openModal={open}
          handleCloseModal={handleClose}
          target={0} 
          targetName={null}
          targetCnpj={currCnpj}
          dataPoint={dataPoint}
          dataPointDayBefore={dataPointDayBefore}
          clientGoodCommentsData={clientGoodCommentsData}
          clientBadCommentsData={clientBadCommentsData}
          competitorOneGoodCommentsData={null}
          competitorOneBadCommentsData={null}
          competitorTwoGoodCommentsData={null}
          competitorTwoBadCommentsData={null}
          competitorThreeGoodCommentsData={null}
          competitorThreeBadCommentsData={null}
          postsLikes={null}
          filterDateRange={dateRange}
          filterPostUrl={campaignPosts}
          cnpjClient={currCnpj}
          onCampaign={onCampaign}
        />
      </ResponsiveContainer>
    </>
  );
};

export default CampaignChart;
