import React from 'react';
import SwotTemplate from '../../../assets/svg/SwotTemplate'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, child, get } from "firebase/database";
import { Box, Typography, Paper, Grid } from '@mui/material';

export default function SwotAnalysis({ cnpjClient, activeTutorial }) {
    const [opportunities, setOpportunities] = React.useState([]);
    const [strengths, setStrengths] = React.useState([]);
    const [threats, setThreats] = React.useState([]);
    const [weaknesses, setWeaknesses] = React.useState([]);

    React.useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                const dbRef = ref(getDatabase());
                cnpjClient = cnpjClient.replace(/[./-]/g, '') // Clean the string
                get(child(dbRef, `swot-analysis/${cnpjClient}`)).then((snapshot) => {
                    if (snapshot.exists()) {
                        const keys = Object.keys(snapshot.val());
                        keys.sort((a, b) => b - a);
                        const lastTimestampKey = keys[0];
                        setOpportunities(snapshot.val()[lastTimestampKey].Opportunities)
                        setStrengths(snapshot.val()[lastTimestampKey].Strengths)
                        setThreats(snapshot.val()[lastTimestampKey].Threats)
                        setWeaknesses(snapshot.val()[lastTimestampKey].Weaknesses)
                    } else {
                        console.log("No data available");
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }
        })
    }, [])

    // Style for the bullet points
    const bulletPointStyle = {
        listStyleType: 'disc',
        paddingLeft: '1em', // gives space for bullet points
        marginTop: '0.5em',
        marginBottom: '0.5em',
        lineHeight: '1.5',
    };

    return (
        <>
        {/* {activeTutorial && (
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 1,
                        mb: 2,
                        backgroundColor: 'lightblue',
                        borderRadius: '10px',
                        fontSize: '0.8rem',
                    }}
                >
                    <Typography variant="body2" component="p">
                        A Vinbol.AI oferece uma ferramenta de Análise SWOT (Forças, Fraquezas, Oportunidades e Ameaças) inovadora e altamente eficaz, projetada para proporcionar uma visão abrangente e aprofundada da sua marca no contexto do mercado digital. Esta análise utiliza dados avançados e insights obtidos através da inteligência artificial para te ajudar a entender melhor sua posição competitiva e identificar estratégias eficazes para o crescimento e aprimoramento da sua marca.
                        <br /><br />
                        <strong>Como Aproveitar ao Máximo:</strong>
                        <br />
                        <ul style={{ ...bulletPointStyle, color: '#000' }}>Análise Regular: Realize análises SWOT regularmente para manter-se atualizado com as mudanças no mercado e na percepção do público.</ul>
                        <ul style={{ ...bulletPointStyle, color: '#000' }}>Integração Estratégica: Integre os insights obtidos com a sua estratégia geral de negócios e marketing para garantir que suas ações estejam consistentemente alinhadas com os objetivos da marca.</ul>
                        <ul style={{ ...bulletPointStyle, color: '#000' }}>Ação Proativa:  Seja proativo na implementação de mudanças estratégicas sugeridas pela análise para manter sua marca dinâmica e competitiva no mercado digital.</ul>
                        A Análise SWOT da Vinbol.AI é mais do que uma ferramenta de avaliação, é um guia estratégico que capacita sua marca a navegar no complexo ecossistema digital com confiança e clareza. Aproveite esta poderosa ferramenta para desbloquear o potencial total da sua marca, posicionando-a para o sucesso no cenário digital competitivo.
                    </Typography>
                </Paper>
            </Grid>
        )} */}

        <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
            <SwotTemplate style={{ width: '100%', height: 'auto' }} />
            
            {/* Strengths */}
            <Box sx={{ position: 'absolute', top: 50, left: 20, width: 'calc(50% - 40px)', height: 'calc(50% - 60px)', overflowY: 'auto' }}>
                <ul style={{ ...bulletPointStyle, color: '#000' }}>
                    {strengths.map((strength, index) => (
                        <li key={index}>{strength}</li>
                    ))}
                </ul>
            </Box>

            {/* Weaknesses */}
            <Box sx={{ position: 'absolute', top: 50, right: 20, width: 'calc(50% - 40px)', height: 'calc(50% - 60px)', overflowY: 'auto' }}>
                <ul style={{ ...bulletPointStyle, color: '#000' }}>
                    {weaknesses.map((weakness, index) => (
                        <li key={index}>{weakness}</li>
                    ))}
                </ul>
            </Box>

            {/* Opportunities */}
            <Box sx={{ position: 'absolute', bottom: -50, left: 20, width: 'calc(50% - 40px)', height: 'calc(50%)', overflowY: 'auto' }}>
                <ul style={{ ...bulletPointStyle, color: '#000' }}>
                    {opportunities.map((opportunity, index) => (
                        <li key={index}>{opportunity}</li>
                    ))}
                </ul>
            </Box>

            {/* Threats */}
            <Box sx={{ position: 'absolute', bottom: -50, right: 20, width: 'calc(50% - 40px)', height: 'calc(50%)', overflowY: 'auto' }}>
                <ul style={{ ...bulletPointStyle, color: '#000' }}>
                    {threats.map((threat, index) => (
                        <li key={index}>{threat}</li>
                    ))}
                </ul>
            </Box>
        </Box>
        </>
    );
}
