import * as React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import LogoIcon from "../../../assets/svg/Logo";
import axios from 'axios';
// Auth
import {  createUserWithEmailAndPassword, getAuth  } from 'firebase/auth';
import { auth } from '../../../firebase';
import { getDatabase, ref, set } from "firebase/database";
import FullButton from "../../Buttons/FullButton";

const API_URI_SEND_MESSAGE = "https://us-central1-guaruba-ai.cloudfunctions.net/sendEmail"

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Todos os direitos reservados ® '}
      <Link color="inherit" href="https://vinbol.ai">
        Vinbol
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function writeUserData(
  userId, 
  firstName, 
  lastName, 
  email, 
  nomeEmpresa, 
  cnpj, 
  site, 
  googleBusinessUser, 
  reclameAquiUser, 
  instagramUser
  ) {
  const db = getDatabase();
  set(ref(db, 'users/' + userId), {
    createdOn: Date.now(),
    tier: 0,
    trialDays: 15,
    isActive: false,
    isDashboardDarkMode: false,
    isOnboarding: true,
    cnpj: [cnpj],
    firstName: firstName,
    lastName: lastName,
    email: email,
    nomeEmpresa: nomeEmpresa,
    site: site,
    integrations: {
      facebook: {
        connected: false
      },
      google: {
        connected: false
      },
      instagram: {
        connected: false
      }
    },
    userGoogleBusiness: googleBusinessUser,
    userReclameAqui: reclameAquiUser,
    userInstagram: instagramUser
  });
}

const steps = ['Mídias sociais e internet', 'Informações da marca', 'Informações do usuário'];

export default function Checkout({ _instagramUser, _googleBusinessUser, _reclameAquiUser }) {
  const navigate = useNavigate();
  
  const [activeStep, setActiveStep] = React.useState(0);
  // Campos
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [cnpj, setCnpj] = React.useState('');
  const [nomeEmpresa, setNomeEmpresa] = React.useState('');
  const [site, setSite] = React.useState('');
  const [googleBusinessUser, setGoogleBusinessUser] = React.useState('');
  const [reclameAquiUser, setReclameAquiUser] = React.useState('');
  const [instagramUser, setInstagramUser] = React.useState('');
  // Estados de checagem de erros
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastNameError, setLastNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [passError, setPassError] = React.useState(false);
  const [cnpjError, setCnpjError] = React.useState(false);
  const [nomeEmpresaError, setNomeEmpresaError] = React.useState(false);
  const [siteError, setSiteError] = React.useState(false);
  // const [googleBusinessUserError, setGoogleBusinessUserError] = React.useState(false);
  // const [reclameAquiUserError, setReclameAquiUserError] = React.useState(false);
  // const [instagramUserError, setInstagramUserError] = React.useState(false);
  const [loginMethod, setLoginMethod] = React.useState(false);
  const [userUid, setUserUid] = React.useState('');
  //
  const [name, setName] = React.useState('');
  const [tel, setTel] = React.useState('');
  const [subject, setSubject] = React.useState('Nova solicitação de setup');
  const [message, setMessage] = React.useState('');
  const sendMessage = () => {
    axios
      .get(API_URI_SEND_MESSAGE, {
        params: {
          name,
          email,
          tel,
          subject,
          message
        },
      })
    };

  React.useEffect(() => {
    setGoogleBusinessUser(_googleBusinessUser);
    setReclameAquiUser(_reclameAquiUser);
    setInstagramUser(_instagramUser);
  }, []);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Review 
        setSite={setSite}
        googleBusinessUser={googleBusinessUser}
        setGoogleBusinessUser={setGoogleBusinessUser}
        instagramUser={instagramUser}
        setInstagramUser={setInstagramUser}
        reclameAquiUser={reclameAquiUser}
        setReclameAquiUser={setReclameAquiUser}
        // Checagem de erro
        siteError={siteError} setSiteError={setSiteError}
        // googleBusinessUserError={googleBusinessUserError} setGoogleBusinessUserError={setGoogleBusinessUserError}
        // instagramUserError={instagramUserError} setInstagramUserError={setInstagramUserError}
        // reclameAquiUserError={reclameAquiUserError} setReclameAquiUserError={setReclameAquiUserError}
        />;
      case 1:
        return <PaymentForm 
        setCnpj={setCnpj}
        setNomeEmpresa={setNomeEmpresa}
        // Checagem de erro
        cnpjError={cnpjError} setCnpjError={setCnpjError}
        nomeEmpresaError={nomeEmpresaError} setNomeEmpresaError={setNomeEmpresaError}
        />;
      case 2:
        return <AddressForm 
        // Campos
        setFirstName={setFirstName}
        setLastName={setLastName}
        setEmail={setEmail}
        setPassword={setPassword}
        setNomeEmpresa={setNomeEmpresa}
        // Checagem de erro
        firstNameError={firstNameError} setFirstNameError={setFirstNameError}
        lastNameError={lastNameError} setLastNameError={setLastNameError}
        emailError={emailError} setEmailError={setEmailError}
        passError={passError} setPassError={setPassError}
        //
        setLoginMethod={setLoginMethod}
        setUserUid={setUserUid}
        />;
      default:
        throw new Error('Unknown step');
    }
  }

  const register = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // Return the UID
      return userCredential.user.uid;
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      throw error; // Rethrow the error to handle it outside the function if needed
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      ;
      // Mídias sociais e internet
      // if (site.trim() === '') {
      //   setSiteError(true);
      //   return;
      // } else if (googleBusinessUser.trim() === '') {
      //   setGoogleBusinessUser(true);
      //   return;
      // } else if (reclameAquiUser.trim() === '') {
      //   setReclameAquiUser(true);
      //   return;
      // } else if (instagramUser.trim() === '') {
      //   setInstagramUser(true);
      //   return;
      // }
    } else if (activeStep === 1) {
      // Informações da marca
      if (nomeEmpresa.trim() === '') {
        setNomeEmpresaError(true);
        return;
      } 
    }
    if (activeStep === 2) {
      // Informações do usuário
      if (firstName.trim() === '') {
        setFirstNameError(true);
        return;
      } else if (lastName.trim() === '') {
        setLastNameError(true);
        return;
      } else if (email.trim() === '') {
        setEmailError(true);
        return;
      } else if (loginMethod === 'email' && password.trim() === '') {
        setPassError(true);
        return;
      }

      if (loginMethod === 'email') {
        register()
          .then((uid) => {
            writeUserData(
              uid,
              firstName,
              lastName,
              email,
              nomeEmpresa,
              cnpj,
              site,
              googleBusinessUser,
              reclameAquiUser,
              instagramUser
            );
          })
          .catch((error) => {
            console.error("Registration failed: ", error);
          });
      } else {
        writeUserData(
          userUid,
          firstName,
          lastName,
          email,
          nomeEmpresa,
          cnpj,
          site,
          googleBusinessUser,
          reclameAquiUser,
          instagramUser
        );
      }
      setMessage(
        `userUid=${userUid}\n
        firstName=${firstName}\n
        lastName=${lastName}\n
        email=${email}\n
        nomeEmpresa=${nomeEmpresa}\n
        cnpj=${cnpj}\n
        site=${site}\n
        googleBusinessUser=${googleBusinessUser}\n
        reclameAquiUser=${reclameAquiUser}\n
        instagramUser=${instagramUser}`
      )
      setName(`${firstName} ${lastName}`)
    }
    setActiveStep(activeStep + 1);
  };

  React.useEffect(() => {
    if (message && name) {
      sendMessage();
    }
  }, [message, name])

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <LogoIcon />
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Configuração da marca
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Falta pouco para iniciarmos nossa jornada!
              </Typography>
              <Typography variant="subtitle1">
              A coleta e o processamento inicial dos dados da sua marca serão concluídos em até 72 horas. Na maioria das vezes, isso ocorre mais rapidamente, mas pode variar dependendo do nível de engajamento das suas redes sociais e das restrições das próprias plataformas. Por favor, aguarde a liberação da sua conta. Nós notificaremos você assim que sua marca estiver pronta.
              </Typography><br></br>
              <FullButton title="Voltar para página inicial!" action={() => {navigate("/")}}/>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Voltar
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Finalizar' : 'Avançar'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Copyright />
      </Container>
    </React.Fragment>
  );
}
