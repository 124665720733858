import React from 'react';
import { Typography, Button } from '@mui/material';

const ErrorScreen = ({ darkMode }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: darkMode ? '#121212' : '#ffffff',
        color: darkMode ? '#f5bb09' : '#e2aa13',
        textAlign: 'center',
        padding: '20px',
      }}
    >
      <Typography variant="h4" style={{ marginBottom: '20px' }}>
        Algo deu errado!
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '20px' }}>
        Ocorreu um problema ao carregar os dados. Por favor, atualize a página.
        Se o problema persistir, aguarde alguns minutos e tente novamente.
      </Typography>
      <Button
        variant="contained"
        style={{ backgroundColor: darkMode ? '#f5bb09' : '#e2aa13', color: darkMode ? '#000000' : '#ffffff' }}
        onClick={() => window.location.reload()}
      >
        Atualizar Página
      </Button>
    </div>
  );
};

export default ErrorScreen;